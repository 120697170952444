<template id="cu-set-template">
    <div class="set" :id="set.name" v-if="set.questions.length">
        <h5 v-show="showSetName()">{{ set.name }}</h5>
        <div class="set-images" v-if="set.images">
            <imagecontainer
            :previews="set.images"
            :howmany="6"
            ref="imagepreviews" 
            v-if="ro" />

        </div>
        <div v-for="question in set.questions" :key="question.id" class="form-group panel-set">
            <div v-if="question.type == 'image'">
                <strong>{{ question.text_question }}</strong><br/>
                <cu-pregunta-imagen :previews="set.images" :imagekey="set.key" :imagename="set.name"></cu-pregunta-imagen>
            </div>
            <div v-if="question.type == 'text'">
                <cu-pregunta-texto :question="question"></cu-pregunta-texto>
            </div>
            <div v-if="(question.type == 'checkbox' && question.options.length > 0)">
                <cu-pregunta-multi :question="question"></cu-pregunta-multi>
            </div>
            <div v-if="(question.type == 'checkbox' && question.options.length == 0)">
                <cu-pregunta-single :question="question"></cu-pregunta-single>
            </div>
            <div v-if="question.type == 'radio'">
                <cu-pregunta-radio :question="question"></cu-pregunta-radio>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['set'],
        inject: ['ro'],

        data: function () {
            return {
                active: 1
            };
        },
        methods: {
            showSetName: function() {
                return (this.set.key.split('_').length == 2) && this.ro
                    && !(['Características','Characteristics','General information','Information','Datos generales'].includes(this.set.name));

            },
            getClave: function () {
                return this.set.key;
            }
        }
    };
</script>
