<template id="resource-admin">
    <div class="container py-5" id="resource-admin-container">
        <div class="row mb-3">
            <div class="col-12">
                <h3>Gestión de recursos</h3>
            </div>
        </div>
        <div class="row border border-primary rounded mb-3 p-3">
            <div class="col-12 d-flex align-items-center">
                <a href="/admin/resources/create" class="btn btn-primary float-start">{{ $t('resource.admin.Crear nuevo recurso') }}</a>
                <p class="text-help m-0 ms-3">{{ $t('Antes de crear un nuevo recurso, usar la búsqueda para asegurarse de que el recurso no existe.') }}</p>
            </div>
        </div>
        <div class="row border border-primary rounded mb-3 p-3">
            <resource-filter :filter-setup="filterSetup" @do-search="getResources" />
        </div>
        <div class="row" v-show="response.data?.length">
            <div class="col-12">
                <table class="table table-bordered table-responsive align-middle">
                    <tbody>
                        <tr v-for="resource in response.data">
                            <td class="resource-admin-image-container">
                                <div class="ratio ratio-1x1">
                                    <img :src="resource.cover.baseurl + resource.cover.src" class="img-fluid" />
                                </div>
                            </td>
                            <th scope="row">
                                <a :href="'/resources/'+resource.slug">{{ resource.name }}</a>
                            </th>
                            <td>
                                <a :href="'/admin/resources/'+resource.slug+'/edit'" class="btn btn-sm btn-outline-primary">Editar</a>
                                <a :href="'/admin/resources/'+resource.slug+'/quiz'" class="btn btn-sm btn-outline-primary">Accesibilidad</a>

                                <a-button actionicon="bi bi-trash3-fill"
                                    buttonclass="btn btn-sm btn-outline-warning"
                                    :actionroute="'resources/' + resource.slug"
                                    actionmethod="DELETE"
                                    @action-success="resourceDeleteOk(resource)"
                                    @action-error="resourceDeleteError">
                                    {{ $t('gui_buttons.Borrar') }}
                                </a-button>   
                            </td>
                        </tr>
                    </tbody>
                </table>           
            </div>
        </div>

        <pagination :response="response" v-if="response" />
    </div>
</template>

<script>
export default {
    props: [
        'filter-setup',
    ],
    mixins: [authMixin, formMixin],
    created: function () {
        let urlparams = new URLSearchParams(window.location.search);
        let getparams = {};
        for (const [key, value] of urlparams.entries()) {
            getparams[key] = value;
        }
        this.filterSetup.selected = { ...this.filterSetup.selected, ...getparams};
        this.getResources(getparams);
    },
    methods: {
        getResources: function (params = null) {
            this.preselected = params;
            let url = 'resources/search';
            let baseParams = {
                fid_town: 474, // Ávila (localidad)
            }
            var search_params = { ...baseParams, ...params };
            // console.debug(search_params);
            t4a.get(url, { params: search_params })
                .then(
                    function (response) {
                        // console.debug(response);
                        this.response = response.data;
                    }.bind(this)
                ).catch(t4a_error_handler.bind(this));
        },
        resourceDeleteOk: function (resource) {
            this.response.data = this.response.data.filter(function(item) {
                return item.id != resource.id;
            })
        },
        resourceDeleteError: function () {
            
        }
    }
}
</script>
