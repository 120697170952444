<template id="translatable-htmlarea">
    <div>
        <!-- <language-selector :locales="dLocales" ref="lselector" @language-changed="onLanguageChanged" class="float-start mb-2" /> -->
        <div>
        <div class="languageselector float-start form-inline">
            <label for="languageselector" v-show="this.showlabels">{{ $t('translatable-textarea.Mostrando idioma') }}:</label>
            <select v-model="selectedLanguage" class="form-select" @change="onLanguageChanged">
                <option v-for="locale in locales" :value="locale.id" :key="locale.id">
                    {{ locale.language }}
                </option>
            </select>
        </div>

        <div class="translator">
            <button
                title="Traducir desde otro idioma"
                class="btn btn-outline-secondary float-end"
                @click.prevent="showTranslationDialog"
                v-show="!is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext">{{ $t('translatable-textarea.Traducir') }}</span>
            </button>
            <button
                title="Idioma sin traducción automática"
                class="btn btn-outline-secondary float-end"
                @click="showTranslationDialog"
                v-bind:disabled="is_nontranslatable()"
                v-show="is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext" v-show="showlabels">{{ $t('translatable-textarea.Idioma sin traducción automática') }}</span>
            </button>
            <!-- <div class="translationerror color-red">
                <span class="glyphicon glyphicon-exclamation-sign"></span>
                Se produjo un error en la traducción.
            </div> -->
            <dialog id="translationdialog" ref="translationdialog">
                <label for="translationselector">
                    {{ $t('translatable-textarea.Traducir') }}
                    {{ this.locales[this.selectedLanguage] }}
                    {{ $t('translatable-textarea.desde') }}:
                </label>
                <select
                    :disabled="is_nontranslatable()"
                    v-model="fromLanguageCode"
                    class="form-select" >
                    <option value="">--</option>
                    <option v-for="locale in locales" :value="locale.id" v-bind:key="locale.id" v-show="fromLangAvailable(locale.id)">
                        {{ locale.language }}
                    </option>
                </select>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="requestTranslation">{{ $t('translatable-textarea.Pedir traducción') }}</button>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="cancelTranslation">{{ $t('translatable-textarea.Cancelar') }}</button>
            </dialog>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="mt-3">
        <!-- <translator :locales="dLocales" :showlabels="true" :showingLanguage="selectedLanguage" ref="tselector" @translation-received="onTranslationReceived" class="float-end mb-2" /> -->
        <textarea
            class="form-control tinymce-textarea"
            rows="8"
            v-model="text"
            @change="onTextChange">
        </textarea>
    </div>
    </div>
</template>
<script>
//#region
var tinymce = require('tinymce/tinymce');
require('tinymce/icons/default');
require('tinymce/themes/silver');
require('tinymce/plugins/advlist');
require('tinymce/plugins/autolink');
require('tinymce/plugins/link');
require('tinymce/plugins/image');
require('tinymce/plugins/lists');
require('tinymce/plugins/charmap');
require('tinymce/plugins/print');
require('tinymce/plugins/hr');
require('tinymce/plugins/anchor');
require('tinymce/plugins/pagebreak');
// require('tinymce/plugins/spellchecker');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/visualblocks');
require('tinymce/plugins/visualchars');
require('tinymce/plugins/code');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/media');
require('tinymce/plugins/nonbreaking');
require('tinymce/plugins/table');
require('tinymce/plugins/directionality');
require('tinymce/plugins/emoticons');
require('tinymce/plugins/template');
require('tinymce/plugins/paste');
// require('tinymce/plugins/textcolor');
require('tinymce/plugins/textpattern');
require('tinymce/plugins/table');
//#endregion
export default {
    mixins: [translationMixin],
    props: {
        translations: {
            default: function() {
                return {}
            }
        },
        showlabels: {
            default: true,
        },
        name: {
            default: function () {
                return 'description';
            }
        }
    },
    data: function () {
        return {
            selectedLanguage: '',
            fromLanguageCode: '',
            text: '',
            dialog: {},
            tinymceInstance: null,
        }
    },
    mounted() {
        this.selectedLanguage = this.locales[0].id;
        this.text = '';
        if (this.translations && this.translations[this.selectedLanguage]) {
            this.text = this.translations[this.selectedLanguage];
        }
        this.dialog = this.$refs.translationdialog;

        var component = this;

        // tinymce init devuelve una promesa. lo guardamos en this.tinymceInstance al final de la llamada
        tinymce.init({
            selector: '.tinymce-textarea',
            convert_urls: false,
            setup: function (editor) {
                editor.on('Change', function (e) {
                    component.text = editor.getContent().trim();
                    component.onTextChange();
                });
            },
            menubar: false,
            height: 500,
            plugins: [
                'advlist autolink link image lists charmap print hr anchor pagebreak',
                'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                'table directionality emoticons template paste textpattern'
            ],
            style_formats: [
                {title: "Bold", icon: "bold", format: "bold"},
                {title: "Italic", icon: "italic", format: "italic"},
                {title: "Underline", icon: "underline", format: "underline"},
                {title: "Strikethrough", icon: "strikethrough", format: "strikethrough"},
            ],
            block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;',
            toolbar: 'insertfile undo redo | formatselect | bullist numlist | link unlink | uploadimg image media | fullscreen | bold italic underline | alignleft aligncenter alignright | outdent indent | removeformat | table'
        }).then(function(editors) {
            // console.debug('tinymce.init().then()', component);
            component.tinymceInstance = editors[0];
            component.updateTinyValue(component.text);
        });
    },
    methods: {
        onTextChange() {
            this.translations[this.selectedLanguage] = this.text;
            if (this.selectedLanguage == this.locales[0].id) {
                this.$emit('input', this.text);
            }
            this.updateTinyValue(this.text);
        },
        updateTinyValue: function (value) {
            // console.debug('updateTinyValue', value)
            // hack para preservar la posicion del cursor al usar .setContent() en tinymce
            var bookmark = this.tinymceInstance.selection.getBookmark(2, true);
            this.tinymceInstance.setContent(this.text);
            this.tinymceInstance.selection.moveToBookmark(bookmark);
        },
    },
    watch: {
        translations: {
            handler: function () {
                this.text = '';
                if (this.translations) {
                    if (this.translations[this.selectedLanguage]) {
                        this.text = this.translations[this.selectedLanguage];
                    } else if (this.translations[0]) {
                        this.text = this.translations[0];
                    }
                }
                this.$forceUpdate();
                this.updateTinyValue(this.text);
            },
            deep: true,
        }
    }
};
</script>
