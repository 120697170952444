<template id="blog-show">
    <div id="blog-show-container" v-if="blog">
        <div class="container-fluid py-3 py-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10">
                        <h2>{{ blog.title }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid purple-band-gradient py-3 py-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="blog-cover-container">
                        <a :href="coverUrl" @click.prevent="showImageDialog">
                            <img :src="coverUrl" class="img-fluid mt-3 mt-md-0" alt="this.blog.cover.alt_text" />
                        </a>
                        </div>
                        <dialog ref="imagedialog" class="imagedialog" @click="closeImageDialog">
                        <img
                            :src="coverUrl"
                            :alt="this.blog.cover.alt_text"
                            :title="this.blog.cover.title_text"
                            class="image-fullview"
                        />
                    </dialog>
                    </div>
                    <div class="col-12 col-md-9">
                        <p class="lead my-auto">{{ blog.intro }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container py-3 py-md-5">
            <div class="row">
                <div class="col-md-10" v-html="blog.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['locales', 'accessibilitycriteria'],
    props: [
        'slug'
    ],
    mixins: [
        authMixin
    ],
    mounted: function() {
        let url = 'blog/'+this.slug;
        t4a.get(url).then(function (response) {
            // console.debug(response);
            this.blog = response.data.blogPost;
        }.bind(this));
    },
    data: function () {
        return {
            blog: null
        }
    },
    methods: {
        showImageDialog: function () {
                this.$refs.imagedialog.showModal();
            },
            closeImageDialog: function (event) {
                this.$refs.imagedialog.close();
            },
    },
    computed: {
        coverUrl: function (){
            return this.blog.cover.baseurl + this.blog.cover.src
        }
    }
    
}
</script>
