<template id="blog-form">
<div id="blog-form-container">
    <form class="form" id="formcontainer" method="POST">
        <div class="form-group mb-3 border border-primary rounded p-3">
            <label for="title" class="mb-2">
            {{ $t('blog.form.title') }}
            </label>
            <p class="text-help required-field">
                <span class="bi bi-exclamation-diamond"></span>
                {{ $t('blog.form.required') }}
            </p>            
            <translatable-textinput 
                name="title" 
                v-model="selected.title"
                :translations="translations?.title" 
            />
        </div>
        <div class="form-group mb-3 border border-primary rounded p-3">
            <label for="intro" class="mb-2">{{ $t('blog.form.intro') }}</label>
            <p class="text-help required-field">
                <span class="bi bi-exclamation-diamond"></span>
                {{ $t('blog.form.required') }}
            </p>    
            <translatable-textinput
                name="intro"
                v-model="selected.intro"
                :translations="translations?.intro"
            />

        </div>

        <div class="form-group mb-3 border border-primary rounded p-3">
            <label for="content" class="mb-2">{{ $t('blog.form.content') }}</label>
            <translatable-htmlarea 
                :translations="translations?.content" 
                :showlabels="false" 
                name="content"
                v-model="selected.content"
            />
        </div>

        <div class="form-group mb-3 border border-primary rounded p-3">
            <label for="cover"  class="mb-2">{{  $t('blog.form.cover') }}</label>
            <imagepicker :previews="[selected.cover]" imagename="cover" imagekey="cover" class="p-3" />

        </div>
        <div class="form-group">
            <button class="btn btn-primary btn-lg mt-5" @click.prevent="clickedSave">{{ $t('gui_buttons.Guardar') }}</button>
            <a-status :astatus="status" class="fs-1 align-bottom" />
        </div>

        <div v-if="filestatus.length">
            <uploadstatus :filestatus="filestatus"></uploadstatus>
        </div>
        <errorlist :errors="errors" />

    </form>
</div>
</template>
<script>
export default {
    inject: ['locales', 'categories', 'subcategories', 'ro'],
    mixins: [authMixin, formMixin],
    props: {
        preselected: {
            required: false,
            default: function () {
                return {}
            }
        },
        translations: {
            required: false,
            default: function () {
                return {}
            }
        }
    },
    data: function () {
        return {
            actionUrl: '',
            uploadUrlTemplate: 'blog/___UPLOADID___/upload',
            blog: {},
            selected: {
                title: '',
                intro: '',
                content: '',
                // l10nfields: {},
            },
            default: {
                title: '',
                intro: '',
                content: '',
                // l10nfields: {},
            }
        }
    },
    methods: {
        clickedSave() {
            // create
            var url = 'blog/register';
            if (this.selected.slug) {
                // update
                url = 'blog/'+this.selected.slug;
            }
            this.actionUrl = url;
            this.submit();
        },
        buildSubmitData: function () {
            var submitData = this.selected;
            submitData.l10nfields = this.gatherTranslations();
            return submitData;
        },
        preselectedHandler: function() {
            console.debug('BlogForm preselected handler')
            for (var prop in this.default) {
                this.selected[prop] = this.default[prop];
            }
            for (var prop in this.preselected) {
                this.selected[prop] = this.preselected[prop];
            }
            this.$forceUpdate();
        }
    },
    mounted: function() {
        console.debug('mounted')
        this.preselectedHandler();
    },
    watch: {
        preselected: {
            handler: function () {
                console.debug ('watch')
                this.preselectedHandler();
            },
            deep: true,
        }
    }
}
</script>
