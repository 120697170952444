<template id="template-translatable-textarea">
    <div>
        <!-- <language-selector :locales="dLocales" ref="lselector" @language-changed="onLanguageChanged" class="float-start mb-2" /> -->
        <div class="languageselector float-start form-inline">
            <label for="languageselector" v-show="this.showlabels">{{ $t('translatable-textarea.Mostrando idioma:') }}</label>
            <select v-model="selectedLanguage" class="form-select" @change="onLanguageChanged">
                <option v-for="locale in locales" :value="locale.id" :key="locale.id">
                    {{ locale.language }}
                </option>
            </select>
        </div>

        <div class="translator">
            <button
                title="Traducir desde otro idioma"
                class="btn btn-outline-secondary float-end"
                @click.prevent="showTranslationDialog"
                v-show="!is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext">{{ $t('translatable-textarea.Traducir') }}:</span>
            </button>
            <button
                title="Idioma sin traducción automática"
                class="btn btn-outline-secondary float-end"
                @click="showTranslationDialog"
                v-bind:disabled="is_nontranslatable()"
                v-show="is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext" v-show="showlabels">{{ $t('translatable-textarea.Idioma sin traducción automática') }}</span>
            </button>
            <!-- <div class="translationerror color-red">
                <span class="glyphicon glyphicon-exclamation-sign"></span>
                Se produjo un error en la traducción.
            </div> -->
            <dialog id="translationdialog" ref="translationdialog">
                <label for="translationselector">
                    {{ $t('translatable-textarea.Traducir') }}
                    {{ this.locales[this.selectedLanguage] }}
                    {{ $t('translatable-textarea.desde') }}:
                </label>
                <select
                    :disabled="is_nontranslatable()"
                    v-model="fromLanguageCode"
                    class="form-select" >
                    <option value="">--</option>
                    <option v-for="locale in locales" :value="locale.id" :key="locale.id" v-show="fromLangAvailable(locale.id)">
                        {{ locale.language }}
                    </option>
                </select>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="requestTranslation">{{ $t('translatable-textarea.Pedir traducción') }}</button>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="cancelTranslation">{{ $t('translatable-textarea.Cancelar') }}</button>
            </dialog>
        </div>



        <!-- <translator :locales="dLocales" :showlabels="true" :showingLanguage="selectedLanguage" ref="tselector" @translation-received="onTranslationReceived" class="float-end mb-2" /> -->
        <textarea class="form-control" rows="8" name="description" v-model="text" @change="onTextChange"></textarea>
    </div>
</template>
<script>
export default {
    props: ['translations', 'locales', 'showlabels'],
    data: function () {
        return {
            selectedLanguage: '',
            fromLanguageCode: '',
            text: '',
            dialog: {},
        }
    },
    mounted() {
        this.selectedLanguage = this.locales[0].id;
        this.text = '';
        if (this.translations && this.translations[this.selectedLanguage]) {
            this.text = this.translations[this.selectedLanguage];
        }
        this.dialog = this.$refs.translationdialog;
    },
    methods: {
        fromLangAvailable(localeid) {
            return true;
        },
        is_nontranslatable() {
            return [0,'ca','eu','ga','cv'].includes(this.selectedLanguage);
        },
        is_empty_fromlang(localeid) {
            return (this.translations && this.translations[localeid] && this.translations[localeid].length);
        },
        is_different_fromlang(localeid) {
            return localeid != this.selectedLanguage;
        },
        onLanguageChanged() {
            // console.debug('onLanguageChanged', language);
            // this.selectedLanguage = language;
            this.text = this.translations[this.selectedLanguage] ?? '';
        },
        onTranslationReceived(translation) {
            console.debug('onTranslationReceived', translation);
            this.text = translation.text;
            this.onTextChange();
        },
        onTextChange() {
            this.dTranslations[this.selectedLanguage] = this.text;
        },
        showTranslationDialog() {
            this.dialog.showModal();
        },
        findLocale(localeid) {
            return this.locales.find(
                function(item) {
                    return item.id == localeid
                }
            );
        },
        requestTranslation() {

            var confirmTranslationText = '¿Solicitar traducción de ' +
                this.findLocale(this.fromLanguageCode).language +
                ' a ' +
                this.findLocale(this.selectedLanguage).language + '?';

            if (confirm(confirmTranslationText)) {
                this.dialog.close();
                this.doTranslate();
            }
        },
        cancelTranslation() {
            this.dialog.close();
        },
        doTranslate() {
            let translationbuttontext = this.$refs.translationbuttontext;

            translationbuttontext.innerHtml = 'Traduciendo'

            let text = this.translations[this.fromLanguageCode];

            let submitData = {
                text: text,
                from_lang: this.fromLanguageCode.toUpperCase(),
                to_lang: this.selectedLanguage.toUpperCase()
            }

            let translationUrl = 'translate';

            t4a.post(translationUrl, submitData)
                .then(function(response) {
                    // this.$emit('translation-received', response.data);
                    this.onTranslationReceived(response.data);
                }.bind(this))
                .catch(t4a_error_handler);
        }
    },
    watch: {
        translations: {
            handler: function () {
                this.text = '';
                this.dTranslations = this.translations;
                if (this.translations) {
                    if (this.translations[this.selectedLanguage]) {
                        this.text = this.translations[this.selectedLanguage];
                    } else if (this.translations[0]) {
                        this.text = this.translations[0];
                    }
                }
                this.$forceUpdate();
            },
            deep: true,
        }
    }
};
</script>
