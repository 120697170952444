<template id="template-astatus">
    <div class="a-status-container" v-if="this.astatus !== 'WAITING'">
        <span :class="this.statusicon"></span>
        <!-- <span
            v-if="this.status !== 'SUBMITTING'"
            :class="this.spanClass"
            title="Status"
            data-toggle="popover"
            :data-content="this.description"
            @mouseover="this.pop">
        </span>
        <span
            v-if="this.status !== 'SUBMITTING'"
            class="short-status">
            {{ this.status.toLowerCase() }}
        </span> -->
    </div>
</template>
<script>
export default {
    props: {
        // description: {
        //     required: true,
        // },
        astatus: {
            required: true,
        },
    },
    computed: {
        statusicon: function() {
            switch (this.astatus) {

                case 'SUBMITTING':
                case 'SUBMITTED':
                    return 'bi bi-arrow-repeat text-muted a-status-in-progress';
                case 'OK':
                    return  'bi bi-check text-success a-status-ok';
                case 'ERROR':
                    return  'bi bi-exclamation-triangle text-danger a-status-error';
                case 'WAITING': default:
                    return '';
            }
        }
    },
    methods: {

        // pop: function () {
        //     $('[data-toggle="popover"]').popover();
        //     $('body').on('click', '.popover', function () {
        //         $(this).popover('destroy');
        //     });
        // },
    }
};
</script>
<style>
@keyframes a-status-in-progress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
span.a-status-in-progress::before {
    margin-top: 4px;
    animation: a-status-in-progress 1.7s linear infinite;
}
</style>
