<template id="resource-item">
<div class="resource-item-container">
    <div class="resource-item">
        <a :href="aa_show_url" class="resource-item-link">
            <div class="resource-item-cover-container">
            <img class="resource-item-cover" :src="resource.cover.baseurl + resource.cover.src" alt="resource.cover.alt_text">
            </div>
            <div class="resource-item-name text-center" v-html="resource.name"></div>
        </a>
        <div v-if="mode == 'edit'" class="resource-item-actions">
            <!-- <button class="btn btn-sm btn-outline-primary" v-if="mode == 'edit'" @click.prevent="clickedEdit">Editar</button> -->
            <a :href="aa_show_url+'/edit'" class="btn btn-sm btn-outline-primary" v-if="mode == 'edit'">{{  $t('Editar') }}</a>
            <a :href="aa_show_url+'/quiz'" class="btn btn-sm btn-outline-primary" v-if="mode == 'edit'">{{  $t('Cuestionario') }}</a>

            <a-button actionicon="bi bi-trash3-fill"
                v-if="mode == 'edit'"
                buttonclass="btn btn-sm btn-outline-warning"
                :actionroute="'resources/' + resource.slug"
                actionmethod="DELETE"
                @action-success="resourceDeleteOk"
                @action-error="resourceDeleteError">
                {{ $t('gui_buttons.Borrar') }}
            </a-button>
        </div>
    </div>
    <!-- <div v-show="!mode" v-html="resource.description"></div> -->
    <!-- <a :href="aa_show_url" class="btn btn-outline-primary" v-if="!mode">Ver más</a> -->

    
</div>
</template>

<script>
export default {
    props: [
        'resource',
        'mode'
    ],
    methods: {
        resourceDeleteOk: function () {
            this.$emit('resource-deleted',this.resource);
        },
        resourceDeleteError: function () {

        }
    },
    computed: {
        aa_show_url: function () {
            return '/resources/'+this.resource.slug;
        },
    }
}
</script>
