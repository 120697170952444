<template id="template-actionsubmit"></template>
<script>
export default {
    mixins: [authMixin],
    props: {
        actiontype: { required: true, },
        actionid: { },
        validator: null,
        status: { required: true, },
    },
    data: function() {
        return {
            dactiontype: this.actiontype,
            actiondata: this.$parent.actiondata,
        }
    },
    computed: {
        actionType: {
            get: function() { return this.dactiontype; },
            set: function(value) { this.dactiontype = value; },
        },
        actionMethod: {
            get: function () {
                var actionMethod = '';
                switch (this.actiontype) {
                    case 'image-report':
                        return 'GET';
                    case 'image-restore':
                        return 'GET';
                    case 'image-add-slider':
                        return 'GET';
                    case 'image-remove-slider':
                        return 'GET';
                    case 'image-delete-user-review':
                        return 'DELETE';
                    case 'image-delete-technical-inspection':
                        return 'DELETE';
                    case 'image-delete-admin':
                        return 'DELETE';
                    case 'attachment-delete-admin':
                        return 'DELETE';
                    case 'blog-delete':
                        return 'DELETE';
                    case 'news-delete':
                        return 'DELETE';
                    case 'event-delete':
                        return 'DELETE';
                    case 'destination-delete':
                        return 'DELETE';
                    case 'destinationdetail-delete':
                        return 'DELETE';
                    case 'experience-register':
                        return 'POST';
                    case 'experience-modify':
                        return 'POST';
                    case 'experience-delete':
                        return 'DELETE';
                    case 'experienceday-register':
                        return 'POST';
                    case 'experienceday-modify':
                        return 'POST';
                    case 'experienceday-delete':
                        return 'DELETE';
                    case 'user-change-password':
                        return 'POST';
                    case 'user-change-role':
                        return 'POST';
                    case 'user-delete':
                        return 'DELETE';
                    case 'user-save':
                        return 'POST';
                    case 'other-user-save':
                        return 'POST';
                    case 'resource-delete':
                        return 'DELETE';
                    case 'resource-open':
                        return 'POST';
                    case 'resource-close':
                        return 'POST';
                    default:
                        return 'GET';
                }
            },
        },
        actionUrl: {
            get: function() {
                var getUrl = window.location;
                var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
                var apiPrefix = 'api/v1.1/';
                var actionRoute = '';
                switch (this.actionType) {
                    case 'image-report':
                        actionRoute = 'images/' + this.actionid + '/report';
                        break;
                    case 'image-restore':
                        actionRoute = 'images/' + this.actionid + '/restore';
                        break;
                    case 'image-add-slider':
                        var imageable_route = null;
                        switch (this.actiondata.imageable_type) {
                            case 'Tur4all\\Models\\Questionnaire\\User\\Review':
                                imageable_route = 'review';
                                break;
                            case 'Tur4all\\Models\\Questionnaire\\Technical\\Inspection':
                                imageable_route = 'inspection';
                                break;
                            default:
                                return null;
                        }
                        actionRoute = 'images/' + imageable_route + '/' + this.actiondata.imageable_id + '/slide/' + this.actiondata.id + '';
                        break;
                    case 'image-remove-slider':
                        var imageable_route = null;
                        switch (this.actiondata.imageable_type) {
                            case 'Tur4all\\Models\\Questionnaire\\User\\Review':
                                imageable_route = 'review';
                                break;
                            case 'Tur4all\\Models\\Questionnaire\\Technical\\Inspection':
                                imageable_route = 'inspection';
                                break;
                            default:
                                return null;
                        }
                        actionRoute = 'images/' + imageable_route + '/' + this.actiondata.imageable_id + '/unslide/' + this.actiondata.id + '';
                        break;
                    case 'image-delete-user-review':
                        actionRoute = 'images/' + this.actionid;
                        break;
                    case 'image-delete-technical-inspection':
                        actionRoute = 'images/' + this.actionid;
                        break;
                    case 'image-delete-admin':
                        actionRoute = 'images/' + this.actionid;
                        break;
                    case 'attachment-delete-admin':
                        actionRoute = 'attachments/' + this.actionid;
                        break;
                    case 'blog-delete':
                        actionRoute = 'blog/' + this.actionid;
                        break;
                    case 'news-delete':
                        actionRoute = 'news/' + this.actionid;
                        break;
                    case 'event-delete':
                        actionRoute = 'events/' + this.actionid;
                        break;
                    case 'destination-delete':
                        actionRoute = 'destinations/' + this.actionid;
                        break;
                    case 'destinationdetail-delete':
                        actionRoute = 'destinations/' + this.actionid[0] + '/details/' + this.actionid[1];
                        break;                            
                    case 'experience-register':
                        actionRoute = 'experiences/register';
                        break;
                    case 'experience-modify':
                        actionRoute = 'experiences/' + this.actionid;
                        break;
                    case 'experience-delete':
                        actionRoute = 'experiences/' + this.actionid;
                        break;
                    case 'experienceday-register':
                        actionRoute = 'experiences/' + this.actionid + '/days/register';
                        break;
                    case 'experienceday-modify':
                        actionRoute = 'experiences/' + this.actionid[0] + '/days/' + this.actionid[1];
                        break;
                    case 'experienceday-delete':
                        actionRoute = 'experiences/' + this.actionid[0] + '/days/' + this.actionid[1];
                        break;
                    case 'user-change-password':
                        actionRoute = 'user/password';
                        break;
                    case 'user-change-role':
                        actionRoute = 'user/' + this.actionid + '/role';
                        break;
                    case 'user-delete':
                        actionRoute = 'user/' + this.actionid;
                        break;
                    case 'user-save':
                        actionRoute = 'user/profile';
                        break;
                    case 'other-user-save':
                        actionRoute = 'user/' + this.actionid;
                        break;
                    case 'resource-delete':
                        actionRoute = 'resources/' + this.actionid;
                        break;
                    case 'resource-open':
                        actionRoute = 'resources/' + this.actionid + '/open';
                        break;
                    case 'resource-close':
                        actionRoute = 'resources/' + this.actionid + '/close';
                        break;
                    default:
                        return '/';
                }
                return baseUrl + apiPrefix + actionRoute;
            }
        },
        redirectionUrl: {
            get: function() {
                var getUrl = window.location;
                var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[0];
                var apiPrefix = 'api/v1.1/';
                var redirectionRoute = '';
                switch (this.actionType) {
                    case 'experience-modify':
                        redirectionRoute = 'experiences/index';
                        break;
                    case 'experienceday-modify':
                        redirectionRoute = 'admin/experiences/' + this.actionid[0] + '/edit';
                        break;
                    default:
                        return '/';
                }
                return baseUrl + redirectionRoute;
            }
        },
    },
    methods: {
        validate: function () {
            if (this.validator) {
                // standard 'form#formcontainer'
                if ($(this.validator).valid()) {
                    this.submit();
                } else {
                    this.$parent.actionStatus = 'ERROR';
                }
            } else {
                this.submit();
            }
        },
        submit: function () {
            var jqXHR = $.ajax({
                type: this.actionMethod,
                url: this.actionUrl,
                data: this.$parent.actionRequestData,
                dataType: 'json',
                success: this.onSuccess,
                error: this.onError,
            });
            // console.log(jqXHR);
            // this.$parent.actionResponse = jqXHR.responseJSON;
        },
        onSuccess: function (data, textStatus, jqXHR) {
            // console.log(data);
            // console.log(textStatus);
            // console.log(jqXHR);
            // this.$parent.actionRequest = request;
            this.$parent.actionResponse = jqXHR;
            this.$parent.actionStatus = 'OK';
            if(this.actionType == 'user-save' ||
                this.actionType == 'other-user-save') {
                this.$root.startPictureUpload();
            }
            if (this.isReloadNeededAfterOK()) {
                this.reload();
            }
            if (this.isRedirectionNeededAfterOK()) {
                this.doredirect();
            }
        },
        onError: function (jqXHR, textStatus, errorThrown) {
            // console.log(jqXHR);
            // console.log(textStatus);
            // console.log(errorThrown);
            this.$parent.actionResponse = jqXHR;
            this.$parent.actionStatus = 'ERROR';
            if (this.isReloadNeededAfterERROR()) {
                this.reload();
            }
            if (this.isRedirectionNeededAfterOK()) {
                this.doredirect();
            }
        },
        isReloadNeededAfterOK: function () {
            let allowed = [
                'image-restore',
                'image-add-slider',
                'image-remove-slider',
                'image-delete-user-review',
                'image-delete-technical-inspection',
                'image-delete-admin',
                'attachment-delete-admin',
                'blog-delete',
                'news-delete',
                'event-delete',
                'destination-delete',
                'destinationdetail-delete',
                'experience-delete',
                'experienceday-delete',
                'user-delete',
                'resource-delete',
                'resource-open',
                'resource-close',
            ];
            return allowed.includes(this.actiontype);
        },
        isReloadNeededAfterERROR: function () {
            let allowed = [
                'resource-open',
                'resource-close',
            ];
            return allowed.includes(this.actiontype);
        },
        isRedirectionNeededAfterOK: function () {
            let allowed = [
                'experience-modify',
                'experienceday-modify',
            ];
            return allowed.includes(this.actiontype);
        },
        isRedirectionNeededAfterError: function () {
            switch (this.actiontype) {
                case 'foo':
                    return '';
                case 'bar':
                    return '';
                default: return false;
            }
        },
        reload: function () {
            setTimeout(null, 1000);
            // con true, reload fuerza el GET
            // si se desea recargar la caché con valores POST hay que quitar el true
            // y  controlarlo
            location.reload(true);
        },
        doredirect: function () {
            setTimeout(null, 1000);
            // con true, reload fuerza el GET
            // si se desea recargar la caché con valores POST hay que quitar el true
            // y  controlarlo
            location.href = this.redirectionUrl;
        }
    },
};
</script>