<template id="template-action">
    <div :class="this.rootClass">

        <action-submit
            ref="actionsubmit"
            :actiontype="this.actionType"
            :actionid="this.id"
            :validator="this.validator"
            :status="this.actionStatus">
        </action-submit>

        <action-status
            v-if="this.isFirstStatus"
            ref="actionstatus"
            :type = "this.statustype"
            :status="this.actionStatus"
            :description="this.actionStatusDescription">
        </action-status>

        <action-button
            v-if="this.isActionButton"
            ref="actionbutton"
            :actiontype="this.actionType"
            :status="this.actionStatus">
        </action-button>

        <action-switcher
            v-if="this.isActionSwitcher"
            ref="actionswitcher"
            :actiontype="this.actionType"
            :actionvalue="this.actionvalue"
            :status="this.actionStatus">
        </action-switcher>

        <action-select
            v-if="this.isActionSelect"
            ref="actionselect"
            :actiontype="this.actionType"
            :actiondata="this.actiondata"
            :actionvalue="this.actionvalue"
            :status="this.actionStatus">
        </action-select>

        <action-status
            v-if="this.isLastStatus"
            ref="actionstatus"
            :type = "this.statustype"
            :status="this.actionStatus"
            :description="this.actionStatusDescription">
        </action-status>
    </div>
</template>
<script>
const status = [
    'TEST',
    'LOADING',
    'WAITING',
    'SUBMITTING',
    'SUBMITTED',
    'OK',
    'ERROR',
];


// const action_catalog = {
//     'image-report': {
//         actionMethod: 'GET',
//         actionId: this.id,
//         actionRoute: 'images/' + this.actionid + '/report',
//         redirectionRoute: '',
//         isReloadNeededAfterOK: false,
//         isReloadNeededAfterERROR: false,
//         isRedirectionNeededAfterOK: false,
//         isRedirectionNeddedAfterError: false,
//         isActionButton: true,
//         isActionSwitcher: false,
//         isActionSelect: false,
//         isFirstStatus: true,
//         isLastStatus: false,
//         buttonType: 'green-white',
//         buttonGlyph: '',
//         buttonText: $t("gui_buttons['Moderar']"),
//         buttonClass: 'btn style-button-green-white btn-block',
//     }
// };

export default {
    props: {
        rootclass: { default: '', },
        type: { required: true, },
        validator: null,
        statustype: { default: 'top', },
        actionvalue: { },
        actiondata: { },
        requestdata: { },
        id: { },
    },
    data: function () {
        return {
            dtype: this.type,
            drequestdata: this.requestdata,
            request: null,
            response: null,
            status: 'WAITING',
        }
    },
    computed: {
        rootClass() {
            return 'root-action ' + this.rootclass;
        },
        actionType: {
            get: function() { return this.dtype; },
            set: function(value) { this.dtype = value; }
        },
        actionRequestData: {
            get: function() { return this.requestdata; },
            set: function(value) { this.requestdata = value; }
        },
        actionStatus: {
            get: function() { return this.status; },
            set: function(value) {
                this.status = value;
                if (value === 'SUBMITTING') {
                    var actionSubmit = this.$refs.actionsubmit;
                    // validation and submit
                    actionSubmit.validate();
                }
            }
        },
        actionStatusDescription: function () {
            switch (this.actionStatus) {
                case 'TEST':
                    return 'Component ActionStatus working';
                case 'LOADING':
                    return null;
                case 'WAITING':
                    return null;
                case 'SUBMITTING':
                    return null;
                case 'SUBMITTED':
                    return null;
                case 'OK':
                    return this.responseDescription;
                case 'ERROR':
                    return this.responseErrors;
            }
        },
        actionRequest: {
            get: function() { return this.request; },
            set: function(value) {this.request = value;}
        },
        actionResponse: {
            get: function() { return this.response; },
            set: function(value) {this.response = value;}
        },
        responseJSON: {
            get: function() { return this.response.responseJSON; },
        },
        responseStatusCode: {
            get: function() { return this.response.status; },
        },
        responseDescription: {
            get: function() {
                let description = this.response.responseText;
                String.prototype.replaceAll = function(search, replacement) {
                    var target = this;
                    return target.replace(new RegExp(search, 'g'), replacement);
                };
                description = description.slice(1);
                description = description.slice(0,description.length - 1);
                description = description.replaceAll('"', '');
                return description;
            },
        },
        responseErrors: function () {
            var errors = this.responseJSON.errors;
            if (errors) {
                return Object.values(errors).join(',');
            } else {
                return this.responseDescription;
            }
        },
        isActionButton: function () {
            switch (this.type) {
                case 'image-report': return true;
                case 'image-restore': return true;
                case 'image-add-slider': return true;
                case 'image-remove-slider': return true;
                case 'image-delete-user-review': return true;
                case 'image-delete-technical-inspection': return true;
                case 'image-delete-admin': return true;
                case 'attachment-delete-admin': return true;
                case 'blog-delete':return true;
                case 'news-delete':return true;
                case 'event-delete':return true;
                case 'destination-delete':return true;
                case 'destinationdetail-register':return true;
                case 'destinationdetail-modify':return true;
                case 'destinationdetail-delete':return true;
                case 'experience-register':return true;
                case 'experience-modify':return true;
                case 'experience-delete':return true;
                case 'experienceday-register':return true;
                case 'experienceday-modify':return true;
                case 'experienceday-delete':return true;
                case 'user-change-password':return true;
                case 'user-delete':return true;
                case 'user-save':return true;
                case 'other-user-save':return true;
                case 'resource-delete':return true;
                case 'entity-delete':return true;
                default: return false;
            }
        },
        isActionSwitcher: function () {
            switch (this.type) {
                case 'resource-open': return true;
                case 'resource-close': return true;
                default: return false;
            }
        },
        isActionSelect: function () {
            switch (this.type) {
                case 'user-change-role': return true;
                default: return false;
            }
        },
        isFirstStatus: function () {
            return !this.isLastStatus;
        },
        isLastStatus: function () {
            switch (this.statustype) {
                case 'right': return true;
                case 'bottom': return true;
                default: return false;
            }
        },
    },
};
</script>
