<template id="template-imagepicker">
    <div class="image-picker">
        <!-- <p class="help-block">
            <span class="glyphicon glyphicon-info-sign"></span>
            {{ $t("gui_js_components_imagepicker['El tamaño máximo permitido para las imágenes es de 2MB']") }}
        </p> -->
        <imagecontainer :previews="previews" ref="imagepreviews" />
        <imageinput
            :imagekey="imagekey"
            :imagename="imagename"
            ref="imageinput"
            @beginuploads="startUploads()"
            :capturealt="capturealt">
        </imageinput>
    </div>
</template>
<script>
export default {
    mixins: [uploadsMixin],
    props: {
        capturealt: {
            default: function () {
                return false;
            }
        },
        previews: {
            default: function () {
                return [];
            },
        },
        imagekey: {
            default: function () {
                return '';
            },
            required: true,
        },
        imagename: {
            default: function () {
                return '';
            },
            required: true,
        },
    }
}
</script>
