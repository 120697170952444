<template id="resource-form">
    <div id="resource-form-container" class="container my-5">
        <div class="row">
            <div class="col-12">
                <h2>{{ formTitle }}</h2>
                <form class="form" id="formcontainer" method="POST" ref="form">
                    <div class="form-group mb-3 border border-primary rounded p-3">
                        <label for="name" class="mb-2">{{ $t('resource.form.name') }}</label>
                        <p class="text-help required-field">
                            <span class="bi bi-exclamation-diamond"></span>
                            {{ $t('resource.form.required') }}
                        </p>
                        <input type="text" name="name" id="name" class="form-control required-field" v-model="selected.name"
                            required aria-required="true"  @invalid="handleInvalid" />
                    </div>


                    <div class="form-group mb-3 border border-primary rounded p-3" v-show="this.categories?.length">
                        <label for="fid_category" class="mb-2">{{ $t('resource.form.category') }}</label>
                        <p class="text-help required-field">
                            <span class="bi bi-exclamation-diamond"></span>
                            {{ $t('resource.form.required') }}
                        </p>
                        <p class="text-help" v-if="mode == 'edit'"><span class="bi bi-info-circle"></span>
                            {{ $t('resource.form.category-help') }}
                        </p>
                        <select class="form-select required-field" id="fid_category" name="fid_category"
                            v-model="selected.fid_category" :disabled="mode == 'edit'" required aria-required="true"  @invalid="handleInvalid" >
                            <option value="">--</option>
                            <option v-for="category in this.categories" :value="category.id">{{ category.name }}</option>
                        </select>
                    </div>

                    <div class="form-group mb-3 border border-primary rounded p-3" v-show="this.subcategories?.length">
                        <label for="fid_subcategory" class="mb-2">{{ $t('resource.form.subcategory') }}</label>
                        <p class="text-help required-field">
                            <span class="bi bi-exclamation-diamond"></span>
                            {{ $t('resource.form.required') }}
                        </p>
                        <p class="text-help" v-if="mode == 'edit'"><span class="bi bi-info-circle"></span>
                            {{ $t('resource.form.subcategory-help') }}
                        </p>
                        <select class="form-select required-field" id="fid_subcategory" name="fid_subcategory"
                            :disabled="mode == 'edit'" v-model="selected.fid_subcategory" required aria-required="true"  @invalid="handleInvalid" >
                            <option value="">--</option>
                            <option v-for="subcategory in this.subcategories" :value="subcategory.id"
                                v-if="subcategory.fid_category == selected.fid_category">{{ subcategory.name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group mb-3 border border-primary rounded p-3">
                        <label for="address" class="mb-2">{{ $t('resource.form.address') }}</label>
                        <p class="text-help required-field">
                            <span class="bi bi-exclamation-diamond"></span>
                            {{ $t('resource.form.required') }}
                        </p>

                        <input type="text" name="address" id="address" class="form-control required-field"
                            v-model="selected.address" required aria-required="true"  @invalid="handleInvalid" />

                        <label for="number" class="mb-2">{{ $t('resource.form.number') }}</label>
                        <p class="text-help required-field">
                            <span class="bi bi-exclamation-diamond"></span>
                            {{ $t('resource.form.required') }}
                        </p>
                        <input type="text" name="number" id="number" class="form-control required-field"
                            v-model="selected.number" required aria-required="true"  @invalid="handleInvalid"  />

                        <label for="postal_code" class="mb-2">{{ $t('resource.form.postal_code') }}</label>
                        <input type="text" name="postal_code" id="postal_code" class="form-control"
                            v-model="selected.postal_code" />
                    </div>

                    <div class="form-group mb-3 border border-primary rounded p-3 row g-0">
                        <div class="col-12 col-md-6">
                            <label for="latitude" class="mb-2">{{ $t('resource.form.latitude') }}</label>
                            <p class="text-help required-field">
                                <span class="bi bi-exclamation-diamond"></span>
                                {{ $t('resource.form.required') }}
                            </p>

                            <p class="text-help"><span class="bi bi-info-circle"></span>
                                {{ $t('resource.form.latitude-help') }}
                            </p>
                            <input type="text" name="latitude" id="latitude" class="form-control required-field"
                                v-model="selected.latitude" required aria-required="true" @invalid="handleInvalid"  />

                            <label for="longitude" class="mb-2">{{ $t('resource.form.longitude') }}</label>
                            <p class="text-help required-field">
                                <span class="bi bi-exclamation-diamond"></span>
                                {{ $t('resource.form.required') }}
                            </p>

                            <p class="text-help"><span class="bi bi-info-circle"></span>
                                {{ $t('resource.form.longitude-help') }}
                            </p>
                            <input type="text" name="longitude" id="longitude" class="form-control required-field"
                                v-model="selected.longitude" required aria-required="true" @invalid="handleInvalid"  />
                        </div>
                        <div class="resource-map-container col-12 col-md-6 g-md-5">
                            <p class="text-help"><span class="bi bi-info-circle"></span>
                                {{ $t('resource.form.map-help') }}
                            </p>
                            <p>
                                <button @click.prevent="searchAddress" class="btn btn-outline-primary">{{ $t('resource.form.search-address-button') }}</button>
                            </p>
                            <l-map :zoom="mapdata.mapZoom" :center="mapdata.mapCenter" :options="mapdata.mapOptions" @dblclick="placeMarker">
                                <l-tile-layer :url="mapdata.mapTileLayerUrl" :attribution="mapdata.mapTileLayerAttribution"
                                    :options="mapdata.mapTileLayerOptions" />
                                <l-marker :lat-lng="mapdata.mapMarker" />
                            </l-map>
                        </div>
                    </div>

                    <div class="form-group mb-3  border border-primary rounded p-3">
                        <label for="telephone" class="mb-2">{{ $t('resource.form.telephone') }}</label>
                        <input type="text" name="telephone" id="telephone" class="form-control"
                            v-model="selected.telephone" />

                        <label for="email" class="mb-2">{{ $t('resource.form.email') }}</label>
                        <input type="email" name="email" id="email" class="form-control" v-model="selected.email" />

                        <label for="url" class="mb-2">{{ $t('resource.form.url') }}</label>
                        <input type="text" name="url" id="url" placeholder="https://" class="form-control"
                            v-model.trim="selected.url" />
                    </div>

                    <div class="form-group mb-3   border border-primary rounded p-3">
                        <label for="contact_name" class="mb-2">{{ $t('resource.form.contact_name') }}</label>
                        <input type="text" name="contact_name" id="contact_name" class="form-control"
                            v-model="selected.contact_name" />

                        <label for="contact_email" class="mb-2">{{ $t('resource.form.contact_email') }}</label>
                        <input type="email" name="contact_email" id="contact_email" class="form-control"
                            v-model="selected.contact_email" />

                        <label for="contact_position" class="mb-2">{{ $t('resource.form.contact_position') }}</label>
                        <input type="text" name="contact_position" id="contact_position" class="form-control"
                            v-model="selected.contact_position" />

                        <label for="contact_phone" class="mb-2">{{ $t('resource.form.contact_phone') }}</label>
                        <input type="text" name="contact_phone" id="contact_phone" class="form-control"
                            v-model="selected.contact_phone" />
                    </div>

                    <div class="form-group mb-3  border border-primary rounded p-3">
                        <label for="description" class="mb-2">{{ $t('resource.form.description') }}</label>
                        <translatable-htmlarea :translations="translations?.description" :locales="this.locales"
                            :showlabels="false" v-model="selected.description" />
                    </div>

                    <div class="form-group mb-3 border border-primary rounded p-3">
                        <label for="contact_phone" class="mb-2">{{ $t('resource.form.cover') }}</label>
                        <imagepicker :previews="[selected.cover]" imagename="cover" imagekey="resourcepic" />
                    </div>

                    <div class="form-group">
                        <button class="btn btn-primary btn-lg" @click.prevent="clickedSave">{{ $t('gui_buttons.Guardar') }}</button>
                        <a-status :astatus="status" class="fs-1 align-bottom" />
                    </div>
                    <div v-if="filestatus.length">
                        <uploadstatus :filestatus="filestatus"></uploadstatus>
                    </div>
                    <errorlist :errors="errors" />
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['locales', 'categories', 'subcategories'],
    provide: {
        ro: false,
    },
    mixins: [authMixin, formMixin],
    props: {
        preselected: {
            required: false,
            default: function () {
                return {}
            }
        },
        translations: {
            required: false,
            default: function () {
                return {}
            }
        },
        mode: {
            required: false,
            default: function () {
                return 'create';
            }
        }
    },
    data: function () {
        return {
            actionUrl: '',
            uploadUrlTemplate: 'resources/___UPLOADID___/upload',
            resource: {},
            selected: {
                name: '',
                fid_category: '',
                fid_subcategory: '',
                fid_town: 474, // Ávila (localidad)
                fid_region: 6, // Ávila (provincia), Castilla y León
                fid_country: 209, // España
                latitude: "40.65724",
                longitude: "-4.69951",
            },
            default: {
                name: '',
                fid_category: '',
                fid_subcategory: '',
                address: '',
                number: '',
                postal_code: '',
                telephone: '',
                url: '',
                contact_email: '',
                contact_name: '',
                contact_phone: '',
                contact_position: '',
                description: '',
                translations: [],
                fid_town: 474, // Ávila (localidad)
                fid_region: 6, // Ávila (provincia), Castilla y León
                fid_country: 209, // España
                latitude: "40.65724",
                longitude: "-4.69951",
                cover: {}
            }
        }
    },
    methods: {
        clickedSave() {
            // create
            this.actionUrl = 'resources/register';
            if (this.selected.slug) {
                // update
                this.actionUrl = 'resources/' + this.selected.slug;
            }

            let formvalidation = this.$refs.form.reportValidity();
            if (formvalidation) {
                this.submit();
            }
        },
        handleInvalid: function (e) {
            console.debug(e);
            window.invalidevent = e;
            e.target.focus();

            e.target.parentNode.scrollIntoView({behavior:'smooth'}); 
        },
        buildSubmitData: function () {
            var submitData = this.selected;
            var translations = this.gatherTranslations();
            submitData.l10nfields = translations;
            // console.debug('resourceForm.buildSumbitData', submitData, translations);
            return submitData;
        },
        preselectedHandler: function () {
            // console.debug('ResourceForm preselected handler')
            for (var prop in this.default) {
                this.selected[prop] = this.default[prop];
            }
            for (var prop in this.preselected) {
                this.selected[prop] = this.preselected[prop];
            }
            this.$forceUpdate();
        },
        placeMarker: function (value) {
            this.selected.latitude = value.latlng.lat.toString();
            this.selected.longitude = value.latlng.lng.toString();
 	    },
        searchAddress: function () {
            var addressUrl = "https://nominatim.openstreetmap.org/search.php?";
            if (this.selected.address) {
                addressUrl = addressUrl + "street=" + this.selected.address + " " +this.selected.number;
            }
            addressUrl = addressUrl + "&city=Ávila&country=Spain&format=jsonv2";
            console.debug(addressUrl);
            var requestConfig = {
                headers: {
                    'User-Agent': 'avilaaccesible.es form tool (axios)'
                },
            }
            axios.get(addressUrl, requestConfig)
            .then(function(response) {
                console.debug(response);
                // console.debug(response.lat, response.lon);
                if(response.data.length) {
                    var place = response.data.shift();
                    console.debug(place);
                    this.selected.latitude = place.lat;
                    this.selected.longitude = place.lon;
                }
            }.bind(this));

        },

    },
    mounted: function () {
        // console.debug('mounted')
        this.preselectedHandler();
    },
    computed: {
        formTitle: function () {
            if (this.mode == 'edit') {
                return this.$t('resource.edit.title');
            }
            return this.$t('resource.create.title')
        },
        mapdata: function () {
            return {
                mapCenter: latLng(this.selected.latitude, this.selected.longitude),
                mapMarker: latLng(this.selected.latitude, this.selected.longitude),
                mapZoom: 17,
                mapOptions: {},
                mapTileLayerUrl:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                // mapTileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
                mapTileLayerOptions: {},
                mapTileLayerAttribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                // mapTileLayerAttribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                mapPopup: "<strong>" +this.resource.name + "</strong><br />" + this.resource.address + ', ' + this.resource.number + ' (' + this.resource.postal_code + ')<br />' + "<a href=\"geo:"+this.resource.latitude+","+this.resource.longitude+"\">Abrir en mapas</a>"
            }
        },
    },
    watch: {
        preselected: {
            handler: function () {
                // console.debug ('watch')
                this.preselectedHandler();
            },
            deep: true,
        }
    }
}
</script>
