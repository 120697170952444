<template id="template-imagepreview">
    <div :class="{'image-preview-container': true, 'image-in-slider': image.slider }" v-if="image.src">
        <a :href="imageSrc" @click.prevent="showImageDialog">
            <img
                :src="thumbSrc"
                :alt="image.alt_text"
                :title="image.title_text"
                class="image-preview"
            />
        </a>
        <dialog ref="imagedialog" class="imagedialog" @click="closeImageDialog">
            <img
                :src="imageSrc"
                :alt="image.alt_text"
                :title="image.title_text"
                class="image-fullview"
            />
        </dialog>
        <div v-if="(!ro && showactions)" class="actions-container">
            <a-button actionicon="bi bi-images" v-if="!image.slider"
                :actionroute="'images/review/' + this.image.imageable_id + '/slide/' + this.image.id"
                actionmethod="GET"
                @action-success="addSliderOk"
                @action-error="addSliderError">
                + Slider
            </a-button>
            <a-button actionicon="bi bi-images" v-if="image.slider"
                :actionroute="'images/review/' + this.image.imageable_id + '/unslide/' + this.image.id"
                actionmethod="GET"
                @action-success="removeSliderOk"
                @action-error="removeSliderError">
                - Slider
            </a-button>
            <a-button actionicon="bi bi-trash3-fill"
                buttonclass="btn btn-sm btn-outline-danger"
                :actionroute="'images/' + this.image.id"
                actionmethod="DELETE"
                @action-success="imageDeleteOk"
                @action-error="imageDeleteError">
                {{ $t('gui_buttons.Borrar') }}
            </a-button>
        </div>
        <!-- <div v-if="!ro" class="action">
            <action class="float-start w-100 p-0" type="image-delete-user-review" :id="image.id"></action>
        </div>
        <div v-if="!ro && image.imageable_id && image.slider == 0" class="action">
            <action class="float-start w-100 p-0" type="image-add-slider" :id="image.id" :actiondata="image"></action>
        </div>
        <div v-if="!ro && image.imageable_id && image.slider == 1" class="action">
            <action class="float-start w-100 p-0" type="image-remove-slider" :id="image.id" :actiondata="image"></action>
        </div> -->
    </div>
</template>
<script>
    export default {
        inject: ['ro'],
        props: {
            image: {},
            showactions: {
                default: false,
            }
        },
        data: function () {
            return {};
        },
        methods: {
            showImageDialog: function () {
                this.$refs.imagedialog.showModal();
            },
            closeImageDialog: function (event) {
                this.$refs.imagedialog.close();
            },
            imageDeleteOk: function () {
                console.debug('ImagePreview imageDeleteOk');
                this.$emit('image-deleted',this.image);
            },
            imageDeleteError: function () {
                console.debug('ImagePreview imageDeleteError');
            },
            addSliderOk: function () {
                console.debug('ImagePreview addSliderOk');
                this.image.slider = 1;
            },
            addSliderError: function () {
                console.debug('ImagePreview addSliderError');
            },
            removeSliderOk: function () {
                console.debug('ImagePreview removeSliderOk');
                this.image.slider = 0;

            },
            removeSliderError: function () {
                console.debug('ImagePreview removeSliderError');
            },
        },
        computed: {
            thumbSrc: function() {
                return this.image.baseurl + this.image.thumb;
            },
            imageSrc: function() {
                return this.image.baseurl + this.image.src;
            },
            actionRoute: function () {
                return 'images/review/' + this.image.imageable_id + '/slide/' + this.image.id;
            }
        },
    };
</script>
