<template id="template-translator">
    <div class="translator">
        <button
            title="Traducir desde otro idioma"
            class="btn btn-outline-secondary float-end"
            @click.prevent="showTranslationDialog"
            v-show="!is_nontranslatable()">
                <span class="glyphicon glyphicon-book"></span>
                <span ref="translationbuttontext" v-show="showlabels">Traducir desde otro idioma</span>
        </button>
        <button
            title="Idioma sin traducción automática"
            class="btn btn-outline-secondary float-end"
            @click="showTranslationDialog"
            v-bind:disabled="is_nontranslatable()"
            v-show="is_nontranslatable()">
                <span class="glyphicon glyphicon-book"></span>
                <span ref="translationbuttontext" v-show="showlabels">Idioma sin traducción automática</span>
        </button>
        <!-- <div class="translationerror color-red">
            <span class="glyphicon glyphicon-exclamation-sign"></span>
            Se produjo un error en la traducción.
        </div> -->
        <dialog id="translationdialog" ref="translationdialog">
            <label for="translationselector">Traducir {{ this.locales[this.toLanguageCode] }} desde: </label>
            <select
                v-bind:disabled="is_nontranslatable()"
                v-model="fromLanguageCode"
                class="form-select"
                @change="translationLangChange">
                <option value="">--</option>
                <option v-for="locale in dLocales" :value="locale.id" v-bind:key="locale.id" v-show="!lang_is_empty(locale.id) && from_lang_different(locale.id)">
                    {{ locale.language }}
                </option>
            </select>
            <button class="btn btn-outline-secondary mt-2" @click.prevent="requestTranslation">Pedir traducción</button>
            <button class="btn btn-outline-secondary mt-2" @click.prevent="cancelTranslation">Cancelar</button>
        </dialog>
    </div>
</template>
<script>
export default {
    props: {
        showlabels: {
            type: Boolean,
            required: false,
            default: false
        },
        locales: {
            type: Array,
            required: true,
        },
        showingLanguage: {
            type: String,
            required: true,
        }
    },
    data: function() {
        return {
            fromLanguageCode: null,
            toLanguageCode: this.showingLanguage,
            dialog: {},
        }
    },
    mounted: function() {
        this.dialog = this.$refs.translationdialog;
    },
    methods: {
        is_nontranslatable() {
            return [0,'ca','eu','ga','cv'].includes(this.showingLanguage);
        },
        lang_is_empty(localeid) {
            console.debug('lang_is_empty', this.$parent.dTranslations, localeid)
            var localetext = this.$parent.dTranslations[localeid] ?? null;
            return (!localetext || !localetext.length )
        },
        from_lang_different(localeid) {
            return localeid != this.$parent.selectedLanguage;
        },
        translationLangChange(event) {
            this.fromLanguageCode = event.target.value;
        },
        showTranslationDialog() {
            this.dialog.showModal();
        },
        requestTranslation() {

            var confirmTranslationText = '¿Solicitar traducción de ' +
                this.locales[this.fromLanguageCode] +
                ' a ' +
                this.locales[this.toLanguageCode] + '?';

            if (confirm(confirmTranslationText)) {
                this.dialog.close();
                this.doTranslate();
            }
        },
        cancelTranslation() {
            this.dialog.close();
        },
        doTranslate() {
            let translationbuttontext = this.$refs.translationbuttontext;

            translationbuttontext.innerHtml = 'Traduciendo'

            let text = this.$parent.dTranslations[this.fromLanguageCode];

            let submitData = {
                text: text,
                from_lang: this.fromLanguageCode,
                to_lang: this.toLanguageCode
            }

            let translationUrl = 'translate';

            t4a.post(translationUrl, submitData)
                .then(function(response) {
                    this.$emit('translation-received', response.data);
                }.bind(this))
                .catch(t4a_error_handler);
        }
    }
};
</script>
