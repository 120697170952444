const { default: axios } = require("axios");

var authMixin = {
    data: function() {
        return {
            errors: {},
            response: {},
            status: '',
        }
    },
    methods: {
        createApiClient: function (token = null) {
            let jwt_token = token ?? document.querySelector('input[type=hidden]#jwttoken')?.value;
            let locale = document.querySelector('html').attributes['lang'].value;
            let authString = "Bearer " + jwt_token;
            window.t4a = axios.create({
                headers: {
                    'Authorization': authString,
                    'Content-Language': locale
                },
                // baseURL: 'https://www.tur4all.com/api/v1.1/'
                // baseURL: 'http://wsl.tur4all.com/api/v1.1/'
                baseURL: window.t4aapiurl
            });

            window.t4a_error_handler = function(error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response);
                    this.errors = error.response.data.errors;
                    if (error.response.status >= 400) {
                        this.status = 'ERROR';
                    }
                    // console.debug(error.response.data.errors.exception);
                    
                    // if (error.response.data.errors.exception == 'Token Signature could not be verified.') {
                        // redirigimos al logout y pedimos al usuario que vuelva a iniciar sesión?
                    // }
                    
                    // if (error.response.data.errors.exception == 'Token not provided') {
                        // unauthorized
                    // }

                    // if (error.response.data.errors.exception == 'Token has expired') {
                        // console.debug('Refrescando token')
                        // this.refreshToken();
                        // hacemos una petición de refresh y volvemos a intentar o algo?
                    // }
                    
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                  }
                //   console.log(error.config);
            }
        },
    },

    created: function () {
        this.createApiClient();
    },
};

module.exports = authMixin;