<template id="cu-pregunta-texto">
  <div class="question question-text pregunta pregunta-texto">
    <strong>{{ question.text_question }}:</strong>
    <span class="form-text d-inline-block" v-show="!ro">{{ question.text_help }}</span>
    <input type="text" :name="question.form_name" :id="question.form_id" v-model="question.answer" :readonly="ro" v-if="!ro" />
    <span v-if="ro">{{  question.answer }}</span>

    <cu-subset v-for="subset in question.subsets" :subset="subset" :key="subset.key"></cu-subset>
  </div>
</template>
<script>
export default {
  props: ['question'],
  inject: ['ro'],
  methods: {
    clearAnswer: function (e) {
      var emptyValue = null;
      this.question.answer = emptyValue;
    }
  }
};
</script>
