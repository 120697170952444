<template id="cu-quiz">
    <div id="quiz-container" class="container my-5">
        <div class="row">
            <div class="col-12">
                <div v-if="questionnaire">
                    <h2 v-if="questionnaire?.resource.name">{{ questionnaire?.resource.name }}</h2>
                    <h3>{{ $t('Cuestionario de accesibilidad') }}</h3>
                    <div class="user-questionnaire mt-5" v-if="questionnaire?.resource.name">
                        <cu-criteria :criteria="criteria" />

                        <cu-seccion v-for="section in questionnaire?.sections" :section="section"
                            :key="section.key"></cu-seccion>

                    </div>
                    <div v-if="questionnaire?.resource.name">
                        <button type="submit" @click.prevent="submit" class="btn btn-primary">
                            {{ $t('gui_buttons.Enviar') }}
                        </button>
                        <a-status :astatus="status" class="fs-1 align-bottom" />
                    </div>
                    <div class="alert alert-success submit-success hidden" role="alert" v-if="this.response.review_id">
                        {{ $t('api_errors.Inspección creada correctamente') }}
                    </div>
                    <div class="alert alert-danger submit-error hidden" role="alert" v-if="this.errors.length">
                        {{ $t('api_errors.Ocurrió un error al crear la inspección') }}
                        <p v-for="error in errors" class="text-danger">{{ $t('api_' + error) }}</p>
                    </div>
                    <div v-if="filestatus.length">
                        <uploadstatus :filestatus="filestatus"></uploadstatus>
                    </div>
                </div>
                <div v-if="!questionnaire">
                    <span class="bi bi-arrow-repeat text-muted a-status-in-progress"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mixins: [authMixin, formMixin],
    props: {
        slug: String
    },
    provide: {
        ro: false,
    },
    data: function () {
        return {
            questionnaire: null,
            quizurl: 'resources/' + this.slug + '/user/questionnaire',
            uploadurl: '',
            criteria: {}
        }
    },
    created: function () {
        this.getQuestionnaire(this.quizurl);
    },
    methods: {
        getQuestionnaire: function (qUrl) {
            t4a.get(qUrl)
                .then(
                    function (response) {
                        this.questionnaire = response.data;
                        if (response.data.resource.summary) {
                            this.criteria = response.data.resource.summary;

                        }
                    }.bind(this)
                )
                .catch(
                    t4a_error_handler.bind(this)
                );
        },
        buildSubmitData: function () {
            return {
                resource: this.questionnaire.resource,
                sections: this.questionnaire.sections,
                criteria: this.criteria
            };
        },
        submit: function () {
            this.reset();
            this.status = 'SUBMITTING';

            // console.debug('submit a ' + this.quizurl);
            t4a.post(this.quizurl, this.buildSubmitData())
                .then(
                    function (response) {
                        if (response.status == 200) {
                            this.response = response.data;
                            // this.uploadurl = 'user/review/' + response.data.review_id + '/upload';
                            this.uploadurl = 'user/review/' + this.questionnaire.resource.id + '/upload';
                            console.debug('uploads a ' + this.uploadurl);
                            this.startUploads(this.uploadurl);
                            // this.showSuccess();
                        }
                    }.bind(this)
                )
                .catch(
                    function (error) {
                        if (error.response) {
                            for (var errorKey in error.response.data.errors) {
                                this.errors.push(error.response.data.errors[errorKey]);
                            }
                        }
                        // this.showError();
                        window.t4a_error_handler(error).bind(this);
                    }.bind(this)
                );
        },
        toggleCriteriaSection: function () {
            console.debug('toggleCriteriaSection');
            //     var section = $('.panel-body.criteria');
            //     if (section.css('display') == 'none') {
            //         section.show('slow');
            //     } else {
            //         section.hide('slow');
            //     }
        }
    },
};
</script>
