<template id="blog-admin">
    <div id="blog-admin-container" class="container py-5">
        <div class="row">
            <div class="col-12">
                <h2>Gestión de noticias</h2>
            </div>
        </div>
        <div class="row border border-primary rounded mb-3 p-3">
            <div class="col-12 d-flex align-items-center">
                <a href="/admin/blogs/create" class="btn btn-primary float-start">{{ $t('blog.admin.Crear nueva noticia') }}</a>
            </div>
        </div>
        <div class="row"  v-show="response.data?.length">
            <div class="col-12">
                <table class="table table-bordered table-responsive align-middle">
                    <tbody>
                        <tr v-for="blog in response.data">
                            <td class="blog-admin-image-container">
                                <div class="ratio ratio-1x1">
                                    <img :src="blog.cover.baseurl + blog.cover.src" class="img-fluid" />
                                </div>
                            </td>
                            <th scope="row">
                                <a :href="'/blogs/'+blog.slug">{{ blog.title }}</a>
                            </th>
                            <td>
                                <a :href="'/admin/blogs/'+blog.slug+'/edit'" class="btn btn-sm btn-outline-primary">Editar</a>
                                <a-button actionicon="bi bi-trash3-fill"
                                    buttonclass="btn btn-sm btn-outline-warning"
                                    :actionroute="'blog/' + blog.slug"
                                    actionmethod="DELETE"
                                    @action-success="blogDeleteOk(blog)"
                                    @action-error="blogDeleteError">
                                    {{ $t('gui_buttons.Borrar') }}
                                </a-button>   
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pagination :response="response"  v-if="response" />
    </div>
</template>
<script>
export default {
    mixins: [authMixin, formMixin],
    created: function () {
        let urlparams = new URLSearchParams(window.location.search);
        let getparams = {};
        for (const [key, value] of urlparams.entries()) {
            getparams[key] = value;
        }
        this.getBlogs(getparams);
    },
    methods: {
        getBlogs: function (params = null) {
            let url = 'blog';
            var search_params = params;
            console.debug(params);
            t4a.get(url,{ params: search_params})
            .then(
                function(response) {
                    this.response = response.data;
                    this.blogs = this.response.data;
                }.bind(this)
            ).catch(t4a_error_handler.bind(this));
        },
        blogDeleteOk: function (blog) {
            this.response.data = this.response.data.filter(function(item) {
                return item.id != blog.id;
            })
        },
        blogDeleteError: function () {
            
        }
    },

}
</script>
    