<template id="cu-pregunta-imagen">
    <div>
        <p class="form-text d-inline-block">{{ $t("gui_js_components_questionnaire_user_cupreguntaimagen['Imágenes más recientes para este contexto']") }}.</p>
        <imagecontainer
            :previews="previews"
            :howmany="6"
            showactions="true"
            ref="imagepreviews" />

        <imageinput
            :imagekey="fullKey"
            :imagename="imagename"
            :howmany="6"
            :multiple="true"
            ref="imageinput"
            capturealt="true"
            @queued-uploads="onQueuedUploads" />
    </div>
</template>
<script>
    export default {
        mixins: [uploadsMixin],
        inject: ['ro'],
        props: {
            previews: {
                default: function () {
                    return [];
                },
            },
            imagekey: {
                default: function () {
                    return '';
                },
                required: true,
            },
            imagename: {
                default: function () {
                    return '';
                },
                required: true,
            },
        },
        methods: {
            clearAnswer: function () {
                this.$refs.imageinput.clearFiles();
            },
        },
        computed: {
            fullName: function () {
                return this.$parent.getClave();
            },
            fullKey: function () {
                return this.$parent.getClave();
            }
        }
    };
</script>
