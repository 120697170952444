<template id="resource-create">
    <div id="resource-create-container" class="container py-5">
        <div class="row">
            <div class="col-12 mb-3">
                <resource-form ref="resourceform" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            resources: [],
            totalresources: 0,
            checked: false,
            formSetup: {},
            preselected: {
                fid_category: '',
                fid_subcategory: '',
                name: ''
            }
        }
    },
    created: function () {
        // this.showSpinner();
        // this.getResources();
    },
    methods: {
        getResources: function (params = null) {
            this.preselected = params;
            let url = 'resources/search';
            let baseParams = {
                fid_town: 474, // Ávila (localidad)
            }
            var search_params = { ...baseParams, ...params };

            console.debug(search_params);
            t4a.get(url, { params: search_params })
                .then(
                    function (response) {
                        console.debug(response);
                        this.response = response.data;
                        this.resources = response.data.results;
                        this.totalresources = response.data.totalResultResources;
                        this.checked = true;
                    }.bind(this)
                ).catch(t4a_error_handler.bind(this));
        },
        onClickedEdit: function (resource) {
            this.resources = [];
            this.preselected = resource;
            this.$forceUpdate();
            
        }
    }
}
</script>
