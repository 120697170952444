<template id="cu-pregunta-radio">
    <div class="question question-radio pregunta pregunta-radio">
        <strong>{{ question.text_question }}</strong>: <span v-if="ro">{{  answerOption.text }}</span>
        <span v-if="hasSubsets()" class="has-subsets bi bi-clipboard-plus desplegable"
            :title="titleString" v-show="!ro"></span>
        <span class="form-text" v-show="!ro">{{ question.text_help }}</span>
        <div v-for="option in question.options" :key="option.form_id" class="radio form-radio" v-if="!ro">
            <label :for="option.form_id">
                <input
                    type="radio"
                    :name="option.form_name"
                    :id="option.form_id"
                    class="form-check-input"
                    :value="option.value"
                    @change="checkCriterios"
                    v-model="question.answer" number
                    v-show="!ro"
                >
                <!-- <span class="bi bi-check" v-show="ro"></span> -->
                <span class="option-text" v-show="!ro">{{ option.text }}</span>
            </label>
            <span class="form-text" v-show="!ro">{{ option.text_help }}</span>
        </div>
        <button class="btn btn-outline-danger mt-3" @click.prevent="clearAnswer" v-show="!ro">{{  $t("gui_js_components_questionnaire_user_cupreguntaradio['Limpiar respuesta']") }}</button>
        <div class="clearfix"></div>
        <cu-subset v-for="subset in question.subsets" :subset="subset" :key="subset.key" v-show=""></cu-subset>
    </div>
</template>

<script>
  export default {
    props: ['question'],
    inject: ['ro'],

    computed: {
        titleString: function (){
            return this.$t("gui_js_components_questionnaire_user_cupreguntaradio['Responder esta pregunta muestra un cuestionario adicional']");
        },
        answerOption: function() {
            if (this.question.answer==null) {
                return null;
            }

            return this.question.options.find(function (item) {
                return item.value == this.question.answer;
            }.bind(this))
        }
    },
    methods: {
        hasSubsets: function() {
            return this.question.subsets.length > 0;
        },
      clearAnswer: function (e) {
        var emptyValue = null;
        this.question.answer = null;
        this.question.options.forEach(function(item, index, array){
          var fakeEvent = {
            target: {
              name: item.form_name,
              value: item.value,
              checked: false
            }
          };
          this.checkCriterios(fakeEvent);
        }.bind(this));
      },
      checkCriterios: function (e) {
        // console.debug('radio checkCriterios');
        this.$children.forEach(function(subset){
          subset.disable();
        }.bind(this));
        this.checkCriterioForElement(e.target);
      },
      checkCriterioForElement: function (element) {
        var radiovalue = element.value;
        var radioischecked = element.checked;
        var criterios = this.getCriterios(radiovalue);
        criterios.forEach( function(criterio) {
          var subset = this.getSubset(criterio.child_set_key);
          if (subset) {
            if (radioischecked) {
              subset.enable();
            } else {
              subset.disable();
            }
          }

        }.bind(this));
      },

      getSubset: function(clave) {
        var subsets = this.$children;

        for (var i = 0; i < subsets.length; i++) {
          if (subsets[i].subset.key == clave) {
            return subsets[i];
          }
        }
        return undefined;
      },

      getCriterios: function(value) {
        return this.question.criteria.filter( function(criterio) {
          return criterio.condition_value == value;
        });
      }
    },

    mounted: function() {
      this.$children.forEach(function(subset){
        subset.active = 0;
      }.bind(this));
      // this.elements = $('[name='+this.question.form_name+']:checked').get();
      this.elements = this.$el.querySelectorAll('input:checked');
      this.elements.forEach(this.checkCriterioForElement);
    }
  };

</script>
