<template id="resource-report">
    <div id="resource-report-container">
        <div class="row">
            <div class="report-container" v-if="report">
                <cu-seccion v-for="section in report.sections" :section="section" :key="section.key"></cu-seccion>
            </div>
        </div>
        <div class="row">
            <div class="col-12 t4a-backlink-container">
                <div class="card backlink-card d-flex flex-row">
                    <div class="backlink-card-image-container">
                        <a href="https://tur4all.com"><img src="/images/imagotipo-tur4all-opti.svg" role="presentation" class="backlink-card-image"></a>
                    </div>
                    <div class="backlink-card-text-container">
                        <div class="backlink-card-text-name">{{ $t("backlink['Recurso analizado en colaboración con']") }} <a href="https://tur4all.com">{{ $t("backlink['TUR4all Turismo accesible']") }}</a></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    inject: ['locales', 'accessibilitycriteria'],
    provide: {
        ro: true,
    },
    props: [
        'slug'
    ],
    mixins: [
        authMixin
    ],
    data: function () {
        return {
            report: null
        }
    },
    mounted: function() {
        this.getReport();
    },
    methods: {
        getReport: function () {
            let resource_url = 'resources/' + this.slug + '/user-report';
            t4a.get(resource_url).then(function (response) {
                this.report = response.data;
            }.bind(this));
        },
    },
}
</script>
