<template id="cu-seccion-template">
    <div class="panel panel-default seccion">
        <div class="panel-heading">
            <h4 class="panel-title">{{ $t("gui_js_components_resourcefilter['Criterios de accesibilidad']") }}</h4>
        </div>
        <div id="section_criteria" class="panel-body">
            <div class="set criteria-cards-container row" id="set_criteria">
                <div v-for="criterion in this.accessibilitycriteria" class="col-12 col-md-3 d-flex flex-row">
                    <label class="card criteria-card p-0 d-flex flex-row" :for="criterion.key">
                        <div class="me-1 criteria-card-image-container">
                            <img class="criteria-card-image" :src="criterion.pictogram" role="presentation" />
                        </div>
                        <div class="criteria-card-text-container">
                            <input class="form-check-input me-1 criteria-card-text-input"
                                type="checkbox"
                                :name="criterion.key"
                                :id="criterion.key"
                                v-model="criteria[criterion.key]"
                                true-value="1"
                                false-value="0">
                            <span class="criteria-card-text-name">{{ criterion.name }}</span>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['ro','accessibilitycriteria'],
    props: {
        criteria: {
            required: true,
            default: function () {
                return {}
            }
        }
    }
};
</script>
