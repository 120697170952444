var uploadsMixin = {
    // comportamiento comun a ImagePicker, CUPreguntaImagen y CTPreguntaImagen
    methods: {
        onQueuedUploads: function (howmanyuploads) {
            this.$refs.imagepreviews.onQueuedUploads(howmanyuploads);
        },
        startUploads: function (url) {
            // console.debug('uploads-mixin startUploads', url);
            return this.$refs.imageinput.startUploads(url);
        },
        registerWithParent: function () {
            // console.debug('bus.$emit register-uploader', this)
            bus.$emit('register-uploader', this);
        },
        deregisterWithParent: function () {
            // console.debug('bus.$emit deregister-uploader', this)
            bus.$emit('deregister-uploader', this);
        }
    },
    created: function () {
        this.registerWithParent();
    },
    beforeDestroy: function () {
        this.deregisterWithParent();
    },
};
module.exports = uploadsMixin;