<template id="blog-index">
<div id="blog-index-container" class="container py-5">
    <div class="row justify-content-center mb-5">
        <div class="col-12">
            <h2>{{ $t('blog.index.Noticias') }}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12" v-for="(blog, index) in response.data">
            <blog-item :blog="blog" :index="index" class="mb-5" />
            <hr class="border-gradient border-gradient-purple border-only-bottom mb-5" v-if="index < response.data.length-1" />
        </div>
    </div>
    <pagination :response="response" v-if="response" />
</div>
</template>
<script>
export default {
    mixins: [authMixin, formMixin],
    data: function () {
        return {
            blogs: [],
            response: {}
        }
    },
    created: function () {

        let urlparams = new URLSearchParams(window.location.search);
        let getparams = {};
        for (const [key, value] of urlparams.entries()) {
            getparams[key] = value;
        }

        this.getBlogs(getparams);
    },
    methods: {
        getBlogs: function (params = null) {
            let url = 'blog';
            var search_params = params;
            console.debug(params);
            t4a.get(url,{ params: search_params})
            .then(
                function(response) {
                    this.response = response.data;
                    this.blogs = this.response.data;
                }.bind(this)
            ).catch(t4a_error_handler.bind(this));
        }
    },

}
</script>
