export default {
    "en": {
        "Si": "Yes",
        "Sí": "Yes",
        "Pulsa para desplegar una lista de filtros según criterios de accesibilidad": "Press to show a list of accessibility criteria filters",
        "backlink": {
            "Recursos analizados en colaboración con": "Resources analysed in cooperation with",
            "Recurso analizado en colaboración con": "Resource analysed in cooperation with",
            "TUR4all Turismo accesible": "TUR4all Accessible Tourism"
        },
        "translatable-textarea": {
            "Mostrando idioma": "Current language",
            "Traducir": "Translate",
            "Idioma sin traducción automática": "Language without auto-translation",
            "desde": "from",
            "Pedir traducción": "Request translation",
            "Cancelar": "Cancel"
        },
        "pagination": {
            "pagination": "pagination",
            "mostrando": "Showing",
            "desde": "from",
            "a": "to",
            "de": "of",
            "resultados": "results",
            "First": "First",
            "Previous": "Previous",
            "Next": "Next",
            "Last": "Last",
        },
        "blog": {
            "admin": {
                "Crear nueva noticia": "Create new News Post"
            },
            "index": {
                "Noticias": "News posts",
            },
            "edit": {
                "title": "News edition",
            },
            "create": {
                "title": "New news post",
            },
            "form": {
                "title": "Title",
                "intro": "Intro",
                "content": "Content",
                "cover": "Featured image",
                "required": "This field is required"

            }
        },
        "resource": {
            "admin": {
                "Crear nuevo recurso": "Create new resource"
            },
            "index": {
                "Recursos accesibles": "Accessible resources"
            },
            "edit": {
                "title": "Resource edition"
            },
            "create": {
                "title": "New resource"
            },
            "form": {
                "name": "Name",
                "category": "Category",
                "category-help": "Category can't be modified because it defines the resource's accessibility characteristics",
                "subcategory": "Subcategory",
                "subcategory-help": "Subcategory can't be modified because it defines the resource's accessibility characteristics",
                "address": "Street Name",
                "number": "Number",
                "postal_code": "Postal code",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "latitude-help": "Mark the decimal with a point. Don't mark minutes nor seconds",
                "longitude-help": "Mark the decimal with a point. Don't mark W or E, minutes nor seconds",
                "telephone": "Telephone",
                "email": "Email",
                "url": "URL",
                "contact_name": "Contact Name",
                "contact_email": "Contact Email",
                "contact_position": "Contact Position",
                "contact_phone": "Contact Phone",
                "description": "Description",
                "cover": "Featured Image",
                "required": "This field is required",
                "map-help": "Double click a location in the map to automatically set the coordinates.",
                "search-address-button": "Click to set the marker to the selected address",
                "search-address-button-help": "Street Name and Number need to be set to set the marker"
            },
            "report": {
                "link": "Jump to the detailed accessibility report",
            }
        },
        "gui_buttons": {
            "¿Estás seguro?": "Are you sure?",
            "Acciones": "Actions",
            "Añadir comentario": "Add comment",
            "Añadir recurso": "Add resource",
            "Borrar": "Delete",
            "Borrar imagen": "Delete image",
            "Buscar": "Search",
            "Cambiar contraseña": "Change password",
            "Cancelar": "Cancel",
            "Cerrar sesión": "Close session",
            "Comentar": "Comment",
            "Comentarios": "Comments",
            "Compartir": "Share",
            "Conectar con Facebook": "Login with Facebook",
            "Conectar con Twitter": "Login with Twitter",
            "Crear": "Create",
            "Crear cuenta": "Create account",
            "Crear inspección": "Create inspection",
            "Crear recurso": "Create resource",
            "Descargar": "Download",
            "Descargar informe": "Download report",
            "Deshacer cambios": "Disregard changes",
            "Deshacer reporte": "Disregard report",
            "Editar": "Edit",
            "Editar datos de contacto": "Edit contact data",
            "Editar imágenes": "Edit images",
            "Envía tu comentario": "Send your comment",
            "Enviar": "Send",
            "Evaluación técnica": "Technical validation",
            "Evaluación rápida": "User review",
            "Fecha de creación": "Date created",
            "Filtrar": "Filter",
            "Guardar": "Save",
            "Informe de técnico": "Technical report",
            "Informe de usuario": "Professional report",
            "Inicia sesión": "Start session",
            "Inspecciones de técnico": "Technical inspections",
            "Inspecciones de usuario": "Professional inspections",
            "Inspecciones rápidas": "User reviews",
            "Leer más": "Read more",
            "Limpiar": "Clean",
            "Limpiar búsqueda": "Clean search",
            "Limpiar respuesta": "Clean answer",
            "Moderar": "Change",
            "Modificar recurso": "Change resource",
            "No": "No",
            "Nueva búsqueda": "New search",
            "Ocultar código": "Hide code",
            "Participa": "Participate",
            "Quitar": "Delete",
            "Restaurar": "Restore",
            "Resultados": "Results",
            "Saltar a cuestionario de usuario": "Pass on to users questionnaire",
            "Salvar": "Save",
            "Seleccionar imagen": "Select image",
            "Sí": "Yes",
            "Suscribete": "Subscrive",
            "Valorar": "Validate",
            "Ver código": "See code",
            "Ver destinos": "See destinations",
            "Ver experiencias": "See experiences",
            "Ver fotos": "See photos",
            "Ver información": "See information",
            "Ver mapa": "See map",
            "Ver más": "See more",
            "Versión para imprimir": "Printing version",
            "Volver": "Return",
            "Volver a cuestionario de técnico": "Return to technical questionnaire",
            "Volver al inicio": "Return to homepage",
            "Volver al listado": "Return to the list"
        },
        "gui_js_components_filepreviews": {
            "Tamaño": "Size"
        },
        "gui_js_components_questionnaire_technical_ctpreguntaimagen": {
            "Imágenes más recientes para este contexto": "Most recent images for the context",
            "Añadir texto alternativo localizado para estas imágenes": "Add Images alternative text"
        },
        "gui_js_components_imagepicker": {
            "El tamaño máximo permitido para las imágenes es de 2MB": "The maximum size allowed for images is 2MB"
        },
        "gui_js_components_filepicker": {
            "Se adjuntará al pulsar Enviar": "Will be added when pressed \"Send\"",
            "Seleccionar archivos": "Files selection",
            "Limpiar": "Clean"
        },
        "gui_js_components_switchaction": {
            "Sí": "Yes",
            "No": "No"
        },
        "gui_js_components_questionnaire_user_cupreguntamulti": {
            "Responder esta pregunta muestra un cuestionario adicional": "Answer this question will open an additional questionnaire"
        },
        "gui_vue": {
            "Subida de imágenes adjuntas": "Images upload",
            "Índice de cuestionarios raíz": "Basic questionnaire Index",
            "Inicio del cuestionario": "Begin of the questionnaire",
            "Final del cuestionario": "End of the questionnaire",
            "Inicio del subcuestionario": "Begin of the sub-questionnaire",
            "Final del subcuestionario": "End of the sub-questionnaire",
            "Índice de este contenedor": "Content Index",
            "Añadir subcuestionario": "Add sub-questionnaire",
            "Imágenes más recientes para este contexto": "Most recent images for the context"
        },
        "gui_js_components_questionnaire_user_cupreguntaradio": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional",
            "Limpiar respuesta": "Clean answer"
        },
        "gui_js_components_imageinput": {
            "Descripción de la imagen": "Image description",
            "Quitar": "Delete",
            "El tamaño máximo permitido para las imágenes es de 2MB": "The maximum size allowed for images is 2MB",
            "Subidas pendientes": "Pending upload",
            "Máximo": "Maximum",
            "Seleccionar imagen": "Select image",
            "Deshacer cambios": "Disregard changes"
        },
        "gui_js_components_element_resourcedescription": {
            "Descripción": "Description"
        },
        "gui_js_components_htmleditor": {
            "Ver código": "See code",
            "Ocultar código": "Hide code"
        },
        "gui_js_components_resourcefilter": {
            "Nombre": "Name",
            "Categoría": "Category",
            "Subcategoría": "Subcategory",
            "Solamente recursos analizados por expertos": "Only resources checked by experts",
            "País": "Country",
            "Comunidad": "Region",
            "Provincia": "Province",
            "Localidad": "City",
            "Criterios de accesibilidad": "Accessibility criteria",
            "Buscar": "Search",
            "Crear recurso": "Create resource",
            "Nueva búsqueda": "New search"
        },
        "api_dbfields": {
            "user_model": "user",
            "user_model_plural": "users",
            "user_name": "Name",
            "user_email": "Email",
            "user_password": "Password",
            "user_surnames": "Last name",
            "user_alias": "Alias",
            "user_cover": "User profile photo",
            "resource_model": "resource",
            "resource_model_plural": "resources",
            "resource_name": "Name",
            "resource_address": "Street",
            "resource_number": "Number",
            "resource_postal_code": "Postcode",
            "resource_telephone": "Telephone",
            "resource_telephone_abbreviation": "Tel",
            "resource_email": "Email",
            "resource_url": "Web page",
            "resource_description": "Description",
            "resource_latitude": "Latitude",
            "resource_longitude": "Longitude",
            "resource_contact_name": "Contact person",
            "resource_contact_email": "Contact person's email",
            "resource_contact_position": "Contact person's role",
            "resource_contact_phone": "Contact person's number",
            "resource_cover": "Cover photo of resource",
            "resource_title": "Tourism resource",
            "resource_location": "Location",
            "resource_coordinates": "GPS Coordinates",
            "resource_coordinates_help": "Separate the decimal numbers with a dot. Example: 45.342456",
            "resource_information": "Resource information",
            "resource_contact": "Contact information",
            "country_model": "country",
            "country_model_plural": "countries",
            "country_name": "Country",
            "Tur4all\\Models\\Location\\Country": "Country",
            "region_model": "region",
            "region_model_plural": "regions",
            "region_first_level_name": "Region",
            "region_second_level_name": "Province",
            "town_model": "city",
            "town_model_plural": "cities",
            "town_name": "City",
            "category_model": "category",
            "category_model_plural": "categories",
            "category_name": "Category",
            "subcategory_model": "subcategory",
            "subcategory_model_plural": "subcategories",
            "subcategory_name": "Subcategory",
            "destination_model": "destination",
            "destination_model_plural": "destinations",
            "destination_name": "Name",
            "destination_weight": "Weight",
            "Tur4all\\Models\\Destination\\Destination": "Destination",
            "Tur4all\\Models\\Destination\\DestinationDetail": "Destination Detail",
            "experience_model": "experience",
            "experience_model_plural": "experiences",
            "experience_name": "Name",
            "experience_weight": "Weight",
            "experience_cover": "Cover photo of the experience",
            "experience_information": "Key information",
            "Tur4all\\Models\\Experience\\Experience": "Experience",
            "Tur4all\\Models\\Experience\\ExperienceDay": "Experience Day",
            "blogarticle_model": "article",
            "blogarticle_model_plural": "articles",
            "blogarticle_title": "Title",
            "blogarticle_created_at": "Date created",
            "blogarticle_cover": "Cover photo of the headline",
            "eventpost_model": "event",
            "eventpost_model_plural": "events",
            "eventpost_title": "Title",
            "eventpost_where": "Where",
            "eventpost_who": "Organized by",
            "eventpost_when": "Date",
            "eventpost_created_at": "Date created",
            "eventpost_cover": "Cover photo of the event",
            "newspost_model": "news",
            "newspost_model_plural": "news",
            "newspost_title": "Title",
            "newspost_created_at": "Date created",
            "newspost_cover": "Photo of the news item"
        },
        "gui_js_components_userpicker": {
            "Resultados": "Results",
            "Seleccionados": "Selection"
        },
        "gui_js_components_questionnaire_user_cupreguntasingle": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional"
        },
        "gui_js_components_imagelocales": {
            "Guardar": "Save",
            "Textos alternativos guardados correctamente": "Alternative texts saved",
            "Ocurrió un error al guardar los textos alternativos": "Happened an error when uploading alternative texts",
            "¿Estas seguro?": "Are you sure?"
        },
        "gui_js_components_imagepreview": {
            "Moderar": "Change"
        },
        "gui_js_components_questionnaire_user_cupreguntaimagen": {
            "Imágenes más recientes para este contexto": "Most recent images for the context"
        },
        "gui_js_components_resourcepicker": {
            "Creado despues de": "Created after",
            "Creado antes de": "Created before",
            "Modificado despues de": "Modified after",
            "Modificado antes de": "Modified before",
            "Nombre": "Name",
            "País": "Country",
            "Comunidad": "Region",
            "Provincia": "Province",
            "Localidad": "City",
            "Categoría": "Category",
            "Subcategoría": "Subcategory",
            "Buscar": "Search",
            "Resultados": "Results",
            "Seleccionados": "Selection"
        },
        "api_errors": {
            "Errores": "Errors",
            "unique": "The field '{field}' already exists in the database and cannot be repeated.",
            "required": "The field '{field}' is required.",
            "min": "The field '{field}' must have a minimum of '{quantity}' digits.",
            "max": "Field '{field}' size is exceeded.",
            "email": "The email field doesn't have the right format.",
            "confirmed": "Passwords do not match.",
            "accepted": "You must accept the General Conditions to register.",
            "Ha ocurrido un error": "An error occurred",
            "El sitio web no está temporalmente disponible": "The site is temporarily unavailable",
            "La página que buscas no ha sido encontrada": "The page you are looking for wasn't found",
            "Puede que esa página ya no exista o que hayas escrito mal la dirección de la dirección URL": "It may be that that page no longer exists or you haven't written well the URL",
            "Prohibido. Permisos insuficientes.": "Forbidden. You do not have access",
            "Solicitud incorrecta": "Incorrect request",
            "Inspección creada correctamente": "Inspection inserted correctly. Please hold...",
            "Ocurrió un error al crear la inspección": "An error occurred while creating the inspection",
            "Permisos insuficientes": "Insufficient permissions"
        },
        "gui_js_components_uploadstatus": {
            "Subida de imágenes adjuntas": "Images upload"
        }
    },
    "es": {
        "Si": "Si",
        "Sí": "Si",
        "Pulsa para desplegar una lista de filtros según criterios de accesibilidad": "Pulsa para desplegar una lista de filtros según criterios de accesibilidad",
        "backlink": {
            "Recursos analizados en colaboración con": "Recursos analizados en colaboración con",
            "Recurso analizado en colaboración con": "Recurso analizado en colaboración con",
            "TUR4all Turismo accesible": "TUR4all Turismo accesible"
        },
        "translatable-textarea": {
            "Mostrando idioma": "Mostrando idioma",
            "Traducir": "Traducir",
            "Idioma sin traducción automática": "Idioma sin traducción automática",
            "desde": "desde",
            "Pedir traducción": "Pedir traducción",
            "Cancelar": "Cancelar"
        },
        "pagination": {
            "pagination": "paginación",
            "mostrando": "Mostrando",
            "desde": "desde",
            "a": "a",
            "de": "de",
            "resultados": "resultados",
            "First": "Primera",
            "Previous": "Anterior",
            "Next": "Siguiente",
            "Last": "Última",
        },
        "blog": {
            "admin": {
                "Crear nueva noticia": "Crear nueva noticia"
            },
            "index": {
                "Noticias": "Noticias",
            },
            "edit": {
                "title": "Edición de noticias",
            },
            "create": {
                "title": "Nueva noticia",
            },
            "form": {
                "title": "Título",
                "intro": "Entradilla",
                "content": "Contenido",
                "cover": "Imagen destacada",
                "required": "Este campo es obligatorio"

            }
        },
        "resource": {
            "admin": {
                "Crear nuevo recurso": "Crear nuevo recurso"
            },
            "index": {
                "Recursos accesibles": "Recursos accesibles"
            },
            "edit": {
                "title": "Edición de recurso"
            },
            "create": {
                "title": "Nuevo recurso"
            },
            "form": {
                "name": "Nombre",
                "category": "Categoría",
                "category-help": "La categoría no se puede modificar porque determina las características de accesibilidad del recurso.",
                "subcategory": "Subcategoría",
                "subcategory-help": "La subcategoría no se puede modificar porque determina las características de accesibilidad del recurso.",
                "address": "Nombre de la calle",
                "number": "Número",
                "postal_code": "Código postal",
                "latitude": "Latitud",
                "longitude": "Longitud",
                "latitude-help": "Separar la parte decimal con un punto. No especificar minutos ni segundos.",
                "longitude-help": "Separar la parte decimal con un punto. No especificar W o E, minutos ni segundos.",
                "telephone": "Teléfono",
                "email": "Email",
                "url": "URL",
                "contact_name": "Nombre de la persona de contacto",
                "contact_email": "Email de la persona de contacto",
                "contact_position": "Puesto/cargo de la persona de contacto",
                "contact_phone": "Teléfono de la persona de contacto",
                "description": "Descripción",
                "cover": "Imagen destacada",
                "required": "Este campo es obligatorio",
                "map-help": "Haga doble clic en una ubicación del mapa para fijar automáticamente las coordenadas.",
                "search-address-button": "Click para mover el marcador a la dirección indicada",
                "search-address-button-help": "El nombre de la calle y el número son necesarios para mover el marcador"
            },
            "report": {
                "link": "Saltar al informe detallado de accesibilidad",
            }
        },
        "gui_buttons": {
            "¿Estás seguro?": "¿Estás seguro?",
            "Estas seguro": "¿Estás seguro?",
            "Acciones": "Acciones",
            "Añadir comentario": "Añadir comentario",
            "Añadir recurso": "Añadir recurso",
            "Borrar": "Borrar",
            "Borrar imagen": "Borrar imagen",
            "Bor    rar adjunto": "Borrar adjunto",
            "Buscar": "Buscar",
            "Cambiar contraseña": "Cambiar contraseña",
            "Cancelar": "Cancelar",
            "Cerrar sesión": "Cerrar sesión",
            "Comentar": "Comentar",
            "Comentarios": "Comentarios",
            "Compartir": "Compartir",
            "Conectar con Facebook": "Conectar con Facebook",
            "Conectar con Twitter": "Conectar con Twitter",
            "Crear": "Crear",
            "Crear cuenta": "Crear cuenta",
            "Crear inspección": "Crear inspección",
            "Crear recurso": "Crear recurso",
            "Descargar": "Descargar",
            "Descargar informe": "Descargar informe",
            "Deshacer cambios": "Deshacer cambios",
            "Deshacer reporte": "Deshacer reporte",
            "Editar": "Editar",
            "Editar datos de contacto": "Editar datos de contacto",
            "Editar imágenes": "Editar imágenes",
            "Envía tu comentario": "Envía tu comentario",
            "Enviar": "Enviar",
            "Evaluación técnica": "Evaluación técnica",
            "Evaluación rápida": "Evaluación de usuario",
            "Fecha de creación": "Fecha de creación",
            "Filtrar": "Filtrar",
            "Guardar": "Guardar",
            "Informe de técnico": "Informe de técnico",
            "Informe de usuario": "Informe profesional",
            "Inicia sesión": "Inicia sesión",
            "Inspecciones de usuario": "Inspecciones profesional",
            "Inspecciones de técnico": "Inspecciones de técnico",
            "Inspecciones rápidas": "Inspecciones de usuario",
            "Leer más": "Leer más",
            "Limpiar": "Limpiar",
            "Limpiar búsqueda": "Limpiar búsqueda",
            "Limpiar respuesta": "Limpiar respuesta",
            "Moderar": "Moderar",
            "Modificar recurso": "Modificar recurso",
            "No": "No",
            "Nueva búsqueda": "Nueva búsqueda",
            "Ocultar código": "Ocultar código",
            "Participa": "Participa",
            "Quitar": "Quitar",
            "Restaurar": "Restaurar",
            "Resultados": "Resultados",
            "Saltar a cuestionario de usuario": "Saltar a cuestionario de usuario",
            "Salvar": "Salvar",
            "Seleccionar imagen": "Seleccionar imagen",
            "Sí": "Sí",
            "Suscribete": "Suscribete",
            "Valorar": "Valorar",
            "Ver código": "Ver código",
            "Ver destinos": "Ver destinos",
            "Ver experiencias": "Ver experiencias",
            "Ver fotos": "Ver fotos",
            "Ver imágenes": "Ver imágenes",
            "Ver información": "Ver información",
            "Ver mapa": "Ver mapa",
            "Ver más": "Ver más",
            "Versión para imprimir": "Versión para imprimir",
            "Volver": "Volver",
            "Volver a cuestionario de técnico": "Volver a cuestionario de técnico",
            "Volver al inicio": "Volver al inicio",
            "Volver al listado": "Volver al listado",
            "Exportar": "Exportar",
            "Exportar todas": "Exportar todas",
            "Exportar todos": "Exportar todos",
            "Exportar destino": "Exportar destino",
            "Exportar detalles": "Exportar detalles",
            "Exportar recursos": "Exportar recursos",
            "Exportar recurso": "Exportar recurso"
        },
        "gui_js_components_filepreviews": {
            "Tamaño": "Tamaño"
        },
        "gui_js_components_imagepicker": {
            "El tamaño máximo permitido para las imágenes es de 2MB": "El tamaño máximo permitido para las imágenes es de 2MB"
        },
        "gui_js_components_filepicker": {
            "Se adjuntará al pulsar Enviar": "Se adjuntará al pulsar \"Enviar\"",
            "Seleccionar archivos": "Seleccionar archivos",
            "Limpiar": "Limpiar"
        },
        "gui_js_components_switchaction": {
            "Sí": "Sí",
            "No": "No"
        },
        "gui_js_components_questionnaire_user_cupreguntamulti": {
            "Responder esta pregunta muestra un cuestionario adicional": "Responder esta pregunta muestra un cuestionario adicional"
        },
        "gui_vue": {
            "Subida de imágenes adjuntas": "Subida de imágenes adjuntas",
            "Índice de cuestionarios raíz": "Índice de cuestionarios raíz",
            "Inicio del cuestionario": "Inicio del cuestionario",
            "Final del cuestionario": "Final del cuestionario",
            "Inicio del subcuestionario": "Inicio del subcuestionario",
            "Final del subcuestionario": "Final del subcuestionario",
            "Índice de este contenedor": "Índice de este contenedor",
            "Añadir subcuestionario": "Añadir subcuestionario",
            "Imágenes más recientes para este contexto": "Imágenes más recientes para este contexto",
            "Responder esta pregunta es clave": "Responder esta pregunta es clave"
        },
        "gui_js_components_questionnaire_user_cupreguntaradio": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional",
            "Limpiar respuesta": "Limpiar respuesta"
        },
        "gui_js_components_imageinput": {
            "Descripción de la imagen": "Descripción de la imagen",
            "Quitar": "Quitar",
            "El tamaño máximo permitido para las imágenes es de 2MB": "El tamaño máximo permitido para las imágenes es de 2MB",
            "Subidas pendientes": "Subidas pendientes",
            "Máximo": "Máximo",
            "Seleccionar imagen": "Seleccionar imagen",
            "Deshacer cambios": "Deshacer cambios"
        },
        "gui_js_components_element_resourcedescription": {
            "Descripción": "Descripción"
        },
        "gui_js_components_htmleditor": {
            "Ver código": "Ver código",
            "Ocultar código": "Ocultar código"
        },
        "gui_js_components_resourcefilter": {
            "Nombre": "Nombre",
            "Categoría": "Categoría",
            "Subcategoría": "Subcategoría",
            "Solamente recursos analizados por expertos": "Solamente recursos analizados por expertos",
            "País": "País",
            "Comunidad": "Comunidad",
            "Provincia": "Provincia",
            "Localidad": "Localidad",
            "Criterios de accesibilidad": "Criterios de accesibilidad",
            "Buscar": "Buscar",
            "Crear recurso": "Crear recurso",
            "Nueva búsqueda": "Nueva búsqueda",
            "Exportar": "Exportar"
        },
        "api_dbfields": {
            "user_model": "usuario",
            "user_model_plural": "usuarios",
            "user_name": "Nombre",
            "user_email": "Correo electrónico",
            "user_password": "Contraseña",
            "user_surnames": "Apellidos",
            "user_alias": "Alias",
            "user_cover": "Imagen del perfil de usuario",
            "resource_model": "recurso",
            "resource_model_plural": "recursos",
            "resource_name": "Nombre",
            "resource_address": "Calle",
            "resource_number": "Número",
            "resource_postal_code": "Código Postal",
            "resource_telephone": "Teléfono",
            "resource_telephone_abbreviation": "Tlfno",
            "resource_email": "Correo electrónico",
            "resource_url": "Página web",
            "resource_description": "Descripción",
            "resource_latitude": "Latitud",
            "resource_longitude": "Longitud",
            "resource_contact_name": "Persona de contacto",
            "resource_contact_email": "Email de la persona de contacto",
            "resource_contact_position": "Cargo de la Persona de contacto",
            "resource_contact_phone": "Teléfono de la persona de contacto",
            "resource_cover": "Imagen de portada del recurso",
            "resource_title": "Recurso turístico",
            "resource_location": "Ubicación",
            "resource_coordinates": "Coordenadas",
            "resource_coordinates_help": "Separar la parte decimal con un punto \".\" Ejemplo: -45.342456",
            "resource_information": "Información del recurso",
            "resource_contact": "Información de contacto",
            "Tur4all\\Models\\Resource\\Resource": "Recurso",
            "country_model": "país",
            "country_model_plural": "paises",
            "country_name": "País",
            "Tur4all\\Models\\Location\\Country": "País",
            "region_model": "región",
            "region_model_plural": "regiones",
            "region_first_level_name": "Comunidad",
            "region_second_level_name": "Provincia",
            "Tur4all\\Models\\Location\\Region": "Comunidad \/ Provincia",
            "town_model": "localidad",
            "town_model_plural": "localidades",
            "town_name": "Localidad",
            "Tur4all\\Models\\Location\\Town": "Localidad",
            "category_model": "categoría",
            "category_model_plural": "categorías",
            "category_name": "Categoría",
            "Tur4all\\Models\\Resource\\Category": "Categoría",
            "subcategory_model": "subcategoría",
            "subcategory_model_plural": "subcategorías",
            "subcategory_name": "Subcategoría",
            "Tur4all\\Models\\Resource\\Subcategory": "Subcategoría",
            "destination_model": "destino",
            "destination_model_plural": "destinos",
            "destination_name": "Nombre",
            "destination_weight": "Peso",
            "Tur4all\\Models\\Destination\\Destination": "Destino",
            "Tur4all\\Models\\Destination\\DestinationDetail": "Detalle Destino",
            "experience_model": "experiencia",
            "experience_model_plural": "experiencias",
            "experience_name": "Nombre",
            "experience_weight": "Peso",
            "experience_cover": "Imagen de portada de la experiencia",
            "experience_information": "Información de interés",
            "Tur4all\\Models\\Experience\\Experience": "Experiencia",
            "Tur4all\\Models\\Experience\\ExperienceDay": "Experiencia, Día",
            "blogarticle_model": "artículo",
            "blogarticle_model_plural": "artículos",
            "blogarticle_title": "Título",
            "blogarticle_created_at": "Fecha de creación",
            "blogarticle_cover": "Imagen de cabecera del artículo",
            "eventpost_model": "evento",
            "eventpost_model_plural": "eventos",
            "eventpost_title": "Título",
            "eventpost_where": "Lugar",
            "eventpost_who": "Organiza",
            "eventpost_when": "Fecha",
            "eventpost_created_at": "Fecha de creación",
            "eventpost_cover": "Imagen de portada del evento",
            "newspost_model": "noticia",
            "newspost_model_plural": "noticias",
            "newspost_title": "Título",
            "newspost_created_at": "Fecha de creación",
            "newspost_cover": "Imagen de la noticia"
        },
        "gui_js_components_userpicker": {
            "Resultados": "Resultados",
            "Seleccionados": "Seleccionados"
        },
        "gui_js_components_questionnaire_user_cupreguntasingle": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional"
        },
        "gui_js_components_imagelocales": {
            "Guardar": "Guardar",
            "Textos alternativos guardados correctamente": "Textos alternativos guardados correctamente",
            "Ocurrió un error al guardar los textos alternativos": "Ocurrió un error al guardar los textos alternativos",
            "¿Estas seguro?": "¿Estás seguro?"
        },
        "gui_js_components_imagepreview": {
            "Moderar": "Moderar"
        },
        "gui_js_components_questionnaire_user_cupreguntaimagen": {
            "Imágenes más recientes para este contexto": "Imágenes más recientes para este contexto"
        },
        "gui_js_components_resourcepicker": {
            "Creado despues de": "Creado despues de",
            "Creado antes de": "Creado antes de",
            "Modificado despues de": "Modificado despues de",
            "Modificado antes de": "Modificado antes de",
            "Nombre": "Nombre",
            "País": "País",
            "Comunidad": "Comunidad",
            "Provincia": "Provincia",
            "Localidad": "Localidad",
            "Categoría": "Categoría",
            "Subcategoría": "Subcategoría",
            "Buscar": "Buscar",
            "Resultados": "Resultados",
            "Seleccionados": "Seleccionados"
        },
        "api_errors": {
            "Errores": "Errores",
            "unique": "El campo '{field}' ya está registrado en la base de datos y no puede repetirse.",
            "required": "El campo '{field}' es obligatorio.",
            "min": "El campo '{field}' debe tener un mínimo de '{quantity}' dígitos.",
            "max": "Se ha sobrepasado el tamaño del campo '{field}'",
            "email": "El campo email no tiene el formato adecuado.",
            "confirmed": "Las contraseñas no coinciden.",
            "accepted": "Es necesario aceptar las condiciones generales para registrarse.",
            "latlng": "El campo '{field}' no tiene el formato correcto.",
            "Ha ocurrido un error": "Ha ocurrido un error",
            "El sitio web no está temporalmente disponible": "El sitio web no está temporalmente disponible",
            "La página que buscas no ha sido encontrada": "La página que buscas no ha sido encontrada",
            "Puede que esa página ya no exista o que hayas escrito mal la dirección de la dirección URL": "Puede que esa página ya no exista o que hayas escrito mal la dirección de la dirección URL",
            "Prohibido. Permisos insuficientes.": "Prohibido. Permisos insuficientes.",
            "Solicitud incorrecta": "Solicitud incorrecta",
            "Inspección creada correctamente": "Inspección creada correctamente. Por favor espera mientras se adjuntan las imágenes, puede tardar un rato",
            "Ocurrió un error al crear la inspección": "Ocurrió un error al crear la inspección",
            "El recurso ha sido cerrado por un técnico y no puede ser modificado por usuarios": "El recurso ha sido cerrado por un técnico y no puede ser modificado por usuarios",
            "Permisos insuficientes": "Permisos insuficientes",
            "Operación realizada": "Operación realizada",
            "registercontent_ok": "'{model}' creado correctamente",
            "registercontent_fail": "Error al crear '{model}'",
            "modificatecontent_ok": "'{model}' modificado correctamente",
            "modificatecontent_fail": "Error al modificar '{model}'",
            "Evento modificado correctamente": "Evento modificado correctamente",
            "Error al modificar el evento": "Error al modificar el evento",
            "Evento creado correctamente": "Evento creado correctamente",
            "Error al crear el evento": "Error al crear el evento",
            "Usuario modificado correctamente": "Usuario modificado correctamente",
            "Error al modificar el usuario": "Error al modificar el usuario",
            "Noticia modificada correctamente": "Noticia modificada correctamente",
            "Error al modificar la noticia": "Error al modificar la noticia",
            "Noticia creada correctamente": "Noticia creada correctamente",
            "Error al crear la noticia": "Error al crear la noticia",
            "Página modificada correctamente": "Página modificada correctamente",
            "Error al modificar la página": "Error al modificar la página",
            "Página creada correctamente": "Página creada correctamente",
            "Error al crear la página": "Error al crear la página",
            "Destino modificado correctamente": "Destino modificado correctamente",
            "Error al modificar el destino": "Error al modificar el destino",
            "Destino creado correctamente": "Destino creado correctamente",
            "Error al crear el destino": "Error al crear el destino",
            "Experiencia modificada correctamente": "Experiencia modificada correctamente",
            "Error al modificar la experiencia": "Error al modificar la experiencia",
            "Experiencia creada correctamente": "Experiencia creada correctamente",
            "Error al crear la experiencia": "Error al crear la experiencia",
            "Día modificado correctamente": "Día modificado correctamente",
            "Error al modificar el día": "Error al modificar el día",
            "Día creado correctamente": "Día creado correctamente",
            "Error al crear el día": "Error al crear el día",
            "Recurso modificado correctamente": "Recurso modificado correctamente",
            "Error al recurso el recurso": "Error al modificar el recurso",
            "Recurso creado correctamente": "Recurso creado correctamente",
            "Error al crear el recurso": "Error al crear el recurso",
            "Artículo modificado correctamente": "Artículo modificado correctamente",
            "Error al modificar el artículo": "Error al modificar el artículo",
            "Artículo creado correctamente": "Artículo creado correctamente",
            "Error al crear el artículo": "Error al crear el artículo",
            "Ha ocurrido un error durante la traducción automática de este texto, disculpa las molestias.": "Ha ocurrido un error durante la traducción automática de este texto, disculpa las molestias.",
            "No hay datos que exportar": "No hay datos que exportar"
        },
        "gui_js_components_uploadstatus": {
            "Subida de imágenes adjuntas": "Subida de imágenes adjuntas"
        }
    },
    "fr": {
        "Si": "Oui",
        "Sí": "Oui",
        "Pulsa para desplegar una lista de filtros según criterios de accesibilidad": "Cliquez sur ce bouton pour afficher une liste de filtres en fonction des critères d'accessibilité.",
        "backlink": {
            "Recursos analizados en colaboración con": "Ressources analysées en collaboration avec",
            "Recurso analizado en colaboración con": "Ressource analysée en collaboration avec",
            "TUR4all Turismo accesible": "TUR4all Tourisme accessible"
        },
        "translatable-textarea": {
            "Mostrando idioma": "Montrer la langue",
            "Traducir": "TraTraduireducir",
            "Idioma sin traducción automática": "Langue sans traduction automatique",
            "desde": "de",
            "Pedir traducción": "Commander la traduction",
            "Cancelar": "Annuler"
        },
        "pagination": {
            "pagination": "pagination",
            "mostrando": "Montrer",
            "desde": "de",
            "a": "a",
            "de": "de",
            "resultados": "résultats",
            "First": "Première",
            "Previous": "Précédent",
            "Next": "Suivant",
            "Last": "Dernière",
        },
        "blog": {
            "admin": {
                "Crear nueva noticia": "Crear nueva noticia"
            },
            "index": {
                "Noticias": "Actualités",
            },
            "edit": {
                "title": "Edición de noticias",
            },
            "create": {
                "title": "Nueva noticia",
            },
            "form": {
                "title": "Título",
                "intro": "Entradilla",
                "content": "Contenido",
                "cover": "Imagen destacada",
                "required": "Este campo es obligatorio"

            }
        },
        "resource": {
            "admin": {
                "Crear nuevo recurso": "Crear nuevo recurso"
            },
            "index": {
                "Recursos accesibles": "Ressources accessibles"
            },
            "edit": {
                "title": "Edición de recurso"
            },
            "create": {
                "title": "Nuevo recurso"
            },
            "form": {
                "name": "Nombre",
                "category": "Categoría",
                "category-help": "La categoría no se puede modificar porque determina las características de accesibilidad del recurso.",
                "subcategory": "Subcategoría",
                "subcategory-help": "La subcategoría no se puede modificar porque determina las características de accesibilidad del recurso.",
                "address": "Nombre de la calle",
                "number": "Número",
                "postal_code": "Código postal",
                "latitude": "Latitud",
                "longitude": "Longitud",
                "latitude-help": "Separar la parte decimal con un punto. No especificar minutos ni segundos.",
                "longitude-help": "Separar la parte decimal con un punto. No especificar W o E, minutos ni segundos.",
                "telephone": "Teléfono",
                "email": "Email",
                "url": "URL",
                "contact_name": "Nombre de la persona de contacto",
                "contact_email": "Email de la persona de contacto",
                "contact_position": "Puesto/cargo de la persona de contacto",
                "contact_phone": "Teléfono de la persona de contacto",
                "description": "Descripción",
                "cover": "Imagen destacada",
                "required": "Este campo es obligatorio",
                "map-help": "Haga doble clic en una ubicación del mapa para fijar automáticamente las coordenadas.",
                "search-address-button": "Click para mover el marcador a la dirección indicada",
                "search-address-button-help": "El nombre de la calle y el número son necesarios para mover el marcador"
            },
            "report": {
                "link": "Aller au rapport détaillé sur l'accessibilité",
            }
        },
        "gui_buttons": {
            "¿Estás seguro?": "¿Estás seguro?",
            "Estas seguro": "¿Estás seguro?",
            "Acciones": "Acciones",
            "Añadir comentario": "Añadir comentario",
            "Añadir recurso": "Añadir recurso",
            "Borrar": "Borrar",
            "Borrar imagen": "Borrar imagen",
            "Bor    rar adjunto": "Borrar adjunto",
            "Buscar": "Buscar",
            "Cambiar contraseña": "Cambiar contraseña",
            "Cancelar": "Cancelar",
            "Cerrar sesión": "Cerrar sesión",
            "Comentar": "Comentar",
            "Comentarios": "Comentarios",
            "Compartir": "Compartir",
            "Conectar con Facebook": "Conectar con Facebook",
            "Conectar con Twitter": "Conectar con Twitter",
            "Crear": "Crear",
            "Crear cuenta": "Crear cuenta",
            "Crear inspección": "Crear inspección",
            "Crear recurso": "Crear recurso",
            "Descargar": "Descargar",
            "Descargar informe": "Descargar informe",
            "Deshacer cambios": "Deshacer cambios",
            "Deshacer reporte": "Deshacer reporte",
            "Editar": "Editar",
            "Editar datos de contacto": "Editar datos de contacto",
            "Editar imágenes": "Editar imágenes",
            "Envía tu comentario": "Envía tu comentario",
            "Enviar": "Enviar",
            "Evaluación técnica": "Evaluación técnica",
            "Evaluación rápida": "Evaluación de usuario",
            "Fecha de creación": "Fecha de creación",
            "Filtrar": "Filtrar",
            "Guardar": "Guardar",
            "Informe de técnico": "Informe de técnico",
            "Informe de usuario": "Informe profesional",
            "Inicia sesión": "Inicia sesión",
            "Inspecciones de usuario": "Inspecciones profesional",
            "Inspecciones de técnico": "Inspecciones de técnico",
            "Inspecciones rápidas": "Inspecciones de usuario",
            "Leer más": "Leer más",
            "Limpiar": "Limpiar",
            "Limpiar búsqueda": "Limpiar búsqueda",
            "Limpiar respuesta": "Limpiar respuesta",
            "Moderar": "Moderar",
            "Modificar recurso": "Modificar recurso",
            "No": "No",
            "Nueva búsqueda": "Nueva búsqueda",
            "Ocultar código": "Ocultar código",
            "Participa": "Participa",
            "Quitar": "Quitar",
            "Restaurar": "Restaurar",
            "Resultados": "Resultados",
            "Saltar a cuestionario de usuario": "Saltar a cuestionario de usuario",
            "Salvar": "Salvar",
            "Seleccionar imagen": "Seleccionar imagen",
            "Sí": "Sí",
            "Suscribete": "Suscribete",
            "Valorar": "Valorar",
            "Ver código": "Ver código",
            "Ver destinos": "Ver destinos",
            "Ver experiencias": "Ver experiencias",
            "Ver fotos": "Ver fotos",
            "Ver imágenes": "Ver imágenes",
            "Ver información": "Ver información",
            "Ver mapa": "Ver mapa",
            "Ver más": "Ver más",
            "Versión para imprimir": "Versión para imprimir",
            "Volver": "Volver",
            "Volver a cuestionario de técnico": "Volver a cuestionario de técnico",
            "Volver al inicio": "Volver al inicio",
            "Volver al listado": "Volver al listado",
            "Exportar": "Exportar",
            "Exportar todas": "Exportar todas",
            "Exportar todos": "Exportar todos",
            "Exportar destino": "Exportar destino",
            "Exportar detalles": "Exportar detalles",
            "Exportar recursos": "Exportar recursos",
            "Exportar recurso": "Exportar recurso"
        },
        "gui_js_components_filepreviews": {
            "Tamaño": "Tamaño"
        },
        "gui_js_components_imagepicker": {
            "El tamaño máximo permitido para las imágenes es de 2MB": "El tamaño máximo permitido para las imágenes es de 2MB"
        },
        "gui_js_components_filepicker": {
            "Se adjuntará al pulsar Enviar": "Se adjuntará al pulsar \"Enviar\"",
            "Seleccionar archivos": "Seleccionar archivos",
            "Limpiar": "Limpiar"
        },
        "gui_js_components_switchaction": {
            "Sí": "Sí",
            "No": "No"
        },
        "gui_js_components_questionnaire_user_cupreguntamulti": {
            "Responder esta pregunta muestra un cuestionario adicional": "Responder esta pregunta muestra un cuestionario adicional"
        },
        "gui_vue": {
            "Subida de imágenes adjuntas": "Subida de imágenes adjuntas",
            "Índice de cuestionarios raíz": "Índice de cuestionarios raíz",
            "Inicio del cuestionario": "Inicio del cuestionario",
            "Final del cuestionario": "Final del cuestionario",
            "Inicio del subcuestionario": "Inicio del subcuestionario",
            "Final del subcuestionario": "Final del subcuestionario",
            "Índice de este contenedor": "Índice de este contenedor",
            "Añadir subcuestionario": "Añadir subcuestionario",
            "Imágenes más recientes para este contexto": "Imágenes más recientes para este contexto",
            "Responder esta pregunta es clave": "Responder esta pregunta es clave"
        },
        "gui_js_components_questionnaire_user_cupreguntaradio": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional",
            "Limpiar respuesta": "Limpiar respuesta"
        },
        "gui_js_components_imageinput": {
            "Descripción de la imagen": "Descripción de la imagen",
            "Quitar": "Quitar",
            "El tamaño máximo permitido para las imágenes es de 2MB": "El tamaño máximo permitido para las imágenes es de 2MB",
            "Subidas pendientes": "Subidas pendientes",
            "Máximo": "Máximo",
            "Seleccionar imagen": "Seleccionar imagen",
            "Deshacer cambios": "Deshacer cambios"
        },
        "gui_js_components_element_resourcedescription": {
            "Descripción": "Descripción"
        },
        "gui_js_components_htmleditor": {
            "Ver código": "Ver código",
            "Ocultar código": "Ocultar código"
        },
        "gui_js_components_resourcefilter": {
            "Nombre": "Nom",
            "Categoría": "Catégorie",
            "Subcategoría": "Sous-catégorie",
            "Solamente recursos analizados por expertos": "Solamente recursos analizados por expertos",
            "País": "País",
            "Comunidad": "Comunidad",
            "Provincia": "Provincia",
            "Localidad": "Localidad",
            "Criterios de accesibilidad": "Critères d'accessibilité",
            "Buscar": "Recherche",
            "Crear recurso": "Crear recurso",
            "Nueva búsqueda": "Nueva búsqueda",
            "Exportar": "Exportar"
        },
        "api_dbfields": {
            "user_model": "usuario",
            "user_model_plural": "usuarios",
            "user_name": "Nombre",
            "user_email": "Correo electrónico",
            "user_password": "Contraseña",
            "user_surnames": "Apellidos",
            "user_alias": "Alias",
            "user_cover": "Imagen del perfil de usuario",
            "resource_model": "recurso",
            "resource_model_plural": "recursos",
            "resource_name": "Nombre",
            "resource_address": "Calle",
            "resource_number": "Número",
            "resource_postal_code": "Código Postal",
            "resource_telephone": "Teléfono",
            "resource_telephone_abbreviation": "Tlfno",
            "resource_email": "Correo electrónico",
            "resource_url": "Página web",
            "resource_description": "Descripción",
            "resource_latitude": "Latitud",
            "resource_longitude": "Longitud",
            "resource_contact_name": "Persona de contacto",
            "resource_contact_email": "Email de la persona de contacto",
            "resource_contact_position": "Cargo de la Persona de contacto",
            "resource_contact_phone": "Teléfono de la persona de contacto",
            "resource_cover": "Imagen de portada del recurso",
            "resource_title": "Recurso turístico",
            "resource_location": "Ubicación",
            "resource_coordinates": "Coordenadas",
            "resource_coordinates_help": "Separar la parte decimal con un punto \".\" Ejemplo: -45.342456",
            "resource_information": "Información del recurso",
            "resource_contact": "Información de contacto",
            "Tur4all\\Models\\Resource\\Resource": "Recurso",
            "country_model": "país",
            "country_model_plural": "paises",
            "country_name": "País",
            "Tur4all\\Models\\Location\\Country": "País",
            "region_model": "región",
            "region_model_plural": "regiones",
            "region_first_level_name": "Comunidad",
            "region_second_level_name": "Provincia",
            "Tur4all\\Models\\Location\\Region": "Comunidad \/ Provincia",
            "town_model": "localidad",
            "town_model_plural": "localidades",
            "town_name": "Localidad",
            "Tur4all\\Models\\Location\\Town": "Localidad",
            "category_model": "categoría",
            "category_model_plural": "categorías",
            "category_name": "Categoría",
            "Tur4all\\Models\\Resource\\Category": "Categoría",
            "subcategory_model": "subcategoría",
            "subcategory_model_plural": "subcategorías",
            "subcategory_name": "Subcategoría",
            "Tur4all\\Models\\Resource\\Subcategory": "Subcategoría",
            "destination_model": "destino",
            "destination_model_plural": "destinos",
            "destination_name": "Nombre",
            "destination_weight": "Peso",
            "Tur4all\\Models\\Destination\\Destination": "Destino",
            "Tur4all\\Models\\Destination\\DestinationDetail": "Detalle Destino",
            "experience_model": "experiencia",
            "experience_model_plural": "experiencias",
            "experience_name": "Nombre",
            "experience_weight": "Peso",
            "experience_cover": "Imagen de portada de la experiencia",
            "experience_information": "Información de interés",
            "Tur4all\\Models\\Experience\\Experience": "Experiencia",
            "Tur4all\\Models\\Experience\\ExperienceDay": "Experiencia, Día",
            "blogarticle_model": "artículo",
            "blogarticle_model_plural": "artículos",
            "blogarticle_title": "Título",
            "blogarticle_created_at": "Fecha de creación",
            "blogarticle_cover": "Imagen de cabecera del artículo",
            "eventpost_model": "evento",
            "eventpost_model_plural": "eventos",
            "eventpost_title": "Título",
            "eventpost_where": "Lugar",
            "eventpost_who": "Organiza",
            "eventpost_when": "Fecha",
            "eventpost_created_at": "Fecha de creación",
            "eventpost_cover": "Imagen de portada del evento",
            "newspost_model": "noticia",
            "newspost_model_plural": "noticias",
            "newspost_title": "Título",
            "newspost_created_at": "Fecha de creación",
            "newspost_cover": "Imagen de la noticia"
        },
        "gui_js_components_userpicker": {
            "Resultados": "Resultados",
            "Seleccionados": "Seleccionados"
        },
        "gui_js_components_questionnaire_user_cupreguntasingle": {
            "Responder esta pregunta muestra un cuestionario adicional": " gui_js_components_questionnaire_user_cupreguntamulti.Responder esta pregunta muestra un cuestionario adicional"
        },
        "gui_js_components_imagelocales": {
            "Guardar": "Guardar",
            "Textos alternativos guardados correctamente": "Textos alternativos guardados correctamente",
            "Ocurrió un error al guardar los textos alternativos": "Ocurrió un error al guardar los textos alternativos",
            "¿Estas seguro?": "¿Estás seguro?"
        },
        "gui_js_components_imagepreview": {
            "Moderar": "Moderar"
        },
        "gui_js_components_questionnaire_user_cupreguntaimagen": {
            "Imágenes más recientes para este contexto": "Imágenes más recientes para este contexto"
        },
        "gui_js_components_resourcepicker": {
            "Creado despues de": "Creado despues de",
            "Creado antes de": "Creado antes de",
            "Modificado despues de": "Modificado despues de",
            "Modificado antes de": "Modificado antes de",
            "Nombre": "Nombre",
            "País": "País",
            "Comunidad": "Comunidad",
            "Provincia": "Provincia",
            "Localidad": "Localidad",
            "Categoría": "Categoría",
            "Subcategoría": "Subcategoría",
            "Buscar": "Buscar",
            "Resultados": "Resultados",
            "Seleccionados": "Seleccionados"
        },
        "api_errors": {
            "Errores": "Errores",
            "unique": "El campo '{field}' ya está registrado en la base de datos y no puede repetirse.",
            "required": "El campo '{field}' es obligatorio.",
            "min": "El campo '{field}' debe tener un mínimo de '{quantity}' dígitos.",
            "max": "Se ha sobrepasado el tamaño del campo '{field}'",
            "email": "El campo email no tiene el formato adecuado.",
            "confirmed": "Las contraseñas no coinciden.",
            "accepted": "Es necesario aceptar las condiciones generales para registrarse.",
            "latlng": "El campo '{field}' no tiene el formato correcto.",
            "Ha ocurrido un error": "Ha ocurrido un error",
            "El sitio web no está temporalmente disponible": "El sitio web no está temporalmente disponible",
            "La página que buscas no ha sido encontrada": "La página que buscas no ha sido encontrada",
            "Puede que esa página ya no exista o que hayas escrito mal la dirección de la dirección URL": "Puede que esa página ya no exista o que hayas escrito mal la dirección de la dirección URL",
            "Prohibido. Permisos insuficientes.": "Prohibido. Permisos insuficientes.",
            "Solicitud incorrecta": "Solicitud incorrecta",
            "Inspección creada correctamente": "Inspección creada correctamente. Por favor espera mientras se adjuntan las imágenes, puede tardar un rato",
            "Ocurrió un error al crear la inspección": "Ocurrió un error al crear la inspección",
            "El recurso ha sido cerrado por un técnico y no puede ser modificado por usuarios": "El recurso ha sido cerrado por un técnico y no puede ser modificado por usuarios",
            "Permisos insuficientes": "Permisos insuficientes",
            "Operación realizada": "Operación realizada",
            "registercontent_ok": "'{model}' creado correctamente",
            "registercontent_fail": "Error al crear '{model}'",
            "modificatecontent_ok": "'{model}' modificado correctamente",
            "modificatecontent_fail": "Error al modificar '{model}'",
            "Evento modificado correctamente": "Evento modificado correctamente",
            "Error al modificar el evento": "Error al modificar el evento",
            "Evento creado correctamente": "Evento creado correctamente",
            "Error al crear el evento": "Error al crear el evento",
            "Usuario modificado correctamente": "Usuario modificado correctamente",
            "Error al modificar el usuario": "Error al modificar el usuario",
            "Noticia modificada correctamente": "Noticia modificada correctamente",
            "Error al modificar la noticia": "Error al modificar la noticia",
            "Noticia creada correctamente": "Noticia creada correctamente",
            "Error al crear la noticia": "Error al crear la noticia",
            "Página modificada correctamente": "Página modificada correctamente",
            "Error al modificar la página": "Error al modificar la página",
            "Página creada correctamente": "Página creada correctamente",
            "Error al crear la página": "Error al crear la página",
            "Destino modificado correctamente": "Destino modificado correctamente",
            "Error al modificar el destino": "Error al modificar el destino",
            "Destino creado correctamente": "Destino creado correctamente",
            "Error al crear el destino": "Error al crear el destino",
            "Experiencia modificada correctamente": "Experiencia modificada correctamente",
            "Error al modificar la experiencia": "Error al modificar la experiencia",
            "Experiencia creada correctamente": "Experiencia creada correctamente",
            "Error al crear la experiencia": "Error al crear la experiencia",
            "Día modificado correctamente": "Día modificado correctamente",
            "Error al modificar el día": "Error al modificar el día",
            "Día creado correctamente": "Día creado correctamente",
            "Error al crear el día": "Error al crear el día",
            "Recurso modificado correctamente": "Recurso modificado correctamente",
            "Error al recurso el recurso": "Error al modificar el recurso",
            "Recurso creado correctamente": "Recurso creado correctamente",
            "Error al crear el recurso": "Error al crear el recurso",
            "Artículo modificado correctamente": "Artículo modificado correctamente",
            "Error al modificar el artículo": "Error al modificar el artículo",
            "Artículo creado correctamente": "Artículo creado correctamente",
            "Error al crear el artículo": "Error al crear el artículo",
            "Ha ocurrido un error durante la traducción automática de este texto, disculpa las molestias.": "Ha ocurrido un error durante la traducción automática de este texto, disculpa las molestias.",
            "No hay datos que exportar": "No hay datos que exportar"
        },
        "gui_js_components_uploadstatus": {
            "Subida de imágenes adjuntas": "Subida de imágenes adjuntas"
        }
    },
}
