<template id="cu-pregunta-single">
    <div class="question question-single pregunta pregunta-single">
        <strong v-if="ro">{{ question.text_question }}: </strong><span v-if="ro">{{ $t('Si') }}</span>

        <div class="checkbox form-check" v-show="!ro">

            <label :for="question.form_id">
                <input
                        type="checkbox"
                        :name="question.form_name"
                        :id="question.form_id"
                        class="form-check-input"
                        value="1"
                        @change="checkCriterios"
                        v-bind:true-value="1"
                        v-model="question.answer" number
                        v-show="!ro"
                >
                <!-- <span class="bi bi-check" :class="{report: ro}" v-show="ro"></span> -->

                <strong>{{ question.text_question }}</strong>
                <span v-if="hasSubsets()" class="has-subsets bi bi-clipboard-plus desplegable"
                      :title="titleString" v-show="!ro"></span>
                <span class="form-text" v-show="!ro">{{ question.text_help }}</span>
            </label>
        </div>
        <cu-subset v-for="subset in question.subsets" :subset="subset" :key="subset.key"></cu-subset>
    </div>
</template>
<style>
span.report {
    margin-left: -1.5em;
}
</style>
<script>
  export default {
    props: ['question'],
    inject: ['ro'],

      computed: {
          titleString: function (){
              return this.$t("gui_js_components_questionnaire_user_cupreguntasingle['Responder esta pregunta muestra un cuestionario adicional']");
          }
      },
    mounted: function() {
      // this.checkCriterioForElement($('[name='+this.question.form_name+']').get()[0]);
    //   if (!this.ro) {
        this.checkCriterioForElement(this.$el.querySelector('[name='+this.question.form_name+']'));
    //   } else {
        // this.subset?.enable();
    //   }

    },
    methods: {
      hasSubsets: function() {
        return this.question.subsets.length > 0;
      },
      clearAnswer: function(e) {
        var emptyValue = null;
        this.question.answer = emptyValue;
        var fakeEvent = {
          target: {
            name: this.question.form_name,
            value: 1,
            checked: false
          }
        };
        this.checkCriterios(fakeEvent);
      },
      checkCriterios: function(e) {
        this.checkCriterioForElement(e.target);
      },
      checkCriterioForElement: function(element) {
        var checkboxvalue = element.value;
        var checkboxischecked = element.checked;
        var criterios = this.getCriterios(checkboxvalue);
        criterios.forEach(function(criterio) {
          var subset = this.getSubset(criterio.child_set_key);
          if (subset) {
            if (checkboxischecked) {
              subset.enable();
            } else {
              subset.disable();
            }
          }

        }.bind(this));
      },
      getSubset: function(clave) {
        var subsets = this.$children;

        for (var i = 0; i < subsets.length; i++) {
          if (subsets[i].subset.key == clave) {
            return subsets[i];
          }
        }
        return undefined;
      },

      getCriterios: function(value) {
        return this.question.criteria.filter(function(criterio){
          return criterio.condition_value == value;
        });
      }
    }
  };
</script>
