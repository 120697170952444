<template id="template-actionimagecontainer">
    <div class="thumbnails-container d-flex flex-row flex-wrap">
        <imagepreview v-for="(preview, index) in editablePreviews"
            :image="preview" :key="index" :showactions="showactions"
            @image-deleted="onImageDeleted"
        />
    </div>
</template>
<script>
    export default {
        inject: ['ro'],
        props: {
            previews: {
                default: function () {
                    return [];
                }
            },
            howmany: {
                default: function () {
                    return 1;
                }
            },
            showactions: {
                default: false
            }
        },
        data: function () {
            return {
                editablePreviews: []
            }
        },
        methods: {
            onQueuedUploads: function (howmanyuploads) {
                var howmanytocut = (this.previews.length + howmanyuploads) - this.howmany;
                if (howmanytocut <= 0) {
                    this.editablePreviews = this.previews;
                } else {
                    this.editablePreviews = this.previews.slice(howmanytocut);
                }
            },
            onImageDeleted(image) {
                this.editablePreviews = this.editablePreviews.filter(function(item) {
                    return item.id != image.id;
                });
            },
            previewsHandler: function () {
                let previewsCopy = JSON.parse(JSON.stringify(this.previews));
                this.editablePreviews = previewsCopy.reverse();
            },
        },
        mounted: function () {
            // console.debug('imagecontainer mounted');
            this.previewsHandler();
        },
        watch: {
            previews: {
                handler: function () {
                    // console.debug('imagecontainer watch previews');
                    this.previewsHandler();
                }
            }
        }

    }
</script>
