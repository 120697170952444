<template id="blog-create">
<div id="blog-create-container" class="container pt-3">
    <div class="row">
        <div class="col-12 mb-3">
            <blog-form ref="blogform" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    provide: {
        ro: false
    }
}
</script>
