<template id="resource-index">
<div id="resource-index-container"  class="container py-5">
    <div class="row">
        <div class="col-12">
            <h3>{{ $t('resource.index.Recursos accesibles') }}</h3>
        </div>
    </div>
    <div class="row border border-primary rounded mb-3 p-3">
        <resource-filter :filter-setup="filterSetup" />
        <!-- <pre>{{ apiParams }}</pre> -->
    </div>
    <div class="row">
        <div class="col-12 col-md-3 my-3" v-for="resource in response.data">
            <resource-item :resource="resource" />
        </div>
    </div>
    <pagination :response="response" :params="params" v-if="response" />
</div>
</template>

<script>

export default {
    inject: [
        'accessibilitycriteria', 'categories', 'subcategories'
    ],
    props: [
        'filter-setup'
    ],
    mixins: [authMixin, formMixin],
    data: function () {
        let urlparams = new URLSearchParams(window.location.search);
        let getparams = {};
        for (const [key, value] of urlparams.entries()) {
            getparams[key] = value;
        }
        return {
            params: getparams
        }
    },
    created: function () {

        this.filterSetup.selected = this.params;
        this.getResources();
    },
    methods: {
        // clickedSearch: function (params) {
            // params.page = null;
            // this.getResources(params);
        // },
        getResources: function () {
            // this.api = params;
            let url = 'resources/search';

            let baseParams = {
                fid_town: 474, // Ávila (localidad)
            }

            var search_params = { ...baseParams, ...this.apiParams };

            t4a.get(url, {params: search_params})
            .then(
                function(response) {
                    console.debug(response)
                    this.response = response.data;
                    // this.resources = response.data.results;
                    // this.totalresources = response.data.totalResultResources
                }.bind(this)
            ).catch(t4a_error_handler.bind(this));
        },
    },
    computed: {
        apiParams: function () {
            let searchparams = {}
            for (const [key, value] of Object.entries(this.params)) {
                switch (key) {
                    case 'category':
                        var cat = this.categories.find(function(item) {
                            return item.slug == value;
                        });
                        if (cat) {
                            searchparams['fid_category'] = cat.id;
                        }
                        break;
                    case 'subcategory':
                        var subcat = this.subcategories.find(function(item) {
                            return item.slug == value;
                        });
                        if (subcat) {
                            searchparams['fid_subcategory'] = subcat.id;
                        }
                        break;
                    case 'name': case 'page':
                        if (value) {
                            searchparams[key] = value;
                        }
                        break;
                    default:
                        var criterion = this.accessibilitycriteria.find(function(item) {
                            return item.slug == key;
                        });
                        if (criterion) {
                            searchparams[criterion.key] = value;
                        }
                        break;
                }
            }
            return searchparams;
        },
    }
}
</script>
