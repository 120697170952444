<template id="cu-subset-template">
    <div class="subset" :id="subset.name" v-show="active" v-if="subset.questions.length">
        <h6 class="color-grey" v-show="showSetName()">{{ subset.name }}</h6>
        <div class="subset-images" v-if="subset.images">
            <imagecontainer
            :previews="subset.images"
            :howmany="6"
            ref="imagepreviews"
            v-if="ro" />
        </div>
        <div v-for="question in subset.questions" :key="question.id" class="form-group panel-subset">
            <div v-if="question.type == 'image'">
                <strong>{{ question.text_question }}</strong><br/>
                <cu-pregunta-imagen :previews="subset.images" :imagekey="subset.key" :imagename="subset.name"></cu-pregunta-imagen>
            </div>
            <div v-if="question.type == 'text'">
                <cu-pregunta-texto :question="question"></cu-pregunta-texto>
            </div>
            <div v-if="(question.type == 'checkbox' && question.options.length > 0)">
                <cu-pregunta-multi :question="question"></cu-pregunta-multi>
            </div>
            <div v-if="(question.type == 'checkbox' && question.options.length == 0)">
                <cu-pregunta-single :question="question"></cu-pregunta-single>
            </div>
            <div v-if="question.type == 'radio'">
                <cu-pregunta-radio :question="question"></cu-pregunta-radio>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['subset'],
        inject: ['ro'],
        data: function () {
            return {
                active: 0
            };
        },
        methods: {
            showSetName: function() {
                return (this.subset.key.split('_').length < 4) && this.ro
                    && !(['Características','Characteristics','General information','Information','Datos generales'].includes(this.subset.name));
            },
            getClave: function () {
                return this.subset.key;
            },
            disable: function () {
                this.$children.forEach(function (question) {
                    question.clearAnswer();
                });
                this.active = 0;
            },
            enable: function () {
                this.active = 1;
            }
        }
    };
</script>
