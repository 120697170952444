<template id="template-actionbutton">
    <div :class="this.rootClass">
        <button :class="this.buttonClass" role="button" @click="confirmSubmit">
            <span :class="this.buttonGlyph"></span>&nbsp;&nbsp;{{ this.buttonText }}
        </button>
    </div>
</template>
<script>
    export default {
        props: {
            rootclass: {
                default: '',
            },
            buttonclass: {
                default: '',
            },
            actiontype: {
                required: true,
            },
            status: {
                required: true,
            },
        },
        data: function() {
            return {
                confirmationText: window.i18nSetup.t("gui_buttons['¿Estás seguro?']"),
            }
        },
        computed: {
            rootClass() {
                var baseClass = 'root-actionbutton ';
                if (this.$parent.statustype === 'right') {
                    baseClass += 'pull-left margin-separation-right ';
                }
                return baseClass + this.rootclass;
            },
            buttonClass() {
                var baseClass;
                switch (this.buttonType) {
                    case 'red': baseClass = 'btn style-button-red btn-block '; break;
                    case 'green': baseClass = 'btn style-button-green btn-block '; break;
                    case 'red-white': baseClass = 'btn style-button-red-white btn-block '; break;
                    case 'green-white': baseClass = 'btn style-button-green-white btn-block '; break;
                }
                return baseClass + this.buttonclass;
            },
            buttonGlyph() {
                var baseClass;
                switch (this.actiontype) {
                    case 'image-report': return '';
                    case 'image-restore': return '';
                    case 'image-add-slider': return 'glyphicon glyphicon glyphicon-star';
                    case 'image-remove-slider': return 'glyphicon glyphicon glyphicon-star-empty';
                    case 'image-save': return 'glyphicon glyphicon-ok';
                    case 'image-delete-user-review': return 'glyphicon glyphicon-exclamation-sign';
                    case 'image-delete-technical-inspection': return 'glyphicon glyphicon-exclamation-sign';
                    case 'image-delete-admin': return 'glyphicon glyphicon-exclamation-sign';
                    case 'attachment-delete-admin': return 'glyphicon glyphicon-exclamation-sign';
                    case 'blog-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'news-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'event-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'destination-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'destinationdetail-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'experience-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'experienceday-register': return 'glyphicon glyphicon-ok';
                    case 'experienceday-modify': return'glyphicon glyphicon-ok';
                    case 'experienceday-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'destinationdetail-register': return 'glyphicon glyphicon-ok';
                    case 'destinationdetail-modify': return'glyphicon glyphicon-ok';
                    case 'destinationdetail-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'user-change-password': return 'glyphicon glyphicon-exclamation-sign';
                    case 'user-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'user-save': return 'glyphicon glyphicon-ok';
                    case 'other-user-save': return 'glyphicon glyphicon-ok';
                    case 'resource-delete': return 'glyphicon glyphicon-exclamation-sign';
                    case 'entity-delete': return 'glyphicon glyphicon-exclamation-sign';
                }
                return baseClass + this.buttonclass;
            },
            buttonType() {
                switch (this.actiontype) {
                    case 'image-report': return 'green-white';
                    case 'image-restore': return 'green';
                    case 'image-add-slider': return 'green';
                    case 'image-remove-slider': return 'green-white';
                    case 'image-save': return 'green';
                    case 'image-delete-user-review': return 'red';
                    case 'image-delete-technical-inspection': return 'red';
                    case 'image-delete-admin': return 'red';
                    case 'attachment-delete-admin': return 'red';
                    case 'blog-delete': return 'red';
                    case 'news-delete': return 'red';
                    case 'event-delete': return 'red';
                    case 'destination-delete': return 'red';
                    case 'destinationdetail-delete': return 'red';
                    case 'experience-delete': return 'red';
                    case 'experienceday-register': return 'green';
                    case 'experienceday-modify': return 'green';
                    case 'experienceday-delete': return 'red';
                    case 'destinationdetail-register': return 'green';
                    case 'destinationdetail-modify': return 'green';
                    case 'destinationdetail-delete': return 'red';
                    case 'user-change-password': return 'green';
                    case 'user-delete': return 'red';
                    case 'user-save': return 'red';
                    case 'other-user-save': return 'red';
                    case 'resource-delete': return 'red';
                    case 'entity-delete': return 'red';
                }
            },
            buttonText() {
                switch (this.actiontype) {
                    case 'image-report': return window.i18nSetup.t("gui_buttons['Moderar']");
                    case 'image-restore': return window.i18nSetup.t("gui_buttons['Deshacer reporte']");
                    case 'image-add-slider': return 'Slider +';
                    case 'image-remove-slider': return 'Slider -';
                    case 'image-save': return window.i18nSetup.t("gui_buttons['Guardar']");
                    case 'image-delete-user-review': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'image-delete-technical-inspection': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'image-delete-admin': return window.i18nSetup.t("gui_buttons['Borrar imagen']");
                    case 'attachment-delete-admin': return window.i18nSetup.t("gui_buttons['Borrar adjunto']");
                    case 'blog-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'news-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'event-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'destination-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'destinationdetail-delete': return window.i18nSetup.t("gui_buttons['Borrar']");

                    case 'experience-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'experienceday-register': return window.i18nSetup.t("gui_buttons['Enviar']");
                    case 'experienceday-modify': return window.i18nSetup.t("gui_buttons['Enviar']");
                    case 'experienceday-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'destinationdetail-register': return window.i18nSetup.t("gui_buttons['Enviar']");
                    case 'destinationdetail-modify': return window.i18nSetup.t("gui_buttons['Enviar']");
                    case 'destinationdetail-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'user-change-password': return window.i18nSetup.t("gui_buttons['Cambiar contraseña']");
                    case 'user-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'user-save': return window.i18nSetup.t("gui_buttons['Guardar']");
                    case 'other-user-save': return window.i18nSetup.t("gui_buttons['Guardar']");
                    case 'resource-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    case 'entity-delete': return window.i18nSetup.t("gui_buttons['Borrar']");
                    default: return 'TEST';
                }
            },
        },
        methods: {
            confirmSubmit: function () {
                event.preventDefault();
                if (confirm(this.confirmationText)) {
                    this.$parent.actionStatus = 'SUBMITTING';
                }
            },
        }
    };
</script>
