<template id="blog-item">
    <div id="blog-item-container" class="container g-0" v-if="blog">
        <a :href="'/blogs/'+blog.slug" class="blog-item-link">
            <div class="blog-item py-3">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="blog-item-image-container">
                            <img :src="coverUrl" class="blog-item-image" />
                        </div>
                    </div>
                    <div class="col-12 col-md-9 d-flex align-items-center">
                        <div class="blog-item-text-container">
                            <h3>{{ blog.title }}</h3>
                            <p class="lead">{{ blog.intro }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>
<script>
export default {
    props: [
        'blog',
        'index'
    ],
    computed: {
        coverUrl: function (){
            return this.blog.cover.baseurl + this.blog.cover.src
        },
        isEven: function () {
            return this.index%2 == 0;
        }
    }
    
}
</script>
