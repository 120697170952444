<template id="cu-seccion-template">
    <div class="panel panel-default seccion">
        <div class="panel-heading">
            <h4 class="panel-title">{{ section.name }}</h4>
        </div>
        <div :id="'section_'+section.key" class="panel-body">
            <cu-set v-for="set in section.sets" :set="set" :key="set.key"></cu-set>
        </div>
    </div>
</template>
<script>
export default {
    props: ['section'],
    inject: ['ro'],
};
</script>
