<template id="template-imageinput">
  <div class="image-input">
    <div class="thumbnails-container row">
      <div class="thumbnail thumbnail-input-preview col-6" v-for="(imgsrc, index) in sortedimgsrcs">
          <img class="imagen-input-thumbnail" :src="imgsrc" />
          <div class="form-group imagen-alt-input-group" v-if="capturealt">
              <label :for="altid(index)">{{ $t("gui_js_components_imageinput['Descripción de la imagen']") }}</label>
              <input type="text" class="form-control" :name="altid(index)" :id="altid(index)" v-model="queuedalts[index]"/>
              <a class="btn btn-outline-danger" role="button" @click.prevent="removePendingUpload(index)">
                  <span class="glyphicon glyphicon-remove text-danger"></span>&nbsp;&nbsp;{{ $t("gui_js_components_imageinput['Quitar']") }}&nbsp;&nbsp;
              </a>
          </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <p class="help-block">
      <span class="glyphicon glyphicon-info-sign"></span>
      {{ $t("gui_js_components_imageinput['El tamaño máximo permitido para las imágenes es de 2MB']") }}
      <span>{{ $t("gui_js_components_imageinput['Subidas pendientes']") }}: {{ this.queueduploads.length }}</span>
      <span>({{ $t("gui_js_components_imageinput['Máximo']") }}: {{ this.howmany }})</span>
    </p>
    <label :for="imagekey" class="btn btn-outline-primary" v-show="this.queueduploads.length < this.howmany">
      {{ $t("gui_js_components_imageinput['Seleccionar imagen']") }}
      <input
          type="file"
          :name="imagekey"
          :id="imagekey"
          :multiple="multiple"
          :imagename="imagename"
          class="form-control"
          style="display: none"
          accept=".jpg,.png,.jpeg"
          @change="enqueueNewUploads"
      />
    </label>
    <button class="btn btn-outline-danger" @click.prevent="clearFiles">{{ $t("gui_js_components_imageinput['Deshacer cambios']") }}</button>
  </div>
</template>
<style>
.imagen-input-thumbnail {
  max-width: 150px;
}
</style>
<script>
  export default {
    data: function() {
      return {
        imgsrcs: [],
        queueduploads: [],
        queuedalts: []
      }
    },
    props: {
      capturealt: {
        default: function () {
            return false;
        }
      },
      imagekey: {
        default: function() {
          return '';
        },
        required: true,
      },
      imagename: {
        default: function() {
          return '';
        },
        required: true,
      },
      howmany: {
        default: function() {
          return 1;
        }
      },
      multiple: {
        default: function() {
          return false;
        }
      }
    },
    methods: {
      enqueueNewUploads: function(event) {
        var input = event.target;
        if (input.files && input.files[0]) {
          var fileLimit = this.howmany;
          if (input.files.length < fileLimit) {
            fileLimit = input.files.length
          }
          for (var i = 0; i < fileLimit; i++) {
            var reader = this.getFileReaderInstance(i);
            // OJO! mantener dentro del bucle: necesitamos un reader nuevo para
            // cada imagen por algo de los Web Workers xD
            if (this.queueduploads.length < this.howmany) {
              reader.readAsDataURL(input.files[i]);
              var queuedUpload = this.buildQueuedUpload(this.imagename, input.name, input.files[i]);
              this.queueduploads.push(queuedUpload)
            }
          }
          input.value = '';
          this.$emit('queued-uploads',this.queueduploads.length);
          // this.$parent.onQueuedUploads(this.queueduploads.length);
        }
      },
      getFileReaderInstance: function(frIndex) {
        var reader = new FileReader();
        reader.onload = function(e) {
          var indexedThumb = {
            index: frIndex,
            data: e.target.result
          };
          this.imgsrcs.push(indexedThumb);
        }.bind(this);
        return reader;
      },
      clearFiles: function() {
        this.imgsrcs = [];
        this.queueduploads = [];
        this.queuedalts = [];
        // this.$parent.onQueuedUploads(0);
        this.$emit('queued-uploads', 0);

      },
      removePendingUpload: function(i) {
        this.imgsrcs.splice(i,1);
        this.queueduploads.splice(i,1);
        this.queuedalts.splice(i,1);
        // this.$parent.onQueuedUploads(this.queueduploads.length);
        this.$emit('queued-uploads',this.queueduploads.length);

      },
      startUploads: function(targetUrl) {
        // console.debug('ImageInput startUploads', targetUrl);

        this.targetUrl = targetUrl;
        var pendingRequests = this.sendRequests(this.queueduploads, this.queuedalts);
        return pendingRequests;
      },
      buildQueuedUpload: function (context, name, file) {
        return {
          context: context,
          name: name,
          file: file,
          filename: file.name
        }
      },
      buildFormData: function(queuedUpload, queuedalt) {
        var formdata = new FormData();
        formdata.append(queuedUpload.name, queuedUpload.file);
        if (typeof queuedalt !== 'undefined') {
            formdata.append('alt', queuedalt);
        }
        // console.debug('buildFormData: ', formdata, name, file)
        return formdata;
      },
      sendRequests: function(queueduploads, queuedalts) {
        // console.debug('ImageInput sendRequests')
        var deferredRequests = [];
        for (var k = 0; k < queueduploads.length; k++) {
          deferredRequests.push(this.sendRequest(queueduploads[k], queuedalts[k]));
        }
        return deferredRequests;
      },
      sendRequest: function(queuedupload, queuedalt) {
        var formdata = this.buildFormData(queuedupload, queuedalt);
        var deferredRequest = t4a.post(this.targetUrl,
          formdata,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlenconded'
            }
          }).then(function(response) {
            if (response.status == 200) {
              this.pushFilestatus(queuedupload, 'OK');
            }
          }.bind(this))
          .catch(function(error){
            let errors = [];
            for (var errorKey in error.response.data.errors) {
              errors.push(error.response.data.errors[errorKey]);
            }
            this.pushFilestatus(queuedupload, errors.join(', '));
            t4a_error_handler(error).bind(this);
            // console.error(error);
          }.bind(this));
        return deferredRequest;
        // var deferredRequest = $.ajax({
        //   url: this.targetUrl,
        //   type: "POST",
        //   data: formdata,
        //   cache: false,
        //   contentType: false,       // The content type used when sending data to the server.
        //   processData:false,        // To send DOMDocument or non processed data file it is set to false
        //   success: function(response, status, request) {
        //     this.pushFilestatus(queuedupload, "OK");
        //   }.bind(this),
        //   error: function(request, status, error) {
        //     window.errorResponse = request.responseJSON;
        //     var errors = [];
        //     for (var errorKey in request.responseJSON.errors) {
        //       errors.push(request.responseJSON.errors[errorKey]);
        //     }
        //     this.pushFilestatus(queuedupload, errors.join(', '));
        //   }.bind(this)
        // });
      },
      pushFilestatus: function(queuedupload, status) {
        var filestatus = {
          context: queuedupload.context,
          filename: queuedupload.filename,
          status: status
        }
        // if (Array.isArray(this.$root.filestatus)) {
        //   this.$root.filestatus.push(filestatus);
        // }
        // console.debug('ImageInput.vue pushFilestatus', filestatus);
        bus.$emit('push-filestatus', filestatus);

      },
        altid: function(idx) {
            return 'altfor'+idx;
        }
    },
    computed: {
        sortedimgsrcs: function () {
            if (this.imgsrcs.length == 0) {
                return [];
            }
            return this.imgsrcs.sort(function(a,b) {
                return a.index - b.index;
            }).map(function(item) {
                return item.data;
            })
        }
    }
  };
</script>
