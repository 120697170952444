<template id="resource-category-index">
<div id="resource-category-index-component">
    <div class="resource-category-index-container container py-5">
        <div class="row">
            <div class="col-12">
                <h3>{{ categoryname }}</h3>
            </div>
            <div class="col-12">
                <p class="category-index-intro"><slot></slot></p>
            </div>
        </div>
    </div>
    <div class="resource-category-index-container container-fluid pt-3 red-band">
        <div class="row" v-if="showMap">
            <div class="col-12 gx-5 gy-2">
                <div class="resource-category-map-container "  v-if="response" >
                <l-map :zoom="mapdata.mapZoom" :center="mapdata.mapCenter" :options="mapdata.mapOptions" :bounds="mapdata.bounds">
                    <l-tile-layer :url="mapdata.mapTileLayerUrl" :attribution="mapdata.mapTileLayerAttribution"
                        :options="mapdata.mapTileLayerOptions" />
                    <l-marker :lat-lng="latLng(resource.latitude, resource.longitude)"v-for="resource in response.data" :key="resource.id">
                        <l-popup :content="mapPopup(resource)">
                            <strong>{{ resource.name }}</strong><br />
                            {{ resource.address }}, {{  resource.number }}
                        </l-popup>
                    </l-marker>
                </l-map>
            </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="row">
            <resource-item :resource="resource" v-for="resource in response.data" :key="resource.slug" 
                class="red-band col-12 col-md-4 col-lg-3" />
        </div>
    </div>
</div>
</template>
    
<script>

export default {
    inject: [
        'accessibilitycriteria', 'categories', 'subcategories'
    ],
    props: {
        slug: {
            required: true
        },
    },
    mixins: [authMixin, formMixin],
    data: function () {
        return {
            params: {
                category: this.slug
            }
        }
    },
    created: function () {
        this.getResources();
    },
    methods: {
        latLng: function (lat, lon) {
            return latLng(lat, lon);
        },
        latLngBounds: function (boundsData) {
            return latLngBounds(boundsData);

        },
        mapPopup: function (resource) {
            return "<strong>" +
                "<a href=\"/resources/"+resource.slug+"\">" + resource.name + "</a>" +
                "</strong><br />" +
                resource.address +
                ', ' +
                resource.number +
                ' (' + resource.postal_code +
                ')';
                
        },
        getResources: function () {
            // this.api = params;
            let url = 'resources/search';

            let baseParams = {
                fid_town: 474, // Ávila (localidad)
            }

            var search_params = { ...baseParams, ...this.apiParams };

            t4a.get(url, {params: search_params})
            .then(
                function(response) {
                    console.debug(response)
                    this.response = response.data;
                    // this.resources = response.data.results;
                    // this.totalresources = response.data.totalResultResources
                }.bind(this)
            ).catch(t4a_error_handler.bind(this));
        },
    },
    computed: {
        showMap: function() {
            return this.slug == 'aparcamientos';
        }, 
        categoryname: function () {
            for (const [key, value] of Object.entries(this.params)) {
                if (key == 'category') {
                    var cat = this.categories.find(function(item) {
                        return item.slug == value;
                    });
                    return cat.name;
                }
            }
        },
        defaultlat: function () {
            return "40.65724";
        },
        defaultlon: function () {
            return "-4.69951";
        },
        minlat: function() {
            // valor a ojo para minima latitud en la que encaja la ciudad
            let minDefLat = 40.6336991959118;
            if (this.response.data) {
                return Math.min(...this.response.data.map((item) => item.latitude)); 
            }
            return minDefLat;
        },
        maxlat: function() {
            // valor a ojo para max latitud en la que encaja la ciudad
            let maxDefLat = 40.67377624881134
            if (this.response.data) {
                return Math.max(...this.response.data.map((item) => item.latitude));
            }
            return maxDefLat;
        },
        minlon: function() {
            // valor a ojo para minima longitud en la que encaja la ciudad
            let minDefLon = -4.7139930725097665;
            if (this.response.data) {
                return Math.min(...this.response.data.map((item) => item.longitude));
            }
            return minDefLon;
        },
        maxlon: function() {
            // valor a ojo para max longitud en la que encaja la ciudad
            let maxDefLon = -4.655113220214845;
            if (this.response.data) {
                return Math.max(...this.response.data.map((item) => item.longitude));
            }
            return maxDefLon;
        },
        mapdata: function () {
            var defaultLatitude = "40.65724";
            var defaultLongitude = "-4.69951";

            return {
                mapCenter: latLng(defaultLatitude, defaultLongitude),
                // bounds: {},
                bounds: latLngBounds ([
                    [this.maxlat, this.minlon],
                    [this.minlat, this.maxlon]
                ]),
                mapZoom: 16,
                mapOptions: {},
                mapTileLayerUrl:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                mapTileLayerOptions: {},
                mapTileLayerAttribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            }
        },
        apiParams: function () {
            let searchparams = {}
            for (const [key, value] of Object.entries(this.params)) {
                switch (key) {
                    case 'category':
                        var cat = this.categories.find(function(item) {
                            return item.slug == value;
                        });
                        if (cat) {
                            searchparams['fid_category'] = cat.id;
                        }
                        break;
                    case 'subcategory':
                        var subcat = this.subcategories.find(function(item) {
                            return item.slug == value;
                        });
                        if (subcat) {
                            searchparams['fid_subcategory'] = subcat.id;
                        }
                        break;
                    case 'name': case 'page':
                        if (value) {
                            searchparams[key] = value;
                        }
                        break;
                    default:
                        var criterion = this.accessibilitycriteria.find(function(item) {
                            return item.slug == key;
                        });
                        if (criterion) {
                            searchparams[criterion.key] = value;
                        }
                        break;
                }
            }
            return searchparams;
        },
    }
}
</script>
    