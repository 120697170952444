<template id="resource-edit">
    <div id="resource-edit-container" class="container py-5">
        <div class="row">
            <div class="col-12 mb-3" v-if="preselected?.name">
                <resource-form :preselected="preselected" ref="resourceform" :translations="l10nfields" mode="edit" />
            </div>
        </div>
    <errorlist :errors="errors" />

    </div>
</template>

<script>
export default {
    props: [
        'slug',
    ],
    mixins: [authMixin],
    data: function () {
        return {
            preselected: {
                name: ''
            },
            l10nfields: {}

        }
    },
    created: function () {
        let resource_url = 'resources/' + this.slug + '/l10n';
        this.getResource(resource_url);
    },
    methods: {
        getResource: function(url) {
            t4a.get(url)
            .then(function(response) {
                this.preselected = response.data.resource;
                this.l10nfields = response.data.l10nfields

            }.bind(this))
            .catch(window.t4a_error_handler.bind(this));
        }
    }
}
</script>
