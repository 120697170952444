<template id="resource-show">
<div id="resource-show-container" class="py-5">
    <div v-if="resource">
        <div class="container">
            <div class="row resource-name-row">
                <div class="col-12 resource-name-container">
                </div>
            </div>
            <div class="row resource-description-data-row">
                <div class="col-12 col-md-6 resource-description-container">
                    <h2>{{ resource.name }}</h2>

                    <div v-html="resource.description" class="mb-5"></div>
                    <a class="btn btn-outline-primary mb-3" href="#resource-accessibility-report" id="jump-to-report">
                        <span class="bi bi-link"></span>{{ $t('resource.report.link') }}
                    </a>
                </div>
                <div class="col-12 col-md-6 resource-data-container">
                    <div class="resource-category-subcategory-container d-flex flex-row">
                        <div class="card resource-category-card d-flex flex-row">
                            <div class="resource-category-image-container">
                                <img :src="resource.subcategory.category.pictogram" class="resource-category-image" role="presentation">
                            </div>
                            <div class="resource-category-name-container">
                                <span class="visually-hidden">Categoría</span>
                                {{ resource.subcategory.category.name }}
                            </div>
                        </div>
                        <div class="card resource-subcategory-card d-flex flex-row">
                            <div class="resource-subcategory-image-container">
                                <img :src="resource.subcategory.pictogram" class="resource-subcategory-image" role="presentation">
                            </div>
                            <div class="resource-subcategory-name-container">
                                <span class="visually-hidden">Subategoría</span>
                                {{ resource.subcategory.name }}
                            </div>
                        </div>
                    </div>
                    <div class="resource-contact-container contact-cards-container d-flex flex-row flex-wrap">
                        <div class="card resource-contact-card d-flex flex-row">
                            <div class="resource-contact-image-container">
                                <span class="resource-contact-image bi bi-geo-alt-fill"></span>
                            </div>
                            <div class="resource-contact-name-container">
                                <span class="visually-hidden">Dirección</span>
                                {{ fullAddress }}
                            </div>
                        </div>

                        <div class="card resource-contact-card d-flex flex-row" v-if="resource.telephone">
                            <div class="resource-contact-image-container">
                                <span class="resource-contact-image bi bi-telephone-fill"></span>
                            </div>
                            <div class="resource-contact-name-container">
                                <span class="visually-hidden">Teléfono</span>
                                <a :href="'tel:'+resource.telephone">{{ resource.telephone }}</a>
                            </div>
                        </div>

                        <div class="card resource-contact-card d-flex flex-row" v-if="resource.url">
                            <div class="resource-contact-image-container">
                                <span class="resource-contact-image bi bi-link-45deg"></span>
                            </div>
                            <div class="resource-contact-name-container">
                                <span class="visually-hidden">Página web</span>
                                <a :href="resource.url">{{ resource.url }}</a>
                            </div>
                        </div>

                        <div class="card resource-contact-card d-flex flex-row" v-if="resource.email">
                            <div class="resource-contact-image-container">
                                <span class="resource-contact-image bi bi-envelope-at-fill"></span>
                            </div>
                            <div class="resource-contact-name-container">
                                <span class="visually-hidden">Email</span>
                                <a :href="'mailto:'+resource.email">{{ resource.email }}</a>
                            </div>
                        </div>

                        <!-- <div class="card resource-backlink-card d-flex flex-row">
                            <div class="resource-backlink-image-container">
                                <a href="https://tur4all.com"><img src="/images/imagotipo-tur4all-opti-1tinta.svg" role="presentation" class="resource-backlink-image"></a>
                            </div>
                            <div class="resource-backlink-name-container">
                                Recurso analizado en colaboración con <a href="https://tur4all.com">TUR4all Turismo accesible</a>
                            </div>
                        </div> -->


                    </div>
                    <div class="resource-criteria-container criteria-cards-container d-flex flex-row flex-wrap">
                        <div v-for="(criterion_val, criterion_key) in resource.summary"
                            class="card criteria-card d-flex flex-row"
                            v-if="criterion_val"
                        >
                            <div class="criteria-card-image-container">
                                <img class="criteria-card-image"
                                    :src="criterion(criterion_key).pictogram"
                                    :alt="criterion(criterion_key).name" role="presentation"
                                />
                            </div>
                            <div class="criteria-card-text-container">
                                <div class="criteria-card-text-name">{{ criterion(criterion_key).name }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="resource-criteria-container criteria-cards-container d-flex flex-row flex-wrap">
                        <div v-for="(criterion_val, criterion_key) in resource.summary"
                            class="card criteria-card d-flex flex-row"
                            v-if="criterion_val"
                        >
                            <div class="criteria-card-image-container">
                                <img class="criteria-card-image"
                                    :src="criterion(criterion_key).pictogram"
                                    :alt="criterion(criterion_key).name" role="presentation"
                                />
                            </div>
                            <div class="criteria-card-text-container">
                                <div class="criteria-card-text-name">{{ criterion(criterion_key).name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid resource-slider-map-container-fluid red-band my-5 py-5 g-0">
            <div class="container">
                <div class="row resource-slider-map-row">
                    <div class="col-12 col-md-6 resource-image-map-container">
                        <div class="resource-carousel">
                            <resource-carousel :slug="slug"></resource-carousel>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 resource-image-map-container">
                        <div class="resource-map-container">
                            <l-map :zoom="mapdata.mapZoom" :center="mapdata.mapCenter" :options="mapdata.mapOptions">
                                <l-tile-layer :url="mapdata.mapTileLayerUrl" :attribution="mapdata.mapTileLayerAttribution"
                                    :options="mapdata.mapTileLayerOptions"></l-tile-layer>
                                <l-marker :lat-lng="mapdata.mapMarker">
                                    <l-popup :content="mapdata.mapPopup">
                                        <strong>{{  resource.name }}</strong><br />
                                        {{ fullAddress }}
                                    </l-popup>
                                </l-marker>
                            </l-map>
                        </div>
                        <!-- <div class="card p-3 resource-address">
                            <address>{{ fullAddress }}</address>
                            <a :href="'tel:' + resource.telephone">{{ resource.telephone }}</a><br />
                            <a :href="'mailto:' + resource.mail">{{ resource.email }}</a><br />
                            <a :href="resource.url">{{ resource.url }}</a><br />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row resource-report-row" id="resource-accessibility-report">
                <div class="col-12">
                    <resource-report :slug="slug"></resource-report>
                </div>
            </div>
        </div>
    </div>
    <errorlist :errors="errors" />
</div>

</template>
<script>
export default {
    inject: ['locales', 'accessibilitycriteria'],
    props: [
        'slug'
    ],
    mixins: [
        authMixin
    ],
    mounted: function () {
        let resource_url = 'resources/' + this.slug;
        t4a.get(resource_url)
        .then(function (response) {
            // console.debug(response);
            this.resource = response.data;
        }.bind(this))
        .catch(t4a_error_handler.bind(this));
    },
    data: function () {
        return {
            resource: null
        }
    },
    methods: {
        criterion: function (criterion_key) {
            return this.accessibilitycriteria.find(function (item) {
                return criterion_key == item.key;
            });
        }
    },
    computed: {
        mapdata: function () {
            return {
                mapCenter: latLng(this.resource.latitude, this.resource.longitude),
                mapMarker: latLng(this.resource.latitude, this.resource.longitude),
                mapZoom: 17,
                mapOptions: {},
                mapTileLayerUrl:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                // mapTileLayerUrl: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
                mapTileLayerOptions: {},
                mapTileLayerAttribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                // mapTileLayerAttribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                mapPopup: "<strong>" +this.resource.name + "</strong><br />" + this.resource.address + ', ' + this.resource.number + ' (' + this.resource.postal_code + ')<br />' + "<a href=\"geo:"+this.resource.latitude+","+this.resource.longitude+"\">Abrir en mapas</a>"
            }
        },

        coverSrc: function () {
            return this.resource.cover.baseurl + this.resource.cover.src;
        },
        coverAlt: function() {
            return this.resource.cover.alt_text ?? 'Imagen sin descripcion :(';
        },
        fullAddress: function () {
            return this.resource.address + ', ' + this.resource.number + ' (' + this.resource.postal_code + ')';
        },
        quizUrl: function () {
            return document.location + '/quiz';
        }

    }
}
</script>
