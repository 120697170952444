<template id="template-languageselector">
    <div class="languageselector float-start form-inline">
        <label for="languageselector" v-show="showlabels">Mostrando idioma: </label>
        <select
            v-model="selectedLanguage"
            class="form-select" @change="languageChanged()">
            <option v-for="locale in dLocales" :value="locale.id" v-bind:key="locale.id">
                {{ locale.language }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    props: {
        showlabels: {
            type: Boolean,
            required: false,
            default: false
        },
        locales: {
            type: Array,
            required: true,
        },
    },
    data: function() {
        return {
            dLocales: this.locales,
            selectedLanguage: this.locales[0].id,
        }
    },
    methods: {
        languageChanged() {
            this.$emit('language-changed', this.selectedLanguage);
        }
    },
    created: function() {
        console.debug('selector created');
        this.languageChanged();
    },
    updated: function() {
        console.debug('selector updated');
        this.languageChanged();
    },
    mounted: function() {
        console.debug('selector mounted');
        this.languageChanged();
    }
};
</script>
