/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

let applocale = document.querySelector('html').attributes['lang'].value;

import VueI18n from 'vue-i18n'
import LocalizationArrays from './vue-i18n-locales.generated.js';

Vue.use(VueI18n);
// window.locarrays = LocalizationArrays;
window.i18nSetup = new VueI18n({
    locale: applocale, // set locale
    messages: LocalizationArrays, // set locale messages
})

window.t4aapiurl = process.env.MIX_TUR4ALL_API_URL;
window.uploadsMixin = require('./mixins/uploads-mixin');
window.formMixin = require('./mixins/form-mixin');
window.authMixin = require('./mixins/auth-mixin');
window.actionMixin = require('./mixins/action-mixin');
window.translationMixin = require('./mixins/translation-mixin');

Vue.component('cu-quiz', require('./components/quiz/CuQuiz.vue').default);
Vue.component('cu-seccion', require('./components/quiz/CuSeccion.vue').default);
Vue.component('cu-set', require('./components/quiz/CuSet.vue').default);
Vue.component('cu-subset', require('./components/quiz/CuSubset.vue').default);
Vue.component('cu-pregunta-radio', require('./components/quiz/CuPreguntaRadio.vue').default);
Vue.component('cu-pregunta-single', require('./components/quiz/CuPreguntaSingle.vue').default);
Vue.component('cu-pregunta-texto', require('./components/quiz/CuPreguntaTexto.vue').default);
Vue.component('cu-pregunta-multi', require('./components/quiz/CuPreguntaMulti.vue').default);
Vue.component('cu-pregunta-imagen', require('./components/quiz/CuPreguntaImagen.vue').default);
Vue.component('cu-criteria', require('./components/quiz/CuCriteria.vue').default);


Vue.component('imageinput', require('./components/ImageInput.vue').default);
Vue.component('imagecontainer', require('./components/ImageContainer.vue').default);
Vue.component('imagepreview', require('./components/ImagePreview.vue').default);
Vue.component('imagepicker', require('./components/ImagePicker.vue').default);

Vue.component('action', require('./components/Action.vue').default);
Vue.component('action-button', require('./components/ActionButton.vue').default);
Vue.component('a-button', require('./components/AButton.vue').default);
Vue.component('a-status', require('./components/AStatus.vue').default);
Vue.component('action-status', require('./components/ActionStatus.vue').default);
Vue.component('action-submit', require('./components/ActionSubmit.vue').default);
Vue.component('uploadstatus', require('./components/UploadStatus.vue').default);

Vue.component('resource-filter', require('./components/resource/ResourceFilter.vue').default);
Vue.component('resource-index', require('./components/resource/ResourceIndex.vue').default);
Vue.component('resource-category-index', require('./components/resource/CategoryIndex.vue').default);
Vue.component('resource-item', require('./components/resource/ResourceItem.vue').default);
Vue.component('resource', require('./components/resource/Resource.vue').default);
Vue.component('resource-report', require('./components/resource/ResourceReport.vue').default);
Vue.component('resource-carousel', require('./components/resource/ResourceCarousel.vue').default);

Vue.component('resource-admin', require('./components/resource/ResourceAdmin.vue').default);
Vue.component('resource-create', require('./components/resource/ResourceCreate.vue').default);
Vue.component('resource-edit', require('./components/resource/ResourceEdit.vue').default);

Vue.component('resource-form', require('./components/resource/ResourceForm.vue').default);

Vue.component('translatable-textarea', require('./components/translatables/TranslatableTextarea.vue').default);
Vue.component('translatable-htmlarea', require('./components/translatables/TranslatableHtmlarea.vue').default);
Vue.component('translatable-textinput', require('./components/translatables/TranslatableTextInput.vue').default);

Vue.component('language-selector', require('./components/translatables/LanguageSelector.vue').default);
Vue.component('translator', require('./components/translatables/Translator.vue').default);

Vue.component('blog-admin', require('./components/blog/BlogAdmin.vue').default);
Vue.component('blog-index', require('./components/blog/BlogIndex.vue').default);
Vue.component('blog-form', require('./components/blog/BlogForm.vue').default);
Vue.component('blog-create', require('./components/blog/BlogCreate.vue').default);
Vue.component('blog-edit', require('./components/blog/BlogEdit.vue').default);
Vue.component('blog-item', require('./components/blog/BlogItem.vue').default);
Vue.component('blog', require('./components/blog/Blog.vue').default);

Vue.component('pagination', require('./components/Pagination.vue').default);
Vue.component('errorlist', require('./components/Error.vue').default);



import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import { latLng, latLngBounds, Icon } from "leaflet";

window.latLng = latLng;
window.latLngBounds = latLngBounds;


delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);

window.t4av = {
    cacheGet: function (key) {
        let data = sessionStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        return false;
    },
    cachePut: function (key, data) {
        sessionStorage.setItem(key, JSON.stringify(data));
        return data;
    },
    cacheClear: function() {
        sessionStorage.clear();
    },

    t4apublic: axios.create({
        headers: {
            'Content-Language': applocale
        },
        baseURL: window.t4aapiurl
    }),

    data: {
        applocale: applocale,
        locales:  [
            { id: 'es', language: 'Español' },
            { id: 'en', language: 'English' },
            { id: 'fr', language: 'Français' }
        ],
    },

    init: async function () {
        let accessibilitycriteria = this.cacheGet('t4a-accessibilitycriteria');
        if (accessibilitycriteria) {
            this.data.accessibilitycriteria = accessibilitycriteria;
        } else {
            await this.t4apublic.get('accessibilitycriteria').
                then(function(response) {
                    this.data.accessibilitycriteria = response.data.criteria;
                    this.cachePut('t4a-accessibilitycriteria', response.data.criteria);
                }.bind(this)
            );
        }

        let categories = this.cacheGet('t4a-categories');
        if (categories) {
            this.data.categories = categories;
            this.data.subcategories = this.data.categories.reduce(function(subcategories, item) {
                return subcategories.concat(item.subcategories);
            }, []);
        } else {
            await this.t4apublic.get('categories').
                then(function(response) {
                    this.data.categories = response.data.categories;
                    this.data.subcategories = this.data.categories.reduce(function(subcategories, item) {
                        return subcategories.concat(item.subcategories);
                    }, []);
                    this.cachePut('t4a-categories', response.data.categories);
                }.bind(this)
            );
        }

        let locales = this.cacheGet('t4a-locales');
        if (locales) {
            this.data.t4alocales = locales;
        } else {
            await this.t4apublic.get('locales').
                then(function(response) {
                    this.data.t4alocales = response.data.locales;
                    this.cachePut('t4a-locales', response.data.locales);
                }.bind(this)
            );
        }
    }
}

await t4av.init();

window.bus = new Vue();

const app = new Vue({
    el: '#app',
    provide: t4av.data,
    i18n: i18nSetup,
});

