<template id="resource-carousel">
<div id="resource-carousel-container" class="resource-carousel-container">
    <div  v-if="showcarousel">
        <div id="resource-carousel" class="carousel slide">
            <div class="carousel-inner">
                <div class="carousel-item carousel-image-container ratio ratio-4x3" :class="{ active: (index == 0) }"
                    v-for="(image, index) in images">
                    <a :href="imageSrc(image)" @click.prevent="showImageDialog(index)" class="carousel-image-link">
                        <img class="carousel-image" :src="imageSrc(image)" :alt="imageAlt(image)" />
                    </a>  
                </div>
            </div>
        </div>
        <dialog :ref="dialogRef(index)" class="imagedialog" @click="closeImageDialog(index)" v-for="(image, index) in images">
            <img :src="imageSrc(image)" :alt="imageAlt(image)" :title="imageAlt(image)" class="image-fullview" />
        </dialog>  
        <div class="d-flex flex-row pt-2 btn-group">
            <button class="flex-fill resource-carousel-control-prev btn  btn-sm btn-light w-25" type="button" data-bs-target="#resource-carousel" data-bs-slide="prev" @click="clickPrev">
                <span class="bi bi-caret-left-fill" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button :class="{'flex-fill btn btn-sm btn-outline-light': true, 'active': (cindex == index)}" v-for="(image, index) in images" type="button" 
                data-bs-target="#resource-carousel" :data-bs-slide-to="index" aria-current="true" :aria-label="index" @click="cindex = index">
                    <span class="bi bi-image"></span>
            </button>
            <button class="flex-fill resource-carousel-control-next btn btn-sm btn-light w-25" type="button" data-bs-target="#resource-carousel" data-bs-slide="next" @click="clickNext">
                <span class="bi bi-caret-right-fill" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div v-if="!showcarousel">
        <div id="resource-carousel-default">
            se siente no hay fotos decentes
        </div>
    </div>
</div>
</template>
<script>
export default {
    inject: ['locales', 'accessibilitycriteria'],
    provide: {
        ro: true,
    },
    props: [
        'slug'
    ],
    mixins: [
        authMixin
    ],
    data: function () {
        return {
            images: null,
            cindex: null,
        }
    },
    mounted: function () {
        this.getImages();
        this.cindex = 0;
    },
    methods: {
        showImageDialog(imageindex) {
            // console.debug(this.$refs['imagedialog'+imageindex]);
            this.$refs['imagedialog'+imageindex][0].showModal();
        },
        closeImageDialog(imageindex) {
            this.$refs['imagedialog'+imageindex][0].close();

        },
        getImages: function () {
            let resource_url = 'resources/' + this.slug + '/images';
            t4a.get(resource_url).then(function (response) {
                console.debug(response.data);
                this.images = response.data;
            }.bind(this));
        },
        imageSrc: function (image) {
            return image.baseurl + image.src;
        },
        imageAlt: function (image) {
            return image.alt_text ?? 'imagen sin descripción :(';
        },
        dialogRef: function (index) {
            return 'imagedialog'+index;
        },
        clickNext: function() {
            this.cindex += 1;
            if (this.cindex > this.images.length-1) {
                this.cindex = 0
            }
        },
        clickPrev: function() {
            this.cindex -= 1;
            if (this.cindex < 1) {
                this.cindex = this.images.length-1
            }
        }
    },
    computed: {
        showcarousel: function() {
            return true;
            return (
                this.images?.length > 1
            )
        }
    }
}
</script>
