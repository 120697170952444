<template id="resourcefilter-template">
    <div class="form-horizontal" id="resourcefilter-form">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label for="name">{{ $t("gui_js_components_resourcefilter['Nombre']") }}</label>
                    <input type="search" class="form-control" id="name" name="name" v-model="selected.name" @keyup.enter="doSearch" />
                    <span class="bi bi-x-circle clearname" role="button" @click="clearName"></span>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group" v-show="this.categories?.length">
                    <label for="category">{{ $t("gui_js_components_resourcefilter['Categoría']") }}</label>
                    <select class="form-select" id="category" name="category" v-model="selected.category" @change="onCategoryChange">
                        <option value="">--</option>
                        <option v-for="category in this.categories" :value="category.slug">{{ category.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group" v-show="this.filterSetup.showsubcategories">
                    <label for="subcategory">{{ $t("gui_js_components_resourcefilter['Subcategoría']") }}</label>
                    <select class="form-select" id="subcategory" name="subcategory" v-model="selected.subcategory" :disabled="subcategoriesDisabled">
                        <option value="">--</option>
                        <option v-for="subcategory in this.subcategories" :value="subcategory.slug" v-if="subcategory.category == selected.category">{{ subcategory.name }}</option>
                    </select>
                </div>
                <div class="t4a-backlink-container pt-3" v-show="!this.filterSetup.showsubcategories">
                    <div class="card backlink-card d-flex flex-row">
                        <div class="backlink-card-image-container">
                            <a href="https://tur4all.com"><img src="/images/imagotipo-tur4all-opti.svg" role="presentation" class="backlink-card-image"></a>
                        </div>
                        <div class="backlink-card-text-container">
                            <div class="backlink-card-text-name">{{ $t("backlink['Recursos analizados en colaboración con']") }} <a href="https://tur4all.com">{{ $t("backlink['TUR4all Turismo accesible']") }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" v-show="this.filterSetup.showcriteria">
            <div class="col-12">
                <details>
                    <summary class="btn btn-outline-primary">
                        {{ $t("gui_js_components_resourcefilter['Criterios de accesibilidad']") }}
                        <span class="visually-hidden">{{ $t('Pulsa para desplegar una lista de filtros según criterios de accesibilidad') }}</span>
                        <span class="bi bi-chevron-down"></span>
                    </summary>
                    <div class="criteria-cards-container row g-1 p-3 ">
                        <div v-for="criterion in this.accessibilitycriteria" class="col-6 col-md-3 d-flex flex-row">
                            <label class="card criteria-card p-0 d-flex flex-row" :for="criterion.key">
                                <div class="me-1 criteria-card-image-container">
                                    <img class="criteria-card-image" :src="criterion.pictogram" role="presentation" />
                                </div>
                                <div class="criteria-card-text-container">
                                    <input class="form-check-input me-1 criteria-card-text-input" type="checkbox" :name="criterion.key" :id="criterion.key" v-model="selected[criterion.slug]" true-value="1" false-value="0">
                                    <span class="criteria-card-text-name">{{ criterion.name }}</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </details>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 col-md-8">
                <!-- <button class="btn btn-primary" @click.prevent="doSearch">
                    {{ $t("gui_js_components_resourcefilter['Buscar']") }}
                </button> -->
                <a :href="searchurl" ref="searchbutton" class="btn btn-primary">
                    <!-- {{ searchurl }} -->
                    {{ $t("gui_js_components_resourcefilter['Buscar']") }}
                </a>
            </div>
            <!-- <div class="col-12 col-md-4">
                <div class="t4a-backlink-container py-3" v-show="!this.filterSetup.showsubcategories">
                    <div class="card backlink-card d-flex flex-row">
                        <div class="backlink-card-image-container">
                            <a href="https://tur4all.com"><img src="/images/imagotipo-tur4all-opti.svg" role="presentation" class="backlink-card-image"></a>
                        </div>
                        <div class="backlink-card-text-container">
                            <div class="backlink-card-text-name">Recurso analizado en colaboración con <a href="https://tur4all.com">TUR4all Turismo accesible</a></div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<style>
label.card, .criteria-toggle {
    cursor: pointer;
}
input[type="search"] {
    display:inline-block;
}
span.clearname {
    margin: -2em;
    color: #999;
}
</style>
<script>
export default {
    inject: [
        'accessibilitycriteria', 'categories', 'subcategories'
    ],
    props: [
        'filter-setup',
    ],
    data: function () {
        return {
            selected: {
                name: "",
                category: "",
                subcategory: "",
            },
        }
    },
    methods: {
        isEmptyObject: function (o) {
            return !Object.keys(o).length;
        },
        doSearch: function () {
            // this.$emit('do-search', this.searchParams);
            this.clearPage();
            this.$refs.searchbutton.click();
        },
        onCategoryChange: function() {
            this.selected.subcategory = '';
        },
        clearName: function () {
            this.selected.name = '';
        }

    },
    // created: function () {
    //     let urlparams = new URLSearchParams(window.location.search);
    //     let getparams = {};
    //     for (const [key, value] of urlparams.entries()) {
    //         getparams[key] = value;
    //     }
    //     this.selected = getparams;
    //     this.doSearch();

    // },
    mounted: function () {
        // if (!this.isEmptyObject(this.filterSetup)) {
        //     this.setup = this.filterSetup;
        // }
        if (this.filterSetup.selected && !this.isEmptyObject(this.filterSetup.selected)) {
            for (var prop in this.filterSetup.selected) {
                this.selected[prop] = this.filterSetup.selected[prop];
            }
            this.$forceUpdate();
        }

    },
    computed: {
        subcategoriesDisabled: function () {
            return !this.selected.category
        },

        searchurl: function () {
            let searchurl = 'resources?';
            for (const [key, value] of Object.entries(this.selected)) {
                if (key!=='page' && value && value != 0) {
                    searchurl = searchurl + key + '=' + value + '&';
                }
            }
            if (searchurl.endsWith('&')) {
                searchurl = searchurl.slice(0, searchurl.lastIndexOf('&'));

            }
            return searchurl;
        }
    }
}
</script>
