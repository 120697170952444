<template id="cu-pregunta-multi">
    <div class="question question-multi pregunta pregunta-multi">
        <strong>{{ question.text_question }}:</strong>
        <span v-if="ro">{{ answersString }}</span>
        <span v-if="hasSubsets()" class="has-subsets bi bi-clipboard-plus desplegable"
                      :title="titleString" v-show="!ro"></span>
        <span class="form-text" v-show="!ro">{{ question.text_help }}</span>
        <div v-for="option in question.options" :key="option.form_id" class="form-check checkbox" v-show="!ro">
            <label :for="option.form_id">
                <input
                        type="checkbox"
                        :name="option.form_name"
                        :id="option.form_id"
                        class="form-check-input"
                        :value="option.value"
                        @change="checkCriterios"
                        v-bind:true-value="option.value"
                        v-model="question.answer"
                        v-show="!ro"
                >
                <span class="bi bi-check" :class="{report: ro}" v-show="ro"></span>
                <span :class="">{{ option.text }}</span>
            </label>
            <span class="form-text" v-show="!ro">{{ option.text_help }}</span>
        </div>
        <cu-subset v-for="subset in question.subsets" :subset="subset" :key="subset.key"></cu-subset>
    </div>
</template>
<style>
span.report {
    margin-left: -1.5em;
}
</style>
<script>
  export default {
    props: ['question'],
        inject: ['ro'],

      computed: {
        titleString: function (){
            return this.$t("gui_js_components_questionnaire_user_cupreguntamulti['Responder esta pregunta muestra un cuestionario adicional']");
        },
        answersString: function() {
            if (this.question.answer == null || this.question.answer.length == 0) {
                return null;
            }
            let answerOptions = this.question.answer.map(function(answeritem) {
                let foundOption = this.question.options.find(function(optionitem) {
                    return optionitem.value == parseInt(answeritem);
                }.bind(this));
                return foundOption.text;
            }.bind(this));
            return answerOptions.join(', ');
        },
      },
    methods: {
      clearAnswer: function(e) {
        var emptyValue = [];
        this.question.answer = emptyValue;

        this.question.options.forEach(function(item, index, array){
          var fakeEvent = {
            target: {
              name: item.form_name,
              value: item.value,
              checked: false
            }
          };
          this.checkCriterios(fakeEvent);
        }.bind(this));

      },

      checkCriterios: function (e) {
        this.checkCriterioForElement(e.target);
      },

      checkCriterioForElement: function (element) {
        // cuando cambia un checkbox multiple:
        // encontrar criterio para el valor del checkbox
        // obtener ref al subset indicado por el criterio
        // si el checkbox ha cambiado a checked: activar el subset para ese criterio
        // si el checkbox ha cambiado a unchecked: desactivar el subset para ese criterio
        var checkboxvalue = element.value;
        var checkboxischecked = element.checked;

        var criterios = this.getCriterios(checkboxvalue);
        criterios.forEach(function(criterio){
          var subset = this.getSubset(criterio.child_set_key);
          if (subset) {
            if (checkboxischecked) {
              subset.enable();
            } else {
              subset.disable();
            }
          }
        }.bind(this));
      },
      hasSubsets: function() {
        return this.question.subsets.length > 0;
      },
      getSubset: function(clave) {
        var subsets = this.$children;

        for (var i = 0; i < subsets.length; i++) {
          if (subsets[i].subset.key == clave) {
            return subsets[i];
          }
        }
        return undefined;
      },

      getCriterios: function(value) {
        return this.question.criteria.filter(function(criterio){
          return criterio.condition_value == value;
        });
      }

    },
    mounted: function() {
      // this.elements = $('[name='+this.question.form_name+']:checked').get();
      this.elements = this.$el.querySelectorAll('input:checked');
      this.elements.forEach(this.checkCriterioForElement);
      if (this.question.answer == "") {
        this.question.answer = [];
      }
    }
  };
</script>
