<template id="template-error">
<div class="container py-5" v-if="errors.length">
    <div class="row">
        <div class="col-12">
            <h4>{{ $t('api_errors.Errores') }}</h4>
            <div class="alert alert-danger" role="alert" v-for="(errorText, errorKey) in errors">
                <span class="bi bi-exclamation-triangle text-danger"></span> {{ formatError(errorText) }}
            </div>
        </div>
    </div>
</div>
</template>
<script>
  export default {
    props: {
      errors: {
        default: function(){
          return [];
        }
      }
    },
    methods: {
        formatError: function (errorText) {
            // los errores de validación vienen envueltos en un array
            if (Array.isArray(errorText)) {
                return errorText.join(".\n");
            }
            return $t(errorText);
        }
    }
  }
</script>

