var translationMixin = {
    inject: ['locales'],
    methods: {
        registerWithParent: function () {
            // console.debug('bus.$emit register-translation', this)
            bus.$emit('register-translation', this);
        },
        deregisterWithParent: function () {
            // console.debug('bus.$emit deregister-translation', this)
            bus.$emit('deregister-translation', this);
        },
        fromLangAvailable(localeid) {
            return true;
        },
        is_nontranslatable() {
            return [0,'ca','eu','ga','cv'].includes(this.selectedLanguage);
        },
        is_empty_fromlang(localeid) {
            return (this.translations && this.translations[localeid] && this.translations[localeid].length);
        },
        is_different_fromlang(localeid) {
            return localeid != this.selectedLanguage;
        },
        findLocale(localeid) {
            return this.locales.find(
                function(item) {
                    return item.id == localeid
                }
            );
        },
        onLanguageChanged() {
            this.text = this.translations[this.selectedLanguage] ?? '';
            this.onTextChange();
        },
        showTranslationDialog() {
            this.dialog.showModal();
        },
        cancelTranslation() {
            this.dialog.close();
        },
        requestTranslation() {

            var confirmTranslationText = '¿Solicitar traducción de ' +
                this.findLocale(this.fromLanguageCode).language +
                ' a ' +
                this.findLocale(this.selectedLanguage).language + '?';
    
            if (confirm(confirmTranslationText)) {
                this.dialog.close();
                this.doTranslate();
            }
        },
        doTranslate() {
            let translationbuttontext = this.$refs.translationbuttontext;
    
            translationbuttontext.innerHtml = 'Traduciendo'
    
            let text = this.translations[this.fromLanguageCode];
    
            let submitData = {
                text: text,
                from_lang: this.fromLanguageCode.toUpperCase(),
                to_lang: this.selectedLanguage.toUpperCase()
            }
    
            let translationUrl = 'translate';
    
            t4a.post(translationUrl, submitData)
                .then(function(response) {
                    // this.$emit('translation-received', response.data);
                    this.onTranslationReceived(response.data);
                }.bind(this))
                .catch(t4a_error_handler);
        },
        onTranslationReceived(translation) {
            // console.debug('onTranslationReceived', translation);
            this.text = translation.text;
            this.onTextChange();
        },
        onTextChange() {
            this.translations[this.selectedLanguage] = this.text;
            if (this.selectedLanguage == this.locales[0].id) {
                this.$emit('input', this.text);
            }
        },
    },
    created: function () {
        this.registerWithParent();
    },
    beforeDestroy: function () {
        this.deregisterWithParent();
    },

};
module.exports = translationMixin;