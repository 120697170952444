<template id="template-uploadstatus">
    <div>
        <h4 v-if="filestatus.length">{{ $t("gui_js_components_uploadstatus['Subida de imágenes adjuntas']") }}:</h4>
        <ul class="list-group">
            <li class="list-group-item" v-for="(fs, fileindex) in filestatus">
                {{ fs.context }} ({{ fileindex+1 }}/{{ filestatus.length }})<br />
                <strong v-if="fs.status">{{ fs.filename }}:</strong> {{ fs.status }}
            </li>
        </ul>
    </div>
</template>
<script>
  export default {
    props: {
      filestatus: {
        default: function(){
          return [];
        }
      }
    },
  }
</script>
