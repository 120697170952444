<template id="template-abutton">
    <div class="a-button-container">
        <button :class="this.buttonclass" role="button" @click.prevent="confirmSubmit">
            <span :class="this.actionicon"></span>
            <slot></slot>
        </button>
        <a-status :astatus="astatus"></a-status>
    </div>
</template>
<script>
export default {
    mixins: [authMixin, actionMixin],
    props: {
        buttonclass: {
            default: 'btn btn-sm btn-outline-primary',
        },
    },
    // data: function() {
    //     return {
    //         confirmationText: $t('gui_buttons.Estas seguro'),
    //     }
    // },
};
</script>
