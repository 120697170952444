<template id="template-actionstatus">
    <div v-if="this.status !== 'WAITING'" :class="this.rootClass">
        <span
            v-if="this.status === 'SUBMITTING'"
            :class="this.spanClass">
        </span>
        <span
            v-if="this.status !== 'SUBMITTING'"
            :class="this.spanClass"
            title="Status"
            data-toggle="popover"
            :data-content="this.description"
            @mouseover="this.pop">
        </span>
        <span
            v-if="this.status !== 'SUBMITTING'"
            class="short-status">
            {{ this.status.toLowerCase() }}
        </span>
    </div>
</template>
<script>
    export default {
        props: {
            rootclass: {
                default: '',
            },
            spanclass: {
                default: '',
            },
            type: { },
            description: {
                required: true,
            },
            status: {
                required: true,
            },
        },
        computed: {
            rootClass() {
                switch (this.type) {
                    case 'right': return 'root-actionstatus pull-right ' + this.rootclass;
                    default: return 'root-actionstatus ' + this.rootclass;
                }
            },
            spanClass() {
                var baseClass;
                switch (this.status) {
                    case 'TEST': baseClass = 'glyphicon glyphicon-ok-sign text-success action-status-ok '; break;
                    case 'WAITING': baseClass = ''; break;
                    case 'SUBMITTING': baseClass = 'glyphicon glyphicon-refresh text-muted action-status-in-progress '; break;
                    case 'SUBMITTED': baseClass = 'glyphicon glyphicon-refresh text-muted action-status-in-progress '; break;
                    case 'OK': baseClass = 'glyphicon glyphicon-ok-sign text-success action-status-ok '; break;
                    case 'ERROR': baseClass = 'glyphicon glyphicon-exclamation-sign text-danger action-status-error '; break;
                }
                return baseClass + this.spanclass;
            },
        },
        methods: {
            pop: function () {
                $('[data-toggle="popover"]').popover();
                $('body').on('click', '.popover', function () {
                    $(this).popover('destroy');
                });
            },
        }
    };
</script>
