<template id="translatable-htmlarea">
    <div>
        <!-- <language-selector :locales="dLocales" ref="lselector" @language-changed="onLanguageChanged" class="float-start mb-2" /> -->
        <div>
        <div class="languageselector float-start form-inline">
            <label for="languageselector" v-show="this.showlabels">{{ $t('translatable-textarea.Mostrando idioma') }}:</label>
            <select v-model="selectedLanguage" class="form-select" @change="onLanguageChanged">
                <option v-for="locale in locales" :value="locale.id" :key="locale.id">
                    {{ locale.language }}
                </option>
            </select>
        </div>

        <div class="translator">
            <button
                title="Traducir desde otro idioma"
                class="btn btn-outline-secondary float-end"
                @click.prevent="showTranslationDialog"
                v-show="!is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext">{{ $t('translatable-textarea.Traducir') }}</span>
            </button>
            <button
                title="Idioma sin traducción automática"
                class="btn btn-outline-secondary float-end"
                @click="showTranslationDialog"
                v-bind:disabled="is_nontranslatable()"
                v-show="is_nontranslatable()">
                    <span class="glyphicon glyphicon-book"></span>
                    <span ref="translationbuttontext" v-show="showlabels">{{ $t('translatable-textarea.Idioma sin traducción automática') }}</span>
            </button>
            <!-- <div class="translationerror color-red">
                <span class="glyphicon glyphicon-exclamation-sign"></span>
                Se produjo un error en la traducción.
            </div> -->
            <dialog id="translationdialog" ref="translationdialog">
                <label for="translationselector">
                    {{ $t('translatable-textarea.Traducir') }}
                    {{ this.locales[this.selectedLanguage] }}
                    {{ $t('translatable-textarea.desde') }}:
                </label>
                <select
                    :disabled="is_nontranslatable()"
                    v-model="fromLanguageCode"
                    class="form-select" >
                    <option value="">--</option>
                    <option v-for="locale in locales" :value="locale.id" v-bind:key="locale.id" v-show="fromLangAvailable(locale.id)">
                        {{ locale.language }}
                    </option>
                </select>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="requestTranslation">{{ $t('translatable-textarea.Pedir traducción') }}</button>
                <button class="btn btn-outline-secondary mt-2" @click.prevent="cancelTranslation">{{ $t('translatable-textarea.Cancelar') }}</button>
            </dialog>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="mt-3">
        <input class="form-control" type="text" v-model="text" @change="onTextChange" :name="name" />
    </div>
    </div>
</template>
<script>

export default {
    mixins: [translationMixin],
    props: {
        translations: {
            default: function() {
                return {}
            }
        },
        showlabels: {
            default: true,
        },
        name: {
            default: function () {
                return 'description';
            }
        }
    },
    data: function () {
        return {
            selectedLanguage: '',
            fromLanguageCode: '',
            text: '',
            dialog: {},
        }
    },
    mounted() {
        this.selectedLanguage = this.locales[0].id;
        this.text = '';
        if (this.translations && this.translations[this.selectedLanguage]) {
            this.text = this.translations[this.selectedLanguage];
        }
        this.dialog = this.$refs.translationdialog;
    },
    watch: {
        translations: {
            handler: function () {
                this.text = '';
                this.dTranslations = this.translations;
                if (this.translations) {
                    if (this.translations[this.selectedLanguage]) {
                        this.text = this.translations[this.selectedLanguage];
                    } else if (this.translations[0]) {
                        this.text = this.translations[0];
                    }
                }
                this.$forceUpdate();
            },
            deep: true,
        }
    }
};
</script>
