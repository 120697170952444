<template id="blog-edit">
    <div id="blog-edit-container" class="container py-3">
        <div class="row">
            <div class="col-12">
                <h2>{{  $t('blog.edit.title') }}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <blog-form ref="blogform" :preselected="preselected" :translations="l10nfields" />
            </div>
        </div>
    </div>
    </template>
    
    <script>
    export default {
        props: [
            'slug'
        ],
        provide: {
            ro: false
        },
        mixins: [authMixin],
        data: function () {
            return {
                preselected: {
                    title: ''
                },
                l10nfields: {}
            }
        },
        created: function () {
            let blogpost_url = 'blog/' + this.slug+'/l10n';
            this.getBlog(blogpost_url);
        },
        methods: {
            getBlog: function (url) {
                t4a.get(url)
                .then(function(response) {
                    this.preselected = response.data.blogPost;
                    this.l10nfields = response.data.l10nfields

                }.bind(this))
                .catch(window.t4a_error_handler.bind(this))

            }
        }
    }
    </script>
    