var actionMixin = {
    props: {
        actionroute: {
            required: true,
        },
        actionmethod: {
            default: 'POST',
        },
        actionicon: {
            // default: 'bi bi-gear-fill',
            // default: 'bi bi-puzzle',
            // default: 'bi bi-question-circle',
            default: 'bi bi-hand-index',
        },
        redirect: false,
        reload: false,

    },
    data: function() {
        return {
            confirmationtext: window.i18nSetup.t("gui_buttons.¿Estás seguro?"),
            astatus: 'WAITING',
        }
    },
    methods: {
        confirmSubmit: function () {
            console.debug(this.actionroute);
            if (confirm(this.confirmationtext)) {
               this.submit();
            }
        },
        submit: function () {
            this.astatus = 'SUBMITTING';
            t4a.request({
                url: this.actionroute, 
                method: this.actionmethod
            })
            .then(
                this.onSuccess
            )
            .catch(
                this.onError
            );
        },
        onSuccess: function (response) {
            console.debug('action-mixin onSuccess',response);
            this.astatus = 'OK'
            this.$emit('action-success');
        },
        onError: function (error) {
            console.debug('action-mixin onError',error);
            this.$emit('action-error');

            this.astatus = 'ERROR'
            t4a_error_handler(error);
        }
    },
    created: function () {
        // this.registerWithParent();
    },
    beforeDestroy: function () {
        // this.deregisterWithParent();
    },
};
module.exports = actionMixin;