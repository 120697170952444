var formMixin = {
    // este comportamiento común en todos los formularios vue, excepto cuestionarios de momento
    data: function() {
        return {
            uploadcomponents: [],
            translatedfields: [],
            filestatus: [],
            pendingrequests: [],
            // errors: [],
            // response: {}
        }
    },
    created: function () {
        bus.$on('register-uploader', this.registerUploader);
        bus.$on('deregister-uploader', this.deregisterUploader);
        bus.$on('register-translation', this.registerTranslation);
        bus.$on('deregister-translation', this.deregisterTranslation);
        bus.$on('push-filestatus', this.pushFilestatus)
    },
    methods: {
        registerUploader: function(uploader) {
            // console.debug('Registrando uploader ', uploader);
            this.uploadcomponents.push(uploader);
        },
        deregisterUploader: function(uploader) {
            // console.debug('Deregistrando uploader ',uploader);
            this.uploadcomponents = this.uploadcomponents.filter(function (item) {
                return item._uid != this._uid;
            }.bind(uploader));
        },
        registerTranslation: function(translation) {
            // console.debug('Registrando translation ', translation);
            this.translatedfields.push(translation);
        },
        deregisterTranslation: function(translation) {
            // console.debug('Deregistrando translation ',translation);
            this.translatedfields = this.translatedfields.filter(function (item) {
                return item._uid != this._uid;
            }.bind(translation));
        },
        pushFilestatus(status) {
            // console.debug('form-mixin pushFilestatus', status);
            this.filestatus.push(status);
            // this.checkUploadsStatus();
        },
        checkUploadsStatus() {
            // metodo alternativo para cambiar el status a ok cuando se completan los uploads,
            // descomentando la llamada en pushFilestatus
            var howmanypending = this.pendingrequests.length;
            var howmanyok = this.filestatus.reduce(
                function(acc,item) {
                    if (item.status == 'OK') {
                        acc += 1;
                    }
                    return acc;
                },
                0
            );
            if (howmanypending == howmanyok) {
                this.status = 'OK';
            }
        },
        onPendingRequestsSuccess: function(data, textStatus, jqXHR) {
            // console.debug('onPendingRequestsSuccess');
            this.status = 'OK';
        },
        onPendingRequestsFail: function(jqXHR, textStatus, errorThrown) {
            this.status = 'ERROR';
        },
        buildUploadUrlFromResponse: function(response) {
            // console.debug('buildUploadUrlFromResponse', [this.uploadUrlTemplate, response.data.slug]);
            if (response.headers.location) {
                return response.headers.location + '/upload';
            }
            return this.uploadUrlTemplate.replace(/___UPLOADID___/, response.data.slug);
        },
        buildEditUrlFromResponse: function(response) {
            if (response.headers.location) {
                return response.headers.location;
            }
            return this.actionUrl.replace(/register/, response.data.slug);
        },
        reset: function() {
            this.filestatus = [];
            this.errors = [];
            this.status = '';
        },
        validate: function() {
            if ($('#formcontainer').valid()) {
                this.submit();
            } 
        },
        submit: function() {
            this.reset();
            this.status = 'SUBMITTING';
            var submitData = this.buildSubmitData();
            t4a.post(this.actionUrl, submitData)
            .then(function (response) {
                if(response.status >= 200 && response.status <=299) {
                    this.status = 'OK'
                }
                var uploadUrl = this.buildUploadUrlFromResponse(response);
                this.actionUrl = this.buildEditUrlFromResponse(response);
                var uploadsPromise = this.startUploads(uploadUrl);
                uploadsPromise.then(
                    this.onPendingRequestsSuccess,
                    this.onPendingRequestsFail
                )
            }.bind(this))
            .catch(t4a_error_handler.bind(this));
        },
        startUploads: function(uploadUrl) {
            // console.debug('form-mixin startUploads', uploadUrl);
            this.pendingrequests = [];
            for (var i = 0; i < this.uploadcomponents.length; i++) {
                var pendingUploads = this.uploadcomponents[i].startUploads(uploadUrl)
                this.pendingrequests = this.pendingrequests.concat(pendingUploads);
            }
            if (this.pendingrequests.length) {
                this.status = 'SUBMITTING';
            }
            return Promise.all(this.pendingrequests);
        },
        gatherTranslations: function() {
            var translations = {};
            for (var i = 0; i < this.translatedfields.length; i++) {
                var translationField = this.translatedfields[i];
                // console.debug('gatherTranslations', translation.name, translation.translations);
                translations[translationField.name] = translationField.translations;
            }
            // console.debug('gatherTranslations', translations);
            return translations;
        }
    },
};
module.exports = formMixin;