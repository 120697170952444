<template id="pagination">

<div class="pagination-container container" v-show="response.last_page > 1">
    <div class="row">
        <div class="col-12">
            <p class="w-100 text-center">
                {{ $t('pagination.mostrando') }}
                {{ $t('pagination.desde') }}
                {{ response.from }}
                {{ $t('pagination.a') }}
                {{ response.to }}
                {{ $t('pagination.de') }}
                {{ response.total }}
                {{ $t('pagination.resultados') }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <nav :aria-label="$t('pagination.pagination')">
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        <a class="page-link" :class="{disabled: !firstPageUrl}" :href="firstPageUrl">{{ $t('pagination.First') }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" :class="{disabled: !prevPageUrl}" :href="prevPageUrl">{{ $t('pagination.Previous') }}</a>
                    </li>

                    <li class="page-item" v-for="page in pageRange(response.current_page, response.last_page)">
                        <a class="page-link" :class="{disabled: (page==response.current_page)}" :href="paginationUrl(page)">{{page}}</a>
                    </li>

                    <li class="page-item">
                        <a class="page-link" :class="{disabled: !nextPageUrl}" :href="nextPageUrl">{{ $t('pagination.Next') }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" :class="{disabled: !lastPageUrl}" :href="lastPageUrl">{{ $t('pagination.Last') }}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

</template>
    <script>
    export default {
        props: ['response', 'params'],
        methods: {
            getPage(key) {
                if (!this.response[key]) {
                    return null;
                }
                let urlparams = new URL(this.response[key]);
                let page = urlparams.searchParams.get('page');
                return page;
            },
            paginationUrl: function (page) {
                if (!page) {
                    return null;
                }
                let paginationUrl = new URL(this.currentUrl);
                paginationUrl.searchParams.append('page', page);
                for (const key in this.params) {
                    paginationUrl.searchParams.append(key, this.params[key]);
                }
                return paginationUrl.href;
            },
            pageRange: function (current_page, last_page, range = 3) {
                if (!current_page) {
                    current_page = 1;
                }
                let pagerange = [];
                for (let i = -range; i<=-1; i++) {
                    let page = current_page + i 
                    if ( page > 0 ) {
                        pagerange.push(page);
                    }
                }
    
                pagerange.push(current_page);
    
                for (let j = 1; j<= range; j++) {
                    let page = current_page + j;
                    if (page <= last_page) {
                        pagerange.push(page);
                    }
                }
    
                return pagerange;
            }
        },
        computed: {
            firstPageUrl: function() {
                return this.paginationUrl(this.getPage('first_page_url'));
            },
            lastPageUrl: function() {
                return this.paginationUrl(this.getPage('last_page_url'));
            },
            nextPageUrl: function() {
                return this.paginationUrl(this.getPage('next_page_url'));
    
            },  
            prevPageUrl: function () {
                return this.paginationUrl(this.getPage('prev_page_url'));
            },
    
            currentUrl: function ()  {
                let addr = new URL(document.location.href);
                return addr.protocol + addr.host + addr.pathname;
            }
        }
    }
    </script>
    